<template>
    <div style="margin: 10px">
<v-dialog v-model="dialog" width="1000px">
      <v-card>
        <v-card-title>О проекте </v-card-title>
        <v-card-text>

          <div style="text-align:left">
            <v-row>
              <v-col class="titl_proj" cols="12" md=2>id Проекта</v-col>
              <v-col cols="12" md=10>{{dataProject.id}}</v-col>
            </v-row>
            <v-row>
              <v-col class="titl_proj" cols="12" md=2>Уровень специалиста</v-col>
              <v-col  cols="12" md=2>{{dataProject.lvl}}</v-col>
              <v-col class="titl_proj" cols="12" md=2>Роль специалиста</v-col>
              <v-col  cols="12" md=2>{{dataProject.nameCategories}}</v-col>
              <v-col class="titl_proj" cols="12" md=2>Локация специалиста</v-col>
              <v-col  cols="12" md=2>{{dataProject.loc}}</v-col>
            </v-row>
            <v-row>
              <v-col class="titl_proj" cols="12">О проекте</v-col>
            </v-row>
            <v-row>
              <v-col cols="12">{{dataProject.abount}}</v-col>
            </v-row>
             <v-row>
              <v-col  class="titl_proj" cols="12">Требования</v-col>
            </v-row>
            <v-row>
              <v-col cols="12">{{dataProject.requirements}}</v-col>
            </v-row>
            <v-row>
              <v-col class="titl_proj" cols="12" md=3>Рейт</v-col>
              <v-col cols="12" md=3>{{dataProject.reit}}</v-col>
              <v-col class="titl_proj" cols="12" md=3>Валюта</v-col>
              <v-col cols="12" md=3>{{dataProject.name_valute}}</v-col>
            </v-row>
            <v-row>
              <v-col class="titl_proj" cols="12" md=2>Статус проекта</v-col>
              <v-col cols="12" md=10>{{dataProject.nameSatatus}}</v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-title>Выбрать специалистов </v-card-title>
        <v-card-text>

          <div style="text-align:left">
            <v-row>
               <v-col cols="12" md="12">
                      <v-autocomplete
                        :items="spec"
                        v-model="dataSpec"
                        multiple
                        deletable-chips
                hide-details="auto"
                dense
                        chips
                        label="Специалист *"
                        @change="onAddList"
                      ></v-autocomplete>
                    </v-col>
            </v-row>
          </div>
          <v-row v-if="listSpec.length > 0">
            <v-col cols="12">
              <v-data-table
                :headers="headers_spec"
                :items="listSpec"
                :items-per-page="10"
                class="elevation-1"
              >     
               <template v-slot:item.m2="{ item }">
          <v-btn class="btn_read" v-if="item.m1 == 'Предложение'" @click="onDeleteSpec(item.m0)" title="Удалить специалиста" style="margin-top:2px; margin-bottom: 3px">
            Удалить
          </v-btn>
          <v-btn class="btn_read" v-else @click="onDeleteSpec(item.m0)" title="отказать специалиста" style="margin-top:2px; margin-bottom: 3px">
            Отменить
          </v-btn>
        </template>         
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" text @click="dialog=false"> отмена </v-btn>
          <v-spacer></v-spacer>
          <div v-if="dataProject.zayv == 0">
            <v-btn color="primary" text @click="onSave(1)" > Откликнуться </v-btn>
          </div>
          <div v-else>
            <v-btn color="primary" text @click="onSave(2)"> Сохранить </v-btn>
          <v-btn color="primary" text @click="onSave(0)" > Отказаться </v-btn>
          </div>
          
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div style="margin-top: 10px">
      <div>
        <h1>Проекты для участия</h1>
      </div>
      <v-row>
        <v-col col=12 md=3>
          <v-select
          :items="statusProject"
          v-model="sort.radioGroup"
          small-chips
              dense
              chips
          label="Проекты"
          @change="OnSortList"
          outlined
        ></v-select>
        </v-col>
        <v-col col=12 md=3>
          <v-autocomplete
              :items="listLevel"
          v-model="sort.level"
              outlined
              dense
              chips
              small-chips
              label="Уровень"
              multiple
            ></v-autocomplete>
        </v-col>
        <v-col col=12 md=3>
          <v-autocomplete
              :items="categories"
          v-model="sort.cat"
              outlined
              dense
              chips
              small-chips
              label="Роль"
              multiple
            ></v-autocomplete>
        </v-col>
        <v-col col=12 md=3>
          <v-text-field
              label="Ставка"
          v-model="sort.rait"
              outlined
              dense
              chips
            ></v-text-field>
        </v-col>
      </v-row>
        
     
      <v-card>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Поиск"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="listProj"
          :items-per-page="10"
          :search="search"
          class="elevation-1"
          
        >
         <template v-slot:item.act="{ item }">
          <v-btn v-if="item.zayv == 0" @click="openDialog(item)" class="btn_read" title="редактировать специалиста" style="margin-top:2px; margin-bottom: 3px">
            Открыть
          </v-btn>
           <v-btn v-else @click="openDialog(item)" class="btn_read" title="изменить параметры участвования" style="margin-top:2px; margin-bottom: 3px">
            Изменить
          </v-btn>
        </template>
        </v-data-table>
      </v-card>
    </div>

  </div>
</template>

<script>
export default {
  data: () => ({
    search: '',
    dialog: false,
    statusVl: "",
    sort: {
      radioGroup: "Все",
      level: "",
      cat: "",
      rait: "",
    },
    dataSpec: '',
    listSpec: [],
    dataProject: '',
    statusProject: ["Все","Не откликнулись", "В работе"],
    headers: [
      {
        text: "id",
        value: "id",
      },
      { text: "Дата создания", value: "dateCreate" },
      { text: "уровень", value: "lvl" },
      { text: "роль", value: "nameCategories" },
      { text: "О проекте", value: "abount" },
      { text: "Требования", value: "requirements" },
      { text: "Рейт", value: "rt" },
      { text: "Статус", value: "nameSatatus" },
      { text: "", value: "act" },
    ],
    headers_spec: [
      {
        text: "Cпециалист",
        value: "m0",
      },
      { text: "Статус", value: "m1" },
      { text: "действие", value: "m2" },
    ],
    desserts: [],


  }),
  computed: {
    listProj() {
      return this.$store.getters.getListProject;
    },
    spec() {
      return this.$store.getters.getSpecialShort;
    },
    listLevel() {
      return this.$store.getters.getListLevel;
    },
    categories() {
      return this.$store.getters.getListCategories;
    },

  },
  name: "projectCustomer",
  mounted() {
    this.$store.dispatch("getProjectList");
    this.$store.dispatch("getListPersonSpecAction");
    this.$store.dispatch("getLevelList");
    this.$store.dispatch("getCategories");
    
  },
  methods: {
    openDialog(e){
      this.dataProject = e;
      this.listSpec = [];
          this.dataSpec = [];
      if(this.dataProject.zayv == 1) {
        this.$store.dispatch("getListSpecProject", this.dataProject.id).then(res => {
          
          for (let index = 0; index < res.length; index++) {
            this.listSpec.push(
              {
                m0: res[index].sp,
                m1: res[index].name_status,
                m2: 'отказаться'
              }
            );
            this.dataSpec.push(res[index].sp);
          }
        });
      }
      console.log(this.dataProject);
      this.dialog=true
    }, 
    onSave(q){
      let t = {
        idspec: this.dataSpec,
        idproj: this.dataProject.id,
        tp: q,
      }
      this.$store.dispatch("SaveProjectToSpec", t).then(res => {
            if(res) {
                //alert('не удалось сохранить проект!');
               this.dialog = false;
            } else {
                alert('не удалось сохранить!');
            }
        });
    },
    OnSortList(){
      this.$store.dispatch("sortProjectList", this.sort);
    }, 
    onAddList(){
      console.log(this.dataSpec);
      this.listSpec = [];
      for (let index = 0; index < this.dataSpec.length; index++) {
        this.listSpec.push({
          m0: this.dataSpec[index],
          m1: 'Предложение',
          m2: 'Удалить',
          });
        
      }
      console.log(this.listSpec);
    },
    onDeleteSpec(t) {
      for (let index = 0; index < this.listSpec.length; index++) {
        if(this.listSpec[index].m0 == t){
          this.listSpec.splice(index, 1);
          this.dataSpec.splice(index, 1);
        }
        
      }
    }
  },
};

</script>

<style scoped>
.titl_proj {
  color: black;
  font-weight: bold;
}
</style>