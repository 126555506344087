<template>
  <v-container>      
      <v-row>
          <v-dialog v-model="dialog" width="1000px">
      <v-card>
        <v-card-title>Новый менеджер</v-card-title>
        <v-card-text>          
          <v-row>
            <v-col cols="12" md="12" class="infoPerson">
              <v-text-field
                label="Фио менеджера"
                v-model= "data.nm"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
                      <v-autocomplete
                        :items="skills"
                        dense
                        v-model="data.rl"
                        chips
                        small-chips
                        label="Роль"
                      ></v-autocomplete>
                    </v-col>
            <v-col cols="12" md="12" class="infoPerson">
              <v-text-field
                :rules="emailRules"
                v-model="data.eml"
                label="Email"
                required
              ></v-text-field>
            </v-col>
            <v-col style="text-align: left">
                <p>
                    Расшифровка полей
                </p>
                <p>
                    Аккаунт - может добавлять и редактировать CV, откликаться на проекты, согласовывать часы, редактировать документы
                </p>
                <p>
                    Лид - может все что и аккаунт-менеджер + может добавлять и удалять аккаунт-менеджеров
                </p>
                <p>
                    Апрувер - может все что и остальные + может подверждать документы (акты, счета, заявки)
                </p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" text @click="onClose"> Отменить </v-btn>
          <v-spacer></v-spacer>
           <v-btn color="primary" text v-if="data.id!=0" @click="onDelete(data.id)"> Удалить </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="validate"> Сохранить </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      </v-row>
    <v-row>
      <v-col cols="9" class="mb-9">Менеджеры</v-col>
      <v-col cols="3" class="mb-3">
        <v-btn color="primary" @click="onNewMeneg"> Добавить менеджера </v-btn>
      </v-col>
      <v-col cols="12" class="mb-12"> 
        
              <element-loading :active="onLoadData" spinner="spinner" color="#FF6700"/>
          <div>
      <v-card>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Поиск"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="desserts"
          :items-per-page="10"
          :search="search"
          class="elevation-1"
          @click:row="onReadMeneg"
        ></v-data-table>
      </v-card>
    </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    valid: true,    
    dialog: false,
    search: "",
    name: "",
    data: {
      id: 0,
      nm: "",
      rl: "",
      eml: "",
    },
    skills: [
        'Аккаунт', 'Лид','Апрувер'
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    select: null,
    checkbox: false,
     headers: [
      { text: "id", value: "id" },
      {
        text: "ФИО",
        value: "fio",
      },
      { text: "Роль", value: "nameStatus" },
      { text: "Email", value: "email" }
    ],
  }),
  name: "meneger",
  methods: {
    validate() {
      this.$store.dispatch("saveUserCompanyMeneger", this.data).then(res => {
        if(res) {
          this.dialog = false;
        } else {
          alert('Ошибка сохранения!');
        }
      });

    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    onNewMeneg() {
       this.data.id = 0;
      this.data.nm = "";
      this.data.rl = "";
      this.data.eml = "";
        this.dialog = true;
    },
    onReadMeneg(e) {
      this.data.id = e.id;
      this.data.nm = e.fio;
      this.data.rl = e.nameStatus;
      this.data.eml = e.email;
      this.dialog = true;
    },
    onClose() {
        this.dialog = false;
    },
    onDelete(t) {
      if(confirm("Вы уверены что хотите удалить пользователя?"))
      {
        this.$store.dispatch("delMenegerCompany", {id: t}).then(res=>{
          if(res) {
            alert('Пользователь удален!');
            this.dialog = false;
          }
        })
      }
    }
  },
  mounted() {
    this.$store.dispatch("getMenegerCompany")
  },
  computed: {
    desserts() {
      return this.$store.getters.getListMeneger;
    },
    onLoadData() {
      return this.$store.getters.getOnLoad;
    }
  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
