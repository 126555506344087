<template>
    <v-container>
      <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Сделка № {{ deal.numZ }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field label="Название проекта" required v-model="deal.namePrj"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Cпециалист" required v-model="deal.spec"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Стек работ" required v-model="deal.steck"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Поставщик" required v-model="deal.post"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Заказчик" required v-model="deal.zak"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Цена закупа" required v-model="deal.priseZ"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Цена продажи" required v-model="deal.priseP"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Договор с поставщиком (агентский или на оказание услуг)" required v-model="deal.docPost"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Договор с заказчиком" required v-model="deal.docZak"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Ссылка на заявку/ДС нашу поставщикУ" required v-model="deal.linkPos"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Ссылка на заявку/ДС/задание НАМ от заказчика" required v-model="deal.linkZav"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Ссылка на акт  поставщика" required v-model="deal.linkActPost"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Ссылка на акт  заказчика" required v-model="deal.linkActZak"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Ссылка на счет  поставщика" required v-model="deal.linkScetPost"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Ссылка на счет  заказчика" required v-model="deal.linkScetZak"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Ссылка на отчет агента  поставщику (при агентском договоре)" required v-model="deal.linkAswer"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Количество часов" required v-model="deal.hour"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Месяц сделки" required v-model="deal.MountDeal"></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field type="datetime-local" label="Плановый срок окончания" v-model="deal.PlanDate"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    :items="['Месяц начат','Месяц окончен','продление подвеждено','интервью проведено']"
                    label="Стадия" multiple v-model="deal.Status"></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">
              Закрыть
            </v-btn>
            <v-btn color="blue darken-1" text @click="dialog = false">
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
        <v-row>
            <v-col cols=12 md="6">
                <v-btn>Активные</v-btn>
            </v-col>
            <v-col cols=12 md="6">
                <v-btn>Закрытые
                </v-btn>
            </v-col>
           
        </v-row>
        <v-row>
            <v-col cols="12">
              <v-data-table :headers="headers" :items="desserts" :items-per-page="5" class="elevation-1" @click:row="onRead"></v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
  data: () => ({
    valid: true,
    dialog: false,
    deal: {
        numZ: '',
        spec: '',
        namePrj: '',
        post: '',
        zak: '',
        priseZ: '',
        priseP: '',
        hour: '',
        steck: "",
        docPost: '',
        docZak: '',
        linkZav: '',
        linkPos: '',
        linkActPost: '',
        linkActZak:'',
        linkScetPost:'',
        linkScetZak: '',
        linkAswer: '',
        Marga: '',
        MountDeal: '',
        Status: '',
        PlanDate: '',
        Prem: '',
    },
    headers: [
      {
        text: 'Номер сделки',
        align: 'start',
        sortable: false,
        value: 'numZ',
      },
      { text: 'специалист', value: 'spec' },
      { text: 'Название проекта', value: 'namePrj' },
      { text: 'Поставщик', value: 'post' },
      { text: 'Заказчик', value: 'zak' },
      { text: 'Цена закупа', value: 'priseZ' },
      { text: 'Цена продажи', value: 'priseP' },
      { text: 'Количество часов', value: 'hour' },
    ],
    desserts: [
      {
        numZ: '045672',
        spec: 'Артём С.',
        namePrj: 'Разработка ПО с использованием технологии',
        post: 'OOO "АпфорСофт"',
        zak: '"АртВебГрупп"',
        priseZ: '2200',
        priseP: '2700',
        hour: '-',
        steck: "AWS (EC2, EKS, S3, ALB, ECS, ECR, ACL/SG, LAMBDA, VPС, RDS, SQS, Secrets Manager, Launch Templates, AMI, Auto-Scaling, API Gateway, Cognito, Fleet Manager, CloudFront, Elastic cache, SNS, Parameter Store, Location service, Pinpoint, Guard duty, IAM Identity Center, SSO, Cloud trail, Route53, Certificate Manager, Load Balancer, CodeBuild, CodeDeploy, Lambda, Billing, Database Migration Service) hetzner (VPS, domains, API), CloudFlare (domains, certificates, SSL\\TLS), Ansible, Terraform, Packer, Vagrant, Kubernetes, Docker compose, Docker, Podman, Prometheus, ELK Stack, Grafana, Zabbix, Loki, AWS CloudWatch, LDAP, OAuth, SAML, Jenkins, GitLab CI/CD, AWS CodePipeline, Bash, Python, JavaScript, Elixir, Git,Mercurial, JIRA, GitLab, GitHub, Bitbucket, Kallithea, MySQL, PostgreSQL, SQLite, MongoDB, Redis, RabbitMQ, Kafka, Celery",
        docPost: '',
        docZak: '',
        linkZav: 'https://diadoc.kontur.ru/62b6e3b0-17fc-453d-bd03-c26928107b8c/Document/Show?letterId=2ba3c48c-fc95-4d4c-a228-77ceb59ccd61&amp;documentId=6848ac36-4610-43d5-bd68-9b3a88701a00',
        linkPos: '',
        linkActPost: '',
        linkActZak:'',
        linkScetPost:'',
        linkScetZak: '',
        linkAswer: '',
        Marga: '',
        MountDeal: '',
        Status: '',
        PlanDate: '',
        Prem: '',
      },
    ]
  }),
  name: "listDeals",
  mounted() {
    
    
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    onRead(e){
      this.deal = e;
      this.dialog = true;
    }
   
  },
  computed: {
    onLoadData() {
      return this.$store.getters.getOnLoad;
    },
    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>