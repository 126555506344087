<template>
  <v-container>
    <v-alert density="compact" type="error" title="Alert title" v-if="openAlert == 0">
      Уважаемый пользователь, для начала сотрудничества заполните оставшиеся данные о компании. Заранее спасибо!

    </v-alert>
    <v-alert density="compact" type="success" title="Alert title"  v-if="openAlert == 1">
      Спасибо! Данные отправлены на модерацию. В ближайшее время с Вами свяжется наш менеджер (по указанному Вами телеграм-контакту)!
     
    </v-alert>
    <v-alert density="compact" type="error" title="Alert title"  v-if="openAlert == 3">
      К сожалению, мы вынуждены отказать Вам в сотрудничестве по результатам проверки службы безопасности. Спасибо за уделенное время!
     
    </v-alert>
    <v-row>
      <v-col cols="2" class="mb-2"></v-col>
      <v-col cols="8 " class="mb-8">
        <v-form ref="form" v-model="valid" lazy-validation>
          <element-loading :active="onLoadData" spinner="spinner" color="#FF6700" />
          <h1 class="display-2 font-weight-bold mb-3">Данные для ваших резюме</h1>
          <v-img height="75" max-width="125" :src="data.logo" style="margin: auto;"></v-img>
          <v-file-input label="Логотип компании для ваших резюме" accept="image/png, image/jpeg, image/bmp"
            placeholder="Загрузите логотип" filled prepend-icon="mdi-camera" id="file" ref="file" v-on:change="handleFileUpload()" v-model="file"></v-file-input>
          <v-text-field label="email"  required v-model="data.email"></v-text-field>
          <v-text-field label="телефон" v-model="data.phone" required ></v-text-field>
          <v-text-field label="Сайт компании" required v-model="data.site"></v-text-field>
          <v-text-field label="Бренд" required v-model="data.brend"></v-text-field>
          <h1 class="display-2 font-weight-bold mb-3">О Компании</h1>
          <v-text-field label="Количество сотрудников" type="number" required v-model="data.countWorkes"></v-text-field>
         <v-text-field label="Средняя ставка Middle (руб/час)" type="number" required
            v-model="data.RateMiddle"></v-text-field>

          <v-text-field v-model="data.RateSenior" label="Средняя ставка Senior (руб/час)" type="number"
            required></v-text-field>


          <h1 class="display-2 font-weight-bold mb-3">Реквизиты</h1>

          <v-select :items="country" v-model="data.country" label="Страна" disabled></v-select>
          <v-text-field label="Адрес регистрации" type="text" required disabled v-model="data.adrres"></v-text-field>
          <v-select :items="FormOrg" v-model="data.formOrg" label="Форма организации" disabled></v-select>
          <v-text-field v-model="data.offceOrg" label="Официальное наименование" required disabled></v-text-field>
          <v-text-field v-if="data.formOrg != 'Индивидуальный предприниматель'" v-model="data.dir"
            label="Должность руководителя" required></v-text-field>
          <v-select v-if="data.formOrg != 'Индивидуальный предприниматель'" :items="osnData" v-model="data.osn"
            label="На основании чего действует руководитель"></v-select>
          <v-text-field v-if="data.formOrg != 'Индивидуальный предприниматель' && data.osn == 'Доверенность'"
            v-model="data.ndov" label="Номер доверености" required></v-text-field>
          <v-text-field v-if="data.formOrg != 'Индивидуальный предприниматель' && data.osn == 'Доверенность'"
            v-model="data.dtdov" label="Дата выдачи доверености" required></v-text-field>
          <v-text-field v-if="data.formOrg != 'Индивидуальный предприниматель'" v-model="data.FIO"
            label="ФИО руководителя" required></v-text-field>
          <v-text-field v-model="data.inn" label="ИНН" required disabled @change="onGetFirm">
          </v-text-field>
          <v-text-field v-model="data.ogrn" label="ОГРН / ОГРНИП (для РФ)" required disabled></v-text-field>
          <v-text-field v-model="data.kpp" label="КПП (для РФ)" required disabled></v-text-field>
          <h1 class="display-2 font-weight-bold mb-3">Платежные данные</h1>
          <div v-if="data.country == 'Россия'">
            <v-text-field v-model="data.bik_bank" label="БИК банка" required @change="getBankData"></v-text-field>
            <v-text-field v-model="data.name_bank" label=" Название банка" required disabled></v-text-field>
            <v-text-field v-model="data.kor_chet_bank" label="Корреспондентский счет" disabled required></v-text-field>
            <v-text-field v-model="data.chet_bank_org" label="Расчетный счет" required></v-text-field>
          </div>
          <div v-else>
            <v-text-field v-model="data.bik_bank" label="БИК банка корреспондента в рф" required
              @change="getBankData"></v-text-field>
            <v-text-field v-model="data.kor_chet_bank" label="корреспондентский счёт банка в рф (начинается с 301…)"
              required></v-text-field>
            <v-text-field v-model="data.number_bank_org"
              label="счёт иностранного банка в банке корреспонденте(начинается 301…)" required></v-text-field>
            <v-text-field v-model="data.name_bank_in" label="Наименование иностранного банка" required></v-text-field>
            <v-text-field v-model="data.chet_bank_org" label="расчетный счёт в иностранном банке" required
              @change="getBankData"></v-text-field>

          </div>

          <v-btn color="#FFE816" :disabled="!valid" @click="saveFirm" v-if="openAlert != 3">
            Сохранить
          </v-btn>
        </v-form>
      </v-col>
      <v-col cols="2" class="mb-2"> </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    file: '',
    openAlert: 0,
    data: {
      logo: "",
      logoShort: "",
      countPeople: 0,
      country: "",
      formOrg: "",
      offceOrg: "",
      dir: "",
      osn: "",
      ndov: "",
      dtdov: "",
      FIO: "",
      inn: "",
      ogrn: "",
      kpp: "",
      name_bank_in: "",
      name_bank: "",
      bik_bank: "",
      kor_chet_bank: "",
      chet_bank_org: "",
      number_bank_org: "",
      countWorkes: 0,
      RateMiddle: 0,
      RateSenior: 0,
      adrres: "",
      brend: "",
      site: "",
      phone: "",
      email: "",
    },
    name: "",
    inn: "",
    pass: "",
    passNext: "",
    nameRules: [(v) => !!v || "Название не может быть пустым"],
    nameKPP: [
      (v) => !!v || "Кол-во цифр не может быть пустым если вы ип поставьте 0",
      (v) => (v && v.length <= 9) || "КПП должно быть 9 цифр или 0",
    ],
    // KPPBank: [
    //   (v) => !!v || "Кол-во символов не может быть пустым если вы ип поставьте 0",
    //   (v) => (v && v.length == 9) || "КПП должно быть 9 символов или 0",
    // ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    select: null,
    items: ["Заказчик", "Исполнитель"],
    colPerson: ["1-5", "5-10", "10-50", "50+"],
    osnData: ['Устав', 'Доверенность', 'Договор'],
    checkbox: false,
  }),
  name: "firms",
  mounted() {
    console.log("start")
    let m = localStorage.getItem("b-hire-user-ddf");   

      this.openAlert = m;
      console.log("ffff");
      console.log(this.openAlert);
    this.$store.dispatch("getListDataFirm").then((res) => {
      console.log();
      this.data.country = res[0].nameCountry;
      this.data.inn = res[0].inn;
      this.data.ogrn = res[0].OGRN;
      this.data.kpp = res[0].KPP;
      this.data.offceOrg = res[0].officeName;
      this.data.FIO = res[0].FIOR;
      this.data.dir = res[0].positionR;
      this.data.osn = res[0].baseR;
      this.data.formOrg = res[0].nameFormOrg;
      this.data.name_bank = res[0].bank;
      this.data.bik_bank = res[0].bik;
      this.data.kor_chet_bank = res[0].korChet;
      this.data.chet_bank_org = res[0].rasChet;
      this.data.number_bank_org = res[0].numberChetBank;
      this.data.countWorkes = res[0].countWorkes;
      this.data.RateMiddle = res[0].RateMiddle;
      this.data.RateSenior = res[0].RateSenior;
      this.data.adrres = res[0].adress;
      this.data.brend = res[0].brand;
      this.data.site = res[0].site;
      this.data.email = res[0].email;
      this.data.phone = res[0].phone;
      this.data.logoShort = res[0].logo;
      this.data.logo = 'https://humacl.x-or.ru/public/images/'+res[0].logo;

    });
    this.$store.dispatch("getLocationList");
    this.$store.dispatch("getTypeCompanyList");
    this.$store.dispatch("getFormOrganization");
  },
  computed: {
    country() {
      return this.$store.getters.getListLocation;
    },
    typeCompany() {
      return this.$store.getters.getListTypeCompany;
    },
    FormOrg() {
      return this.$store.getters.getListFormOrg;
    },
    CountPeople() {
      return this.$store.getters.getListPeopleCount;
    },
    onLoadData() {
      return this.$store.getters.getOnLoad;
    },
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    saveFirm() {
      this.$store.dispatch("saveUserCompany", this.data).then((res) => {
      this.openAlert = res;
      });
      
    },
    onGetFirm() {
      console.log('ddd');
      if (this.data.country == 'Россия') {
        let url =
          "https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party";
        let token = "326c0f7d4ced81baa0d95a35cceda204bc255423";
        let query = this.data.inn;

        let options = {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Token " + token,
          },
          body: JSON.stringify({ query: query }),
        };

        fetch(url, options)
          .then((response) => response.text())
          .then((result) => {
            let r = JSON.parse(result);
            console.log(JSON.parse(result));
            this.data.offceOrg = r.suggestions[0].data.name.full_with_opf;
            this.data.kpp = r.suggestions[0].data.kpp;
            this.data.ogrn = r.suggestions[0].data.ogrn;
            this.data.FIO = r.suggestions[0].data.management.name;
            this.data.dir = r.suggestions[0].data.management.post;
          })
          .catch((error) => console.log("error", error));
      }
    },
    getBankData() {
      if (this.data.bik_bank.length == 9) {
        var url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/bank";
        var token = "326c0f7d4ced81baa0d95a35cceda204bc255423";
        var query = this.data.bik_bank;

        var options = {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Token " + token
          },
          body: JSON.stringify({ query: query })
        }

        fetch(url, options)
          .then(response => response.text())
          .then((result) => {
            let r = JSON.parse(result);
            console.log(r);
            if (r.suggestions.length != 0) {
              this.data.name_bank = r.suggestions[0].data.name.payment;
              this.data.kor_chet_bank = r.suggestions[0].data.correspondent_account;
            } else {
              alert('Бик не найден!')
            }

          })
          .catch(error => console.log("error", error));
      }
    },
    handleFileUpload(){
      console.log(this.file)
        this.$store.dispatch("saveUserLogo", this.file).then(res=>{
          this.data.logo = 'https://humacl.x-or.ru/public/images/'+res
          this.data.logoShort = res
        });
      }

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
