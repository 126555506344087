<template>
    <div style="margin: 10px">
    
      <v-row style="margin: 10px">
        <loader
          v-if="load"
          object="#ff9633"
          color1="#ffffff"
          color2="#17fd3d"
          size="5"
          speed="2"
          bg="#343a40"
          objectbg="#999793"
          opacity="80"
          name="dots"
        ></loader>
        <v-col cols="12" md="12"><h1>Фонды</h1></v-col>
        <!-- <v-col cols="12" md="4"><v-btn color="primary" @click="onNewSkill"> Добавить транзакцию </v-btn></v-col> -->
      </v-row>
      <v-row  style="margin: 10px">
        <v-col cols="12" md= "2">
          <v-text-field
            v-model="filt.dts"
            type="date"
            label="дата начала"
            required
            @input ="onfind"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md= "2">
          <v-text-field
            v-model="filt.dte"
            type="date"
            label="дата окончания"
            required
            @input ="onfind"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md= "2">
          <v-autocomplete
                :items="filter.stad" 
                v-model="filt.stad"   
                @change ="onfind"                     
                dense
                chips
                multiple
                label="стадия"
            ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row class="line_fond">
        <v-col class="col_line_h" cols=12 md="12">
          <v-row>
            <v-col class="title_fond" cols=12 md="6">Фонд</v-col>
            <v-col class="info_fond" cols=12 md="2">исходные данные</v-col>
            
            <v-col class="info_fond" cols=12 md="2">транзакция</v-col>
            <v-col class="info_fond" cols=12 md="2">итог</v-col>
          </v-row>
        </v-col>
        <v-col class="col_line_h" cols=12 md="12">
          <v-row>
            <v-col class="title_fond" cols=12 md="6">Выручка</v-col>
            <v-col class="info_fond" cols=12 md="2">{{ dtf.fond.vir.toFixed(1) }}</v-col>
            <v-col class="info_fond" cols=12 md="2">{{ dtf.tran.vir.toFixed(1) }}</v-col>
            <v-col class="info_fond" cols=12 md="2">{{ summ.sumprod.toFixed(1) }}</v-col>
          </v-row>
        </v-col>
        <v-col class="col_line" cols=12 md="12">
          <v-row>
            <v-col class="title_fond" cols=12 md="6">Нераспределенная выручка</v-col>
            <v-col class="info_fond" cols=12 md="2">{{ dtf.fond.nvir.toFixed(1) }}</v-col>
            <v-col class="info_fond" cols=12 md="2">{{ dtf.tran.nvir.toFixed(1) }}</v-col>
            <v-col class="info_fond" cols=12 md="2">{{ summ.vipl }}</v-col>
          </v-row>
        </v-col>
        <v-col class="col_line_h" col=12 md="12">
          <v-row>
            <v-col class="title_fond" cols=12 md="6">Себестоимость</v-col>
            <v-col class="info_fond" cols=12 md="2">{{ dtf.fond.cb.toFixed(1) }}</v-col>
            <v-col class="info_fond" cols=12 md="2">{{ dtf.tran.cb.toFixed(1) }}</v-col>
            <v-col class="info_fond" cols=12 md="2">{{ summ.ZAKYP.toFixed(1) }}</v-col>
          </v-row>
        </v-col>
        <v-col class="col_line" col=12 md="12">
          <v-row>
            <v-col class="title_fond" cols=12 md="6">Налоги</v-col>
            <v-col class="info_fond" cols=12 md="2">{{ dtf.fond.nal.toFixed(1) }}</v-col>
            <v-col class="info_fond" cols=12 md="2">{{ dtf.tran.nal.toFixed(1) }}</v-col>
            <v-col class="info_fond" cols=12 md="2">{{ summ.nalog.toFixed(1) }}</v-col>
          </v-row>
        </v-col>
        <v-col class="col_line_h" col=12 md="12">
          <v-row>
            <v-col class="title_fond" cols=12 md="6">НДС</v-col>
            <v-col class="info_fond" cols=12 md="2">{{ dtf.fond.nds.toFixed(1) }}</v-col>
            <v-col class="info_fond" cols=12 md="2">{{ dtf.tran.nds.toFixed(1) }}</v-col>
            <v-col class="info_fond" cols=12 md="2">{{ summ.nds.toFixed(1) }}</v-col>
          </v-row>
        </v-col>
        <v-col class="col_line" col=12 md="12">
          <v-row>
            <v-col class="title_fond" cols=12 md="6">Маржа</v-col>
            <v-col class="info_fond" cols=12 md="2">{{ dtf.fond.marga.toFixed(1) }}</v-col>
            <v-col class="info_fond" cols=12 md="2">{{ dtf.tran.marga.toFixed(1) }}</v-col>
            <v-col class="info_fond" cols=12 md="2">{{ summ.marjas.toFixed(1) }}</v-col>
          </v-row>
        </v-col>
        <v-col class="col_line_h" col=12 md="12">
          <v-row>
            <v-col class="title_fond" cols=12 md="6">Фонд ЗП</v-col>
            <v-col class="info_fond" cols=12 md="2">{{ dtf.fond.fzp.toFixed(1) }}</v-col>
            <v-col class="info_fond" cols=12 md="2">{{ dtf.tran.fzp.toFixed(1) }}</v-col>
            <v-col class="info_fond" cols=12 md="2">{{ summ.fondzp.toFixed(1) }}</v-col>
          </v-row>
        </v-col>
        <v-col class="col_line" col=12 md="12">
          <v-row>
            <v-col class="title_fond" cols=12 md="6">Фонд годовой премии</v-col>
            <v-col class="info_fond" cols=12 md="2">{{ dtf.fond.fgp.toFixed(1) }}</v-col>
            <v-col class="info_fond" cols=12 md="2">{{ dtf.tran.fgp.toFixed(1) }}</v-col>
            <v-col class="info_fond" cols=12 md="2">{{ summ.fondp1.toFixed(1) }}</v-col>
          </v-row>
        </v-col>
        <v-col class="col_line_h" col=12 md="12">
          <v-row>
            <v-col class="title_fond" cols=12 md="6">Страховой фонд</v-col>
            <v-col class="info_fond" cols=12 md="2">{{ dtf.fond.fst.toFixed(1) }}</v-col>
            <v-col class="info_fond" cols=12 md="2">{{ dtf.tran.fst.toFixed(1) }}</v-col>
            <v-col class="info_fond" cols=12 md="2">{{ summ.fonds.toFixed(1) }}</v-col>
          </v-row>
        </v-col>
        <v-col class="col_line" col=12 md="12">
          <v-row>
            <v-col class="title_fond" cols=12 md="6">Корпоративный фонд</v-col>
            <v-col class="info_fond" cols=12 md="2">{{ dtf.fond.fkr.toFixed(1) }}</v-col>
            <v-col class="info_fond" cols=12 md="2">{{ dtf.tran.fkr.toFixed(1) }}</v-col>
            <v-col class="info_fond" cols=12 md="2">{{ summ.fondk.toFixed(1) }}</v-col>
          </v-row>
        </v-col>
        <v-col class="col_line_h" col=12 md="12">
          <v-row>
            <v-col class="title_fond" cols=12 md="6">Фонд маркетинга</v-col>
            <v-col class="info_fond" cols=12 md="2">{{ dtf.fond.fmr.toFixed(1) }}</v-col>
            <v-col class="info_fond" cols=12 md="2">{{ dtf.tran.fmr.toFixed(1) }}</v-col>
            <v-col class="info_fond" cols=12 md="2">{{ summ.fondm.toFixed(1) }}</v-col>
          </v-row>
        </v-col>
        <v-col class="col_line" col=12 md="12">
          <v-row>
            <v-col class="title_fond" cols=12 md="6">Фонд постоянных расходов</v-col>
            <v-col class="info_fond" cols=12 md="2">{{ dtf.fond.fpr.toFixed(1) }}</v-col>
            <v-col class="info_fond" cols=12 md="2">{{ dtf.tran.fpr.toFixed(1) }}</v-col>
            <v-col class="info_fond" cols=12 md="2">{{ summ.fondp.toFixed(1) }}</v-col>
          </v-row>
        </v-col>   
        
        
        
        <v-col class="col_line_h" col=12 md="12">
          <v-row>
            <v-col class="title_fond" cols=12 md="6">Фонд дивидендов</v-col>
            <v-col class="info_fond" cols=12 md="2">{{ dtf.fond.fdv.toFixed(1) }}</v-col>
            <v-col class="info_fond" cols=12 md="2">{{ dtf.tran.fdv.toFixed(1) }}</v-col>
            <v-col class="info_fond" cols=12 md="2">{{ summ.fondd.toFixed(1) }}</v-col>
          </v-row>
        </v-col>
        <v-col class="col_line" col=12 md="12">
          <v-row>
            <v-col class="title_fond" cols=12 md="6">Итого</v-col>
            <v-col class="info_fond" cols=12 md="2"></v-col>
            <v-col class="info_fond" cols=12 md="2"></v-col>
            <v-col class="info_fond" cols=12 md="2">{{ (summ.fondzp+summ.fondp1+summ.fonds+summ.fondk+summ.fondm+summ.fondp+summ.fondd).toFixed(1) }}</v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- <div style="margin-top: 10px">
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Поиск"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="summ"
            :items-per-page="10"
            :search="search"
            class="elevation-1"
            @click:row="readSkilld"
          ></v-data-table>
        </v-card>
      </div> -->
    </div>
  </template>
  
  <script>

  export default {
    data: () => ({
      dialog: false,
      search: "",
      filt: {
        post: [],
        client:[],
        project: [],
        otv: [],
        meneg:[],
        stad: [],
        dts: '',
        dte: '',
      },
      skill: {
        id: 0,
        name: "",
      },
      headers: [
      { text: "Выручка", value: "fondzp" },
        { text: "Нераспределенная выручка", value: "vipl" },
        { text: "Себестоимость", value: "fonds" },
        { text: "Налоги", value: "nalog" },        
        { text: "НДС", value: "fondm" },              
        { text: "Маржа", value: "marjas" },      
        { text: "ФОТ", value: "fondzp" },            
        { text: "Фонд пост", value: "fondp" },            
        { text: "Маркетинг", value: "fondm" },            
        { text: "Страховой", value: "fonds" },    
        { text: "Корпоративный", value: "fondk" },    
        { text: "Годовая премия", value: "fondp" },    
        { text: "Дивиденды", value: "fondd" },    
        { text: "Итого", value: "fondd" },
      ],
    }),
    computed: {
      load() {
        return this.$store.getters.getOnLoad;
      },
      dtf() {
        return this.$store.getters.ListDataFond;
      },
      desserts() {
        return this.$store.getters.getListBxDeal;
      },
      summ(){
        return this.$store.getters.ListSumm;
      },
      filter() {
        return this.$store.getters.ListFilter;
      },
      
    },
    mounted() {
        this.$store.dispatch("getTransactionFonds");
        this.$store.dispatch("getBxDealFonds");
        
    },
    name: "fonds",
    methods: {
      onfind(){
        this.$store.dispatch("FindBxDealFonds", this.filt);
      }      
      
    },
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .infoPerson {
    text-align: left;
  }

  .line_fond {
    margin: 20px;
  }

  .title_fond {
    width: 400px;
    font-size: 120%;
    font-weight: bold;
    text-align: left;
  }
  .col_line {
    margin-bottom: 10px;
  }

  .col_line_h {
    margin-bottom: 10px;
    background-color: #ddd;
  }

  </style>
  