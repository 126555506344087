<template>
    <v-container>
      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3"> восстановление пароля</h1>
      </v-col>
  
      <v-row>
        <v-col cols="4" class="mb-4"></v-col>
        <v-col cols="4" class="mb-4">
          <v-form ref="form" v-model="valid" lazy-validation>
            <element-loading :active="onLoadData" spinner="spinner" color="#FF6700"/>
            <v-text-field
              v-model="email"
              :rules="emailRules"
              label="введите E-mail для восстановления"
              
              required
            ></v-text-field>
  
            <v-btn color="success" :disabled="!valid" @click="onEnter">
              отправить
            </v-btn>
          </v-form>
        </v-col>
        <v-col cols="4" class="mb-4"> </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn color="primary" class="mr-4" to="/login">
            назад
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    data: () => ({
      valid: true,
      pass: "",
      email: "",
      emailRules: [
        (v) => !!v || "E-mail не введен",
        (v) => /.+@.+\..+/.test(v) || "E-mail не правильный",
      ],
      select: null,
      checkbox: false,
    }),
    name: "login",
    mounted() {
      if(this.isAuthUser) {
        let res = localStorage.getItem("b-hire-user-status");
         let r = localStorage.getItem("b-hire-user-link");
                if(res <= 3) {
            if (r == "0") {
              //this.$router.push('/cabinet')
              this.$router.push('/firm')
            } else {
              this.$router.push(r)
            }          
          } else {
            if(res > 3 & res != 7) {
              if (r == "0") {
              this.$router.push('/admspec')
            } else {
              this.$router.push(r)
            }         
            } else {
              if (r == "0") {
              this.$router.push('/project')
            } else {
              this.$router.push(r)
            }         
            }
            
          }
        
      }
    },
    methods: {
      validate() {
        this.$refs.form.validate();
      },
      onEnter() {
        let t  = {
          lg: this.email,
        }
        this.$store.dispatch("getRestoreUser", t).then(res => {         
          if(res == 'error'){
            alert("Данная почта не найдена в базе, проверьте правильность написания!")
          } else {
            alert("Вам отправлена ссылка на данную почту на востановление пароля. Пройдите по ней и востановите пароль! Ссылка дествительна 24 часа!")
          } 
          
          });
      },
      reset() {
        this.$refs.form.reset();
      },
      resetValidation() {
        this.$refs.form.resetValidation();
      },
    },
    computed: {
      isAuthUser() {
        return this.$store.getters.getAuthClient;
      },
      onLoadData() {
        return this.$store.getters.getOnLoad;
      },
    },
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped></style>
  