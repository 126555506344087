<template>
  <v-container>
    <v-col class="mb-4">
      <h1 class="display-2 font-weight-bold mb-3">Авторизация</h1>
    </v-col>

    <v-row>
      <v-col cols="4" class="mb-4"></v-col>
      <v-col cols="4" class="mb-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <element-loading :active="onLoadData" spinner="spinner" color="#FF6700"/>
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            required
          ></v-text-field>

          <v-text-field
            v-model="pass"
            label="пароль*"
            required
            type="password"
            v-on:keyup.enter = "onEnter"
          ></v-text-field>

          <v-btn color="#FFE816" :disabled="!valid" @click="onEnter">
            Войти
          </v-btn>
        </v-form>
      </v-col>
      <v-col cols="4" class="mb-4"> </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn color="primary" text class="mr-4" to="/restore">
          восстановление пароля
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn color="#FFE816" class="mr-4" to="/register">
          Зарегистрироваться
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    pass: "",
    email: "",
    emailRules: [
      (v) => !!v || "E-mail не введен",
      (v) => /.+@.+\..+/.test(v) || "E-mail не правильный",
    ],
    select: null,
    checkbox: false,
  }),
  name: "login",
  mounted() {
    if(this.isAuthUser) {
      let res = localStorage.getItem("b-hire-user-status");
       let r = localStorage.getItem("b-hire-user-link");
       let m = localStorage.getItem("b-hire-user-ddf");
              if(res <= 3) {
          if (r == "0") {
            if(m == 0 || m == 1 || m == 3) {
              this.$router.push('/firm')
            } else {
              this.$router.push('/cabinet')
            }
            
            //this.$router.push('/firm')
          } else {
            this.$router.push(r)
          }          
        } else {
          if(res > 3 & res != 7) {
            if (r == "0") {
            this.$router.push('/admspec')
          } else {
            this.$router.push(r)
          }         
          } else {
            if (r == "0") {
            this.$router.push('/project')
          } else {
            this.$router.push(r)
          }         
          }
          
        }
      
    }
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    onEnter() {
      let t  = {
        lg: this.email,
        ps: this.pass,
      }
      this.$store.dispatch("getListMainClient", t).then(res => {
       
            let r = localStorage.getItem("b-hire-user-link");
          console.log("link ");
          console.log(r);
          if(res <= 3) {
          
            if (r == "0") {
              this.$router.push('/cabinet')
            } else {
              this.$router.push(r)
            }          
          } else {
            if(res > 3 & res != 7) {
              if (r == "0") {
              this.$router.push('/admspec')
            } else {
              this.$router.push(r)
            }         
            } else {
              if (r == "0") {
              this.$router.push('/project')
            } else {
              this.$router.push(r)
            }         
            }
            
          }
        

      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  computed: {
    isAuthUser() {
      return this.$store.getters.getAuthClient;
    },
    onLoadData() {
      return this.$store.getters.getOnLoad;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
