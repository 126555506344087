<template>
    <div style="margin-right: 10px">
   
    <v-row align="center" justify="space-around" style="margin-bottom: 10px">
      <v-btn to="/admin/project/add"> ДОБАВИТЬ ПРОЕКТ + </v-btn>
    </v-row>
    <div style="margin-top: 10px">
      <v-card>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Поиск"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="listProj"
          :items-per-page="10"
          :search="search"
          class="elevation-1"
          
        >
        <template v-slot:item.act="{ item }">
          <v-btn class="btn_read" @click="getInfoProject(item)" title="редактировать специалиста" style="margin-top:2px; margin-bottom: 3px">
            Изменить
          </v-btn>
        </template>
        </v-data-table>
      </v-card>
    </div>

  </div>
</template>

<script>
export default {
  data: () => ({
    search: '',
    headers: [
      {
        text: "id",
        value: "id",
      },
      { text: "Дата создания", value: "dateCreate" },
      { text: "Ссылка на проект", value: "link" },
      { text: "Заказчик", value: "firm" },
      { text: "уровень", value: "lvl" },
      { text: "роль", value: "nameCategories" },
      { text: "О проекте", value: "abount" },
      { text: "Требования", value: "requirements" },
      { text: "Рейт", value: "rt" },
      { text: "Статус", value: "nameSatatus" },
      { text: "", value: "act" },
    ],
    desserts: [],


  }),
  computed: {
    listProj() {
      return this.$store.getters.getListProject;
    }
  },
  name: "projectAdmin",
  mounted() {
    this.$store.dispatch("getProjectList");
  },
  methods: {
    getInfoProject(t){
       this.$store.dispatch("updateProject", t).then(res => {
            if(res) {
                //alert('не удалось сохранить проект!');
               this.$router.push('/admin/project/add') 
            } else {
                alert('не удалось получить информацию о проекте!');
            }
        });
    }
  },
};
</script>

<style scoped>

</style>