<template>
  <v-container>
    <v-dialog v-model="dialog" persistent fullscreen hide-overlay scrollable>
      <v-card>
        <v-card-title>
          <span class="text-h5">Информация о компании: {{firm[0].nameFirm}}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <p><b>Директор:</b> {{firm[0].FIOR}}</p>
              </v-col>
              <v-col cols="12"  md="6">
                <p><b>email: </b> {{firm[0].email}}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <p><b>Телефон:</b> {{firm[0].phone}}</p>
              </v-col>
              <v-col cols="12"  md="6">
                <p><b>ОГРН:</b> {{firm[0].OGRN}}</p>
              </v-col>
            </v-row>
             <v-row>
              <v-col cols="12" md="6">
                <p><b>ИНН:</b> {{firm[0].inn}}</p>
              </v-col>
              <v-col cols="12"  md="6">
                <p><b>Адресс:</b> {{firm[0].adress}}</p>
              </v-col>
            </v-row>
            <v-row>
               <v-card width="100%">
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
              <v-data-table
    :headers="headers_spec"
    :items="firm.spec"
    :items-per-page="10"
    :search="search"
    class="elevation-1"
    style = "width:100%"
  >
  </v-data-table>
   </v-card>
            </v-row>
             <!-- <v-row>
              <v-col cols="12" md="6">
                <p><b></b> {{firm[0].}}</p>
              </v-col>
              <v-col cols="12"  md="6">
                <p><b></b> {{firm[0].}}</p>
              </v-col>
            </v-row> -->
          </v-container>
        </v-card-text>
        <v-card-actions>
           <v-btn color="blue darken-1" text @click="NewPass(firm[0].id)">
            Сменить пароль
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            OK
          </v-btn>
          
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-col class="mb-4">
      <h1 class="display-2 font-weight-bold mb-3">Компании</h1>
    </v-col>
<v-row>
  <v-col cols="6" md="3" sm="6" xs="6">
            <v-text-field  label="ИНН" outlined type="number"
              hide-details="auto" dense chips ></v-text-field>
          </v-col>
          <v-col cols="6" md="3" sm="6" xs="6">
            <v-text-field  label="ид битрикса" outlined type="number"
              hide-details="auto" dense chips ></v-text-field>
          </v-col>
          <v-col cols="6" md="3" sm="6" xs="6">
          <v-autocomplete :items="skills" dense outlined chips small-chips
                            required persistent-hint label="Ключевые технологии*" multiple></v-autocomplete>
          </v-col>
</v-row>
    <v-row>
      <v-col cols="12" class="mb-12">
        
        <v-card>
       <v-card-title>
      <v-text-field
        v-model="search2"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
  <v-data-table
    :headers="headers"
    :search="search2"
    :items="desserts"
    :items-per-page="10"
    class="elevation-1"
  >
<template v-slot:item.iron="{ item }">
  <v-btn  elevation="2" small style="margin-bottom: 5px;"
          title="информация" @click="openInfoSpec(item.id)">
         Подробнее
        </v-btn>
        <!-- <br />
           
            <v-btn v-if="item.bl==0" elevation="2" small @click="blokCompany(item.id)" style="margin-bottom: 5px;"
              title="Блокировать">
             Блок
            </v-btn>
             <v-btn v-else elevation="2" small @click="blokCompany(item.id)" style="margin-bottom: 5px;"
              title="Разблокировать">
             деБлок
            </v-btn> -->
           

          </template>

          <template v-slot:item.comment="{ item}">
           <v-textarea name="input-5-1" label="Комментарий"
                    v-model="item.comment"></v-textarea>
                 <v-btn elevation="2" small @click="saveCommit(item.id, item.comment)" style="margin-bottom: 5px;"
              title="Сохранить комментарий">
             Сохранить
            </v-btn>    
          </template>

          <template v-slot:item.lvl="{ item}">
            <v-select
            v-model="item.name_rank"
            @change="updateRaitingCompany(item.name_rank, item.id)"
          :items="['1 уровень','2 уровень','3 уровень','Черный список','не определен']"
          label="Уровень"
          dense
          outlined
          style="margin-top: 10px"
        ></v-select>
          </template>

</v-data-table>
 </v-card>

      </v-col>
      
    </v-row>
    
  </v-container>
</template>

<script>
export default {
  data: () => ({
    search: '',
    search2: '',
    dialog: false,
    firm: {
      0: {
FIOR: '',
email: '',
phone: '',
OGRN: '',
inn: '',
adress: '',
      },
      'spec': '',
    },
    headers: [
          {
            text: 'Ид',
            value: 'id',
          },
          { text: 'Компания', value: 'nameFirm' },
          { text: 'ИНН', value: 'inn' },
          { text: 'КПП', value: 'KPP' },
          { text: 'Действие', value: 'iron' },
          { text: 'Коментарий', value: 'comment' },
          { text: 'Уровень партнера', value: 'lvl' },
        ],
        headers_spec:[
    {
            text: 'Ид',
            value: 'id',
          },
          { text: 'ФИО', value: 'FIO' },
          { text: 'РОЛЬ', value: 'Category' },
          { text: 'УРОВЕНЬ', value: 'Level' },
          { text: 'СТРАНА', value: 'Country' },
          { text: 'СКИЛЫ', value: 'Main_skills' },
  ],
  }),
  
  name: "ListCompany",
  mounted() {
   this.$store.dispatch("getCompanyList");
   this.$store.dispatch("getSkillsList");
  },
  methods: {
    
    blokCompany(t) {
      this.$store.dispatch("setCompanyBlock", t);
    },
    updateRaitingCompany(t, y) {
      let res = {
        id: y,
        zn: t,
      }
      console.log(res)
      this.$store.dispatch("updateRaitingCompany", res);
    },
    openInfoSpec(t) {
this.$store.dispatch("getFullInfoCompany", t).then(res => {
  this.dialog = true;
  this.firm = res;
  console.log(res);
});
    },
    saveCommit(t, e) {
      let res = {
        id: t,
        zn: e,
      }
      this.$store.dispatch("SaveCommitCompany", res);
    },
    NewPass(t) {
      //console.log(t);
      this.$store.dispatch("getNewPassCompany", t).then(res => {
        alert(res)
      });
    }
  },
  computed: {
    isAuthUser() {
      return this.$store.getters.getAuthClient;
    },
    desserts() {
      return this.$store.getters.getListCompanyEdit;
    },
    skills() {
            return this.$store.getters.getListSkills;
        },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
