<template>
<v-container>
    <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">Редактировани CV</h1>
    </v-col>

    <v-row>
        <v-col cols="2" class="mb-2"></v-col>
        <v-col cols="8" class="mb-8">
            <v-row>
                <v-col cols="12">
                    <v-text-field label="Имя" v-model="listCV.name" required></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-autocomplete v-model="listCV.levelSpec" :items="listLevel" deletable-chips outlined hide-details="auto" dense chips small-chips label="Уровень"></v-autocomplete>
                    <v-text-field v-model="listCV.category" label="Категория" required></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-autocomplete v-model="listCV.role" :items="categories" deletable-chips outlined hide-details="auto" dense chips small-chips label="Роль"></v-autocomplete>
                </v-col>
                <v-col cols="12">
                    <v-autocomplete v-model="listCV.lang" :items="langLevel" deletable-chips outlined hide-details="auto" dense chips small-chips label="Уровень английского языка"></v-autocomplete>
                </v-col>
                <v-col cols="12">
                    <v-autocomplete v-model="listCV.location" :items="listLocation" deletable-chips outlined hide-details="auto" dense chips small-chips label="Локация"></v-autocomplete>
                </v-col>
                <v-col cols="12">
                    <v-text-field v-model="listCV.expNumber" label="Коммерческий опыт" required></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field v-model="listCV.Skill" label="Ключевые навыки" required></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field v-model="listCV.info" label="Дополнительная информация*" required v-on:keyup.enter="onEnter"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field v-model="listCV.lingGoogle" label="Гугл ссылка" required v-on:keyup.enter="onEnter"></v-text-field>
                </v-col>
                <v-col cols="12">

                    <fieldset>
                        <legend>Опыт работы</legend>
                        <v-row style="margin: 5px" v-for="(n, i) in listCV.expOrg" :key="i">
                            <v-col cols="12">
                                <v-text-field v-model="n.dtstart" label="Период начало" required v-on:keyup.enter="onEnter"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field v-model="n.dtEnd" label="Период конец" required v-on:keyup.enter="onEnter"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field v-model="n.nameProg" label="Проект" required v-on:keyup.enter="onEnter"></v-text-field>
                            </v-col>
                            <v-col cols="12">
<v-textarea name="input-7-1" label="Описание" 
                    v-model="n.infoProg"></v-textarea>
                               
                            </v-col>
                            <v-col cols="12">
                                <v-autocomplete v-model="n.role" :items="categories" deletable-chips outlined hide-details="auto" dense chips small-chips label="Роль"></v-autocomplete>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea name="input-7-1" label="Полномочия, обязанности и достижения:" 
                    v-model="n.responsibilities"></v-textarea>
                                z
                            </v-col>
                            <v-col cols="12">
                                <v-text-field v-model="n.command" label="Команда" required v-on:keyup.enter="onEnter"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-btn color="#FFE816" :disabled="!valid" @click="onEnter">
                                    Удалить
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-col cols="12" style="text-align: right">
                                <v-btn color="#FFE816" :disabled="!valid" @click="onEnter">
                                    Добавить
                                </v-btn>
                            </v-col>
                    </fieldset>
                </v-col>
                <v-col cols="12">
                    <v-btn color="#FFE816" :disabled="!valid" @click="onEnter">
                        Сохранить
                    </v-btn>
                </v-col>
            </v-row>

        </v-col>
        <v-col cols="2" class="mb-2"> </v-col>
    </v-row>
</v-container>
</template>

<script>
export default {
    data: () => ({
        valid: true,
        pass: "",
        email: "",
        listCV2: {},
        select: null,
        checkbox: false,

    }),
    name: "sdghsdg",
    mounted() {
        this.$store.dispatch("getListPersonSpecAction");
        this.$store.dispatch("getListCompanySkill");
        this.$store.dispatch("getSpecialisеCV");
        this.$store.dispatch("getLocationListActive");
        this.$store.dispatch("getSkillsList");
        this.$store.dispatch("getLangLevelList");
        this.$store.dispatch("getLangList");
        this.$store.dispatch("getLevelList");
        this.$store.dispatch("getRoleList");
        this.$store.dispatch("getTimeZone");
        this.$store.dispatch("getCategories");
        this.$store.dispatch("getCompanyList");
        this.$store.dispatch("getIndustriesList");
        this.$store.dispatch("getLevelEducationList");
        this.$store.dispatch("getEducationList");
        this.$store.dispatch("getCountryToTimeZone");
        this.$store.dispatch("getRequestClient");
        this.$store.dispatch("getCustomer");

    },
    methods: {
        validate() {
            this.$refs.form.validate();
        },
        onEnter() {
            let t = {
                lg: this.email,
                ps: this.pass,
            }
            this.$store.dispatch("getListMainClient", t).then(res => {

                let r = localStorage.getItem("b-hire-user-link");
                console.log("link ");
                console.log(r);
                if (res <= 3) {

                    if (r == "0") {
                        this.$router.push('/cabinet')
                    } else {
                        this.$router.push(r)
                    }
                } else {
                    if (res > 3 & res != 7) {
                        if (r == "0") {
                            this.$router.push('/admspec')
                        } else {
                            this.$router.push(r)
                        }
                    } else {
                        if (r == "0") {
                            this.$router.push('/project')
                        } else {
                            this.$router.push(r)
                        }
                    }

                }

            });
        },
        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
    },
    computed: {

        desserts() {
            return this.$store.getters.getListPersonSpec;
            //return this.$store.getters.getMainSpecialist;
        },
        desserts_comp() {
            return this.$store.getters.getListCompanySkill;
            //return this.$store.getters.getMainSpecialist;
        },
        listCV() {
            return this.$store.getters.ListCVSpec;
        },
        listSkills() {
            return this.$store.getters.getListSkills;
        },
        dataCVPerson() {
            return this.$store.getters.getPersonCV;
        },
        expList() {
            return this.$store.getters.getListExp;
        },
        listLocation() {
            return this.$store.getters.getListLocationAll;
        },
        langLevel() {
            return this.$store.getters.getListLangLevel;
        },
        langName() {
            return this.$store.getters.getListLang;
        },
        listRole() {
            return this.$store.getters.getListRole;
        },
        levelSpec() {
            return this.$store.getters.getListLevel;
        },
        roleSpec() {
            return this.$store.getters.getListRole;
        },
        timeZone() {
            return this.$store.getters.getTimeZone;
        },
        skills() {
            return this.$store.getters.getListSkills;
        },
        categories() {
            return this.$store.getters.getListCategories;
        },
        url() {
            return this.$store.getters.getBaseUrl;
        },
        company() {
            return this.$store.getters.getListCompany;
        },
        listLevel() {
            return this.$store.getters.getListLevel;
        },
        industries() {
            return this.$store.getters.getListIndustries;
        },
        levelEduc() {
            return this.$store.getters.getListLevelEducation;
        },
        educationList() {
            return this.$store.getters.getListEducation;
        },
        load() {
            return this.$store.getters.getOnLoad;
        },
        CountryToTimeZone() {
            return this.$store.getters.getOnListContryTimeZone;
        },
        requestClient() {
            return this.$store.getters.getListRequestCLient;
        },
        customer() {
            return this.$store.getters.getListCustomer;
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped></style>
