<template>
  <div :style="{'margin-right': '10px', 'margin-top' : heightSize-100+'px'}" class="overflow-hidden">
    <v-app-bar 
    app
    prominent
    :style="{'height': heightSize+'px'}"
    >
      <v-card ref="findPanel">
        <v-container fluid>
          <v-row align="center">
            <v-col cols="6" md ="3" sm="6" xs="6">
              <v-autocomplete
                v-model="find.country"
                :items="listLocation"
                deletable-chips
                outlined
                hide-details="auto"
                dense
                chips
                small-chips
                label="Локация"
                multiple
                @change="onFind"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" md ="3" sm="6" xs="6">
              <v-autocomplete
                v-model="find.lang"
                :items="langLevel"
                deletable-chips
                outlined
                hide-details="auto"
                dense
                chips
                small-chips
                label="Уровень английского не ниже"
                multiple
                @change="onFind"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" md ="3" sm="6" xs="6">
              <v-autocomplete
                v-model="find.level"
                :items="listLevel"
                deletable-chips
                outlined
                hide-details="auto"
                dense
                chips
                small-chips
                label="Уровень"
                multiple
                @change="onFind"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" md ="3" sm="6" xs="6">
              <v-autocomplete
                v-model="find.role"
                :items="categories"
                deletable-chips
                outlined
                hide-details="auto"
                dense
                chips
                small-chips
                label="Роль"
                multiple
                @change="onFind"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" md ="3" sm="6" xs="6">
              <v-text-field
                v-model="find.rate"
                label="Ставка до (руб./час)"
                outlined
                hide-details="auto"
                dense
                chips
                @change="onFind"
              ></v-text-field>
            </v-col>
            <v-col cols="6" md ="3" sm="6" xs="6">
              <v-autocomplete
                v-model="find.skills"
                :items="listSkills"
                deletable-chips
                outlined
                hide-details="auto"
                dense
                single-line
                chips
                hide-no-data 
                small-chips
                label="Навыки"
                multiple
                @change="onFind"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" md ="3" sm="6" xs="6">
              <v-text-field
                v-model="find.pub"
                label="Коммерческий опыт (лет), от"
                outlined
                type="number"
                hide-details="auto"
                dense
                chips
                @change="onFind"
              ></v-text-field>
            </v-col>
            <v-col cols="6" md ="3" sm="6" xs="6">
              <v-btn
                elevation="2"
                style="width: 100%"
                @click="onClear"
              >
              Очистить все
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-app-bar>
    <v-sheet
      id="scrolling-techniques-7"
      class="overflow-y-auto"
    >
    <div style="margin-top: 10px">
      <element-loading :active="onLoadData" spinner="spinner" color="#FF6700"/>
      <v-card>
        <!-- <v-card-title>
           <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Поиск"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title> -->
        <v-data-table
          :headers="headers"
          :items="desserts"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          :loading="loading" loading-text="Loading... Please wait"
          :search="search"
          class="elevation-1"
          hide-default-footer
        >
         <template v-slot:item.linkCV="{ item }">
           <v-btn
            elevation="2"
            small
            :href = "item.linkCV"
            target="_blank"
            style="margin-bottom: 5px;margin-top: 5px; width: 100%"
          >
          Посмотреть
          </v-btn>
          
          <v-btn
            elevation="2"
            small
            :href = "'https://t.me/bhireinterview_bot?start=2_'+item.id"
            target="_blank"
            style="margin-bottom: 5px; width: 100%"
          >
          Запросить доступность
          </v-btn>
      </template>
        </v-data-table>
       
      </v-card>
    </div>
    </v-sheet>
    <v-row class="text-center px-4 align-center" wrap>
            <v-col class="text-truncate" cols="12" md="12">
                Всего {{ totalRecords }} записей
            </v-col>
            <v-col cols="12" md="12">
                <v-pagination
                    v-model="page"
                    :total-visible="7"
                    :length="pageCount">
                </v-pagination>
            </v-col>
            
        </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    page: 1,
    itemsPerPage: 10,
    heightSize: 140,
    perPageChoices: [
          {text:'5 записей' , value: 5},
          {text:'10 записей' , value: 10},
          {text:'20 записей' , value: 20},
      ],
    exp: false,
    edu: false,
    loading: null,
    search: "",
    files: "",
    search_exp: "",
    uploadFoto: false,
    date: "",
    project: {
      dts: "",
      dte: "",
      role: "",
      ind: "",
      descr: "",
      kol: "",
      skill: [],
      poln: "",
      read: -1,
      firm: '',
    },
    education: {
      title: "",
      dtn: "",
      dte: "",
      level: "",
      spec: "",
      read: -1,
    },
    data: {
      id: 0,
      name: "",
      abbr: "",
      nameRus: "",
    },
    spec: {
      id: 0,
      foto: "",
      about: "",
      fio: "",
      cat: "",
      role: "",
      level: "",
      grag: "",
      loc: "",
      zone: "",
      firm: "",
      exp: "",
      rater: "",
      lang: [
        {
          name: "Английский",
          level: "a1 - beginner",
        },
      ],
      educ: [],
      skills: [],
      exp_proj: [],
    },
    tab: null,
    items2: ["О специалисте", "Навыки", "Опыт", "Образование"],
    find: {
      type: 'spec',
      country: [],
      location: [],
      lang: [],
      level: [],
      role: [],
      rate: [],
      skills: [],
      pub: [],
    },
    items: ["foo", "bar", "fizz", "buzz"],
    values: [],
    value: null,
    headers: [
      { text: "id", sortable: false, value: "id" },
          {
            text: "Имя", sortable: false,
            value: "FIO",
          },
          { text: "Локация", sortable: false, value: "Country" },
          { text: "Английский", sortable: false, value: "English" },
          { text: "Уровень", sortable: false, value: "nameLevel" },
          { text: "Роль", sortable: false, value: "Role" },
          { text: "Ставка (руб./час)", sortable: false, value: "Rate_RUB" },
          { text: "Навыки", sortable: false, value: "Main_skills" },
          { text: "Статус", sortable: false, value: "Status" },
          { text: "Комерческий опыт", sortable: false, value: "Years_of_Experience" },
          { text: "CV", sortable: false, value: "linkCV" },
    ],
    headers_exp: [
      { text: "Роль", value: "role" },
      { text: "Начало", value: "dts" },
      { text: "Конец", value: "dts" },
      { text: "Индустрия", value: "ind" },
      { text: "кол-во чел", value: "kol" },
    ],
    headers_edгс: [
      {
        text: "Название",
        value: "title",
      },
      { text: "Дата начала", value: "dtn" },
      { text: "Дата окончания", value: "dte" },
      { text: "уровень", value: "level" },
      { text: "специальность", value: "spec" },
    ],
    
    desserts_exp: [],
    menu: false,
    menu2: false,
    menu_edu_dtn: false,
    menu_edu_dte: false,
    nameRules: [
      (v) => !!v || "Название не может быть пустым",
      (v) => (v && v.length >= 1) || "Name must be less than 10 characters",
    ],

  }),
  computed: {
    totalRecords() {
          return this.desserts.length
      },
      pageCount() {
          return Math.ceil(this.totalRecords / this.itemsPerPage)
      },
    listLocation() {
      return this.$store.getters.getListLocation;
    },
    listLang() {
      return this.$store.getters.getListLang;
    },
    listLevel() {
      return this.$store.getters.getListLevel;
    },
    listRole() {
      return this.$store.getters.getListRole;
    },
    listRate() {
      return this.$store.getters.getListRate;
    },
    listPub() {
      return this.$store.getters.getListPub;
    },
    listSkills() {
      return this.$store.getters.getListSkills;
    },
    dataCVPerson() {
      return this.$store.getters.getPersonCV;
    },
    desserts() {
      return this.$store.getters.getMainSpecialist;
    },
    expList() {
      return this.$store.getters.getListExp;
    },
    country() {
      return this.$store.getters.getListLocation;
    },
    langLevel() {
      return this.$store.getters.getListLangLevel;
    },
    langName() {
      return this.$store.getters.getListLang;
    },
    levelSpec() {
      return this.$store.getters.getListLevel;
    },
    roleSpec() {
      return this.$store.getters.getListRole;
    },
    timeZone() {
      return this.$store.getters.getTimeZone;
    },
    skills() {
      return this.$store.getters.getListSkills;
    },
    categories() {
      return this.$store.getters.getListCategories;
    },
    url() {
      return this.$store.getters.getBaseUrl;
    },
    company() {
      return this.$store.getters.getListCompany;
    },
    industries() {
      return this.$store.getters.getListIndustries;
    },
    levelEduc() {
      return this.$store.getters.getListLevelEducation;
    },
    educationList() {
      return this.$store.getters.getListEducation;
    },
    load() {
      return this.$store.getters.getOnLoad;
    },
    CountryToTimeZone() {
      return this.$store.getters.getOnListContryTimeZone;
    },
    onLoadData() {
      return this.$store.getters.getOnLoad;
    },
    statusUser() {
      return this.$store.getters.getStatusUser;
    }
  },
  name: "cabinet",
  mounted() {
    console.log(this.$refs.findPanel.$refs.link.offsetHeight);
    this.heightSize = this.$refs.findPanel.$refs.link.offsetHeight+ 10;
    
    this.$store.dispatch("getListPersonSpecActionInf");
    this.$store.dispatch("getLocationListActive");
    this.$store.dispatch("getSkillsList");
    this.$store.dispatch("getLangLevelList");
    this.$store.dispatch("getLangList");
    this.$store.dispatch("getLevelList");
    this.$store.dispatch("getRoleList");
    this.$store.dispatch("getTimeZone");
    this.$store.dispatch("getCategoriesAction");
    this.$store.dispatch("getCompanyList");
    this.$store.dispatch("getIndustriesList");
    this.$store.dispatch("getLevelEducationList");
    this.$store.dispatch("getEducationList");
    this.$store.dispatch("getCountryToTimeZone");
  },
  watch: {

  },
  methods: {
    getCVPerson(e){
      console.log(e)     
      //this.$store.dispatch("getPersonCV", e.id);
      this.spec.id = e.id;
        this.spec.grag = e.nameCountryRus;
        this.spec.fio = e.FIO;
        this.spec.level = e.nameLevel;
        this.spec.rater = e.Rate_RUB;
        this.spec.rolecat = e.nameCategories;
        this.spec.firm = e.nameFirm;
        this.spec.exp = e.Years_of_Experience;
        this.spec.cat = e.nameCategories;
        this.spec.zone = e.nameTimeZone;
        this.spec.about = e.about;
        this.spec.educ = e.edu;
        this.spec.exp_proj = e.exp;
        this.spec.skills = e.skills;
        this.spec.about = e.about;
         this.dialog = true;
    },
    onFind(){
      this.heightSize = this.$refs.findPanel.$refs.link.offsetHeight+ 10;
      this.$store.dispatch("onSeachSpecial", this.find);
    },
    onClose() {
      this.dialog = false;
    },
    readData(e) {
      this.$store.dispatch("getPersonCV", e.id).then(() => {
        this.spec.id = e.id;
        this.spec.grag = this.dataCVPerson.nameCountry;
        this.spec.fio = this.dataCVPerson.FIO;
        this.spec.level = this.dataCVPerson.nameLevel;
        this.spec.rater = this.dataCVPerson.Rate_RUB;
        this.spec.cat = this.dataCVPerson.nameRole;
        this.spec.firm = this.dataCVPerson.nameFirm;
        this.spec.exp = this.dataCVPerson.Years_of_Experience;
        this.spec.role = this.dataCVPerson.nameCategories;
        this.spec.zone = this.dataCVPerson.nameTimeZone;
        this.spec.about = this.dataCVPerson.about;
        this.spec.educ = this.dataCVPerson.edu;
        this.spec.exp_proj = this.dataCVPerson.exp;
        this.spec.skills = this.dataCVPerson.skills;
        this.spec.lang = this.dataCVPerson.lang;
        this.dialog = true;
      });
    },
    readEducation(e, r) {
      console.log(r);
      this.education.title = e.title;
      this.education.level = e.level;
      this.education.spec = e.spec;
      this.education.dtn = e.dtn;
      this.education.dte = e.dte;
      this.education.read = r.index;
    },
    readDataProj(e, r) {
      this.project.title = e.title;
      this.project.dts = e.dts;
      this.project.dte = e.dte;
      this.project.role = e.role;
      this.project.ind = e.ind;
      this.project.descr = e.descr;
      this.project.kol = e.kol;
      this.project.skill = e.skill;
      this.project.poln = e.poln;
      this.project.read = r.index;
    },
    DelDataEdu() {
      let n = confirm("Вы уверены что хотите удалить?");
      if (n == true) {
        this.spec.educ.splice(this.education.read, 1);
        this.education.title = "";
        this.education.dtn = "";
        this.education.dte = "";
        this.education.level = "";
        this.education.spec = "";
        this.education.read = -1;
      }
    },
    delDataExp() {
      let n = confirm("Вы уверены что хотите удалить?");
      if (n == true) {
        this.spec.exp_proj.splice(this.education.read, 1);
        this.project.title = "";
        this.project.dts = "";
        this.project.dte = "";
        this.project.role = "";
        this.project.ind = "";
        this.project.descr = "";
        this.project.kol = "";
        this.project.skill = [];
        this.project.poln = "";
        this.project.read = -1;
      }
    },
    onNewData() {
      this.spec.id = 0; 
        this.spec.grag = "";
        this.spec.fio = "";
        this.spec.level = "";
        this.spec.rater = '';
        this.spec.cat = '';
        this.spec.firm = '';
        this.spec.exp = '';
        this.spec.role = '';
        this.spec.zone = '';
        this.spec.about = '';
        this.spec.educ = [];
        this.spec.exp_proj = [];
        this.spec.skills = [];
        this.spec.lang = [
        {
          name: "Английский",
          level: "a1 - beginner",
        },
      ];
      this.dialog = true;
    },
    saveData() {
      this.spec.foto = this.url;
      this.$store.dispatch("saveSpec", this.spec);
      this.dialog = false;
    },
    saveDataExp() {
      this.$store.dispatch("AddListExp", this.project);
      if (this.project.read == -1) {
        this.spec.exp_proj.push(JSON.parse(JSON.stringify(this.project)));
      } else {
        this.spec.exp_proj[this.project.read].title = this.project.title;
        this.spec.exp_proj[this.project.read].dts = this.project.dts;
        this.spec.exp_proj[this.project.read].dte = this.project.dte;
        this.spec.exp_proj[this.project.read].role = this.project.role;
        this.spec.exp_proj[this.project.read].ind = this.project.ind;
        this.spec.exp_proj[this.project.read].descr = this.project.descr;
        this.spec.exp_proj[this.project.read].kol = this.project.kol;
        this.spec.exp_proj[this.project.read].skill = this.project.skill;
        this.spec.exp_proj[this.project.read].poln = this.project.poln;
      }
      this.project.title = "";
      this.project.dts = "";
      this.project.dte = "";
      this.project.role = "";
      this.project.ind = "";
      this.project.descr = "";
      this.project.kol = "";
      this.project.skill = [];
      this.project.poln = "";
      this.project.read = -1;
      this.exp = false;
    },
    newLangPers() {
      this.spec.lang.push({ name: "Английский", level: "a1 - beginner" });
    },
    delLangPers(t) {
      this.spec.lang.splice(t, 1);
      console.log(this.spec.lang);
    },
    setFoto() {
      if (this.files != null) {
        this.uploadFoto = true;
        this.$store.dispatch("setFotoSpec", this.files);
      } else {
        this.uploadFoto = false;
      }
    },
    saveDataEdu() {
      if (
        this.education.title == "" ||
        this.education.dtn == "" ||
        this.education.dte == "" ||
        this.education.level == "" ||
        this.education.spec == ""
      ) {
        alert("Не заполнены все поля!");
      } else {
        console.log();
        if (this.education.read == -1) {
          this.spec.educ.push(JSON.parse(JSON.stringify(this.education)));
        } else {
          this.spec.educ[this.education.read].title = this.education.title;
          this.spec.educ[this.education.read].dtn = this.education.dtn;
          this.spec.educ[this.education.read].dte = this.education.dte;
          this.spec.educ[this.education.read].level = this.education.level;
          this.spec.educ[this.education.read].spec = this.education.spec;
          this.spec.educ[this.education.read].read = this.education.read;
        }
        this.education.title = "";
        this.education.dtn = "";
        this.education.dte = "";
        this.education.level = "";
        this.education.spec = "";
        this.education.read = -1;
        this.edu = false;
      }
    },
    savedateS(t) {
      this.education.dtn = t;
      this.menu_edu_dtn = false;
    },
    savedateE(t) {
      this.education.dte = t;
      this.menu_edu_dte = false;
    },
    savedateSProj(t) {
      this.project.dts = t;
      this.menu = false;
    },
    savedateEProj(t) {
      this.project.dte = t;
      this.menu2 = false;
    },
    DateNow(t) {
      if (t == 1) {
        this.project.dte = "По настоящее время";
        this.menu2 = false;
      } else {
        this.education.dte = "По настоящее время";
        this.menu_edu_dte = false;
      }
    },
    onClear() {
      this.find.country = "";
      this.find.location = "";
      this.find.lang = "";
      this.find.level = "";
      this.find.role = "";
      this.find.rate = "";
      this.find.skills = "";
      this.find.pub = "";
      this.$store.dispatch("getListPersonSpecActionInf");
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.infoPerson {
  text-align: left;
}

.v-data-footer {
        justify-content: center;
        padding-bottom: 8px;
    }
.v-data-footer__select {
            margin: none;
        }

</style>
