<template>
  <v-container>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Задачи</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field label="Название задачи" required v-model="task.name"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field type="datetime-local" label="Дата окончания" v-model="task.date"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    :items="['Все', 'Баранов Дмитрий Сергеевич', 'Алла Семенникова', 'Санита Пшеничникова', 'Асель Бекова', 'Ксения Черенда', 'Екатерина Стулова', 'Александр Малышев', 'Екатерина', 'Ольга Герасимова', 'Михаил Покровский', 'Полина Коровинская']"
                    label="Коллеги" multiple v-model="task.users"></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">
              Закрыть
            </v-btn>
            <v-btn color="blue darken-1" text @click="onSave">
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        Задачи
      </v-col>
      <v-col cols="12" md="6">
        <v-btn @click="dialog = true">Добавить</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table :headers="headers" :items="desserts" :items-per-page="5" class="elevation-1" @click:row="onRead"></v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    dialog: false,
    task: {
      name: '',
      date: '',
      users: [],
    },
    headers: [
      {
        text: 'Задача',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      { text: 'Дата создания', value: 'dts' },
      { text: 'Дата окончания', value: 'dte' },
      { text: 'Пользователи', value: 'users' },
    ],
    desserts: [
      {
        name: 'Test task',
        dts: '2024-04-27T12:16',
        dte: '2024-05-31T12:16',
        users: 'Я',
      },
    ]

  }),
  name: "taskList",
  mounted() {

  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    onSave() {
      const timeElapsed = Date.now();
      const today = new Date(timeElapsed);
      let t = {
        name: this.task.name,
        dts: today.toISOString(),
        dte: this.task.date,
        users: this.task.users.join(', ')
      }
      this.desserts.push(t);
      this.task.name = '';
      this.task.date = '';
      this.task.users = [];
      this.dialog = false;
    },
    onRead(e) {
      this.task.name = e.name;
      this.task.date = e.dte;
      this.task.users = e.users.split(', ')
      this.dialog = true;
    }

  },
  computed: {
    onLoadData() {
      return this.$store.getters.getOnLoad;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>