import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router';
import Axios from 'axios';
import loader from "vue-ui-preloader";
import ElementLoading from 'vue-element-loading'
import { VueMaskDirective } from 'v-mask'

Vue.config.productionTip = false

Vue.use(loader);
Vue.component('ElementLoading', ElementLoading)
Vue.directive('mask', VueMaskDirective);
new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
  created () {
    const token = localStorage.getItem('b-hire-user-token')
    //Axios.defaults.baseURL = 'http://job.local';
    Axios.defaults.baseURL = 'https://humacl.x-or.ru/';
    //Axios.defaults.baseURL = 'https://server.b-hire.ru/';
     if (token) {
       this.$store.dispatch('autoLoginUser', token)
     } 
  }
}).$mount('#app')
