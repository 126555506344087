<template>
  <div style="margin-right: 10px">
    <v-dialog v-model="dialog" width="1000px">
      <v-card>
        <v-card-title> </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
              <h2>Редактирование</h2>
            </v-col>
            <v-col cols="12" md="12" class="infoPerson">
              <v-text-field
                v-model="data.name"
                :rules="nameRules"
                label="Название по Английски"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" class="infoPerson">
              <v-text-field
                v-model="data.abbr"
                :rules="nameRules"
                label="Абревиатура"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" class="infoPerson">
              <v-text-field
                v-model="data.nameRus"
                :rules="nameRules"
                label="Название по Русски"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" class="infoPerson">
              <v-autocomplete
                v-model="data.timeZone"
                :items="timeZone"
                outlined
                dense
                chips
                small-chips
                label="Выбор Часового пояса"
                multiple
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="12" class="infoPerson">
              <v-text-field
                v-model="data.order"
                label="Порядок в выдаче"
                type="number"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" text @click="onClose"> Отменить </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveData"> Сохранить </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row align="center" justify="space-around" style="margin-bottom: 10px">
      <v-btn color="primary" text @click="onNewData"> Добавить </v-btn>
    </v-row>
    <div style="margin-top: 10px">
      <v-card>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Поиск"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="desserts"
          :items-per-page="10"
          :search="search"
          class="elevation-1"
          @click:row="readData"
        ></v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    search: "",
    nameRules: [
      (v) => !!v || "Название не может быть пустым",
      (v) => (v && v.length >= 1) || "Name must be less than 10 characters",
    ],
    data: {
      id: 0,
      name: "",
      abbr: "",
      nameRus: "",
      timeZone: [],
      order: 0,
    },
    headers: [
      { text: "id", value: "id" },
      { text: "Английское название", value: "location" },
      { text: "Аббрев. на англ", value: "abbr" },
      { text: "Русское название", value: "nameCountryRus" },
      { text: "Часовые пояса", value: "TZ" },
      { text: "Порядок", value: "order_country" },
    ],
  }),
  computed: {
    desserts() {
      return this.$store.getters.getListLocationEdit;
    },
    timeZone() {
      return this.$store.getters.getTimeZone;
    },
  },
  mounted() {
    this.$store.dispatch("getLocationList");
    this.$store.dispatch("getTimeZone");
  },
  name: "country",
  methods: {
    readData(e) {
      this.data.id = e.id;
      this.data.name = e.location;
      this.data.abbr = e.abbr;
      this.data.nameRus = e.nameCountryRus;
      this.data.order = e.order_country
      if(e.TZ != null) {
        this.data.timeZone = e.TZ.split(',');
      } else {
        this.data.timeZone = [];
      }
      
      this.dialog = true;
    },
    saveData() {
      this.$store.dispatch("saveCountry", this.data);
      this.dialog = false;
    },
    onClose() {
      this.dialog = false;
    },
    onNewData() {
      this.data.id = 0;
      this.data.name = "";
      this.data.abbr = "";
      this.data.nameRus = "";
      this.data.timeZone = [];
      this.dialog = true;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.infoPerson {
  text-align: left;
}
</style>
