<template>
    <v-container>
        <v-row>
           
            <v-col cols="12" class="mb-4">
                <v-row>
                    <v-col cols="12">
                        Календарь
                    </v-col>
                    <v-col cols="12">
                        <v-sheet tile height="54" class="d-flex">
                            <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
                                <v-icon>mdi-chevron-left</v-icon>
                            </v-btn>
                            <v-select v-model="type" :items="types" dense outlined hide-details class="ma-2"
                                label="Диапазон"></v-select>
                            <v-select v-model="mode" :items="modes" dense outlined hide-details 
                                label="Тип календаря" class="ma-2"></v-select>
                            <v-select v-model="person" :items="weekdays" dense outlined hide-details label="weekdays"
                                class="ma-2"></v-select>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" href="https://www.consultant.ru/law/ref/calendar/proizvodstvennye/2024/" target="_blank">Производственный календарь</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn icon class="ma-2" @click="$refs.calendar.next()">
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-btn>
                        </v-sheet>
                        <v-sheet height="600">
                            <v-calendar ref="calendar" v-model="value" :weekdays="weekday" :type="type" :events="events"
                                :event-overlap-threshold="30" :event-color="getEventColor"
                                @change="getEvents"></v-calendar>
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data: () => ({
        type: 'month',
        types: 
        [
            { text: 'Месяц', value: 'month' },
            { text: 'Неделя', value: 'week' },
            { text: 'День', value: 'day' },
            { text: '4 дня', value: '4day' },
        ],
        mode: 'Общий',
        modes: ['Общий', 'Личный', 'Коллеги'],
        person: 'Все',
        weekdays: ['Все', 'Баранов Дмитрий Сергеевич', 'Алла Семенникова','Санита Пшеничникова','Асель Бекова','Ксения Черенда','Екатерина Стулова','Александр Малышев','Екатерина','Ольга Герасимова','Михаил Покровский','Полина Коровинская'],
        weekday: [1, 2, 3, 4, 5, 6, 0],
        value: '',
        events: [],
        colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
        names: ['Созвон', 'созвон с клиентом', 'Платформа', 'Консультирование', 'Наказание от Ксюши', 'День рождение', 'Общий созвон', 'Вечеринка'],

       
    }),
    name: "account",
    methods: {
        validate() {
            this.$refs.form.validate();
            let t = {
                eml: this.email,
                ps: this.pass,
                ps1: this.passNext,
                fio: this.fio,
            }
            this.$store.dispatch("setDataAccUser", t).then((res) => {
                this.email = res;
            });
        },
        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
        getEvents({ start, end }) {
            const events = []

            const min = new Date(`${start.date}T00:00:00`)
            const max = new Date(`${end.date}T23:59:59`)
            const days = (max.getTime() - min.getTime()) / 86400000
            const eventCount = this.rnd(days, days + 20)

            for (let i = 0; i < eventCount; i++) {
                const allDay = this.rnd(0, 3) === 0
                const firstTimestamp = this.rnd(min.getTime(), max.getTime())
                const first = new Date(firstTimestamp - (firstTimestamp % 900000))
                const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
                const second = new Date(first.getTime() + secondTimestamp)

                events.push({
                    name: this.names[this.rnd(0, this.names.length - 1)],
                    start: first,
                    end: second,
                    color: this.colors[this.rnd(0, this.colors.length - 1)],
                    timed: !allDay,
                })
            }

            this.events = events
        },
        getEventColor(event) {
            return event.color
        },
        rnd(a, b) {
            return Math.floor((b - a + 1) * Math.random()) + a
        },
    },
    mounted() {
        this.$store.dispatch("getMailUser").then((res) => {
            this.email = res.eml;
            this.fio = res.fio
        });
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>