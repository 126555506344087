<template>
    <div style="margin-right: 10px">
        <v-dialog v-model="dialog" width="1000px">
            <v-card>
                <v-card-title> </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="12">
                            Проект: {{ zakaz.proj }}
                            
                        </v-col>
                        <v-col cols="12" md="12">
                            Специалист: {{ zakaz.spec }}
                            
                        </v-col>
                        <v-col cols="12" md="12">{{ comp.officeName }}</v-col>
                        <v-col cols="12" md="12" style="border: solid 1px #FFE816;">
                            Комментарий от менеджера:
                            {{ zakaz.info }}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="12">
                            <h2>Выберите удобное время для интервью</h2>
                            <v-radio-group v-model="radioGroup">
                                <v-radio v-for="(n, i) in zakaz.slots"  @click="otk=false" :key="i" :label="n.dt" :value="n.dt"></v-radio>
                                <v-radio label="отказаться" @click="otk=true"></v-radio>
                            </v-radio-group>
                            <v-text-field label="Укажите причину отказа" v-if="otk"></v-text-field>
                        </v-col>
                    </v-row>

                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn color="#D9D9D9" @click="dialog = false"> Отменить </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="#FFE816" @click="dialog = false"> Сохранить </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-row align="center" justify="space-around" style="margin-bottom: 10px">
            <v-col cols="12" md="6">Запросы на интервью</v-col>
            <v-col cols="12" md="6">

            </v-col>

        </v-row>
        
        <div style="margin-top: 10px">
            <v-card>
                <v-card-title>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Поиск" single-line
                        hide-details></v-text-field>
                </v-card-title>
                <v-data-table :headers="headers" :items="listInterview" :items-per-page="10" :search="search"
                    class="elevation-1" @click:row="readData">
                    <template v-slot:item.result="{ item }">
                        <span>{{ ((item.result == -1) ? 'Ожидает ответа' : (item.result == 0 ? 'отказ' : 'успех')) }}</span>
                    </template>
                </v-data-table>
            </v-card>
        </div>
    </div>
</template>
    
<script>


export default {
    data: () => ({
        dialog: false,
        search: "",
        otk: false,
        zakaz: {
            id: 0,
            spec: "",
            proj: "",
            info: "",
            slots: [
                {
                    dt: '',
                    char: '+'
                }
            ],

        },
        headers: [
            { text: "id", value: "id" },
            { text: "Проект", value: "name_project" },
            { text: "имя спеца", value: "firstname" },
            { text: "результат", value: "result" },
        ],
        comp: "",
    }),
    components: {
    },
    computed: {
        spec() {
            return this.$store.getters.listSpecIntr;
        },
        proj() {
            return this.$store.getters.listCompanyIntr;
        },
        listInterview() {
            return this.$store.getters.listInterview;
        }
    },
    mounted() {
        this.$store.dispatch("getListInfoCompanySpecAdm");
        this.$store.dispatch("getListInterviewCompany");
    },
    name: "RulesEdit",
    methods: {
        addNewSlot(t) {
            console.log(this.zakaz.slots);
            if (this.zakaz.slots[t].char == '+') {
                this.zakaz.slots[t].char = '-'
                let r = {
                    dt: '',
                    char: '+'
                }
                this.zakaz.slots.push(r);
            } else {
                this.zakaz.slots.splice(t, 1);
            }

        },
        onFindFirm() {
            this.$store.dispatch("getCompanyToSpecAdm", this.zakaz.spec).then(res => {
                this.comp = res;
            })
        },
        saveData() {
            if (this.zakaz.spec != '' && this.zakaz.slots[0].dt != '') {
                this.$store.dispatch('saveInterviewAdm', this.zakaz);
                this.dialog = false;

            }
        },
        readData(e) {
            console.log(e);
            this.zakaz.slots = [

            ];
            this.zakaz.id = e.id;
            this.zakaz.spec = e.fff;
            this.zakaz.proj = e.name_project;
            this.zakaz.info = e.info;
            let r = e.slots.split(",");
            console.log(r);
            for (let index = 0; index < r.length; index++) {
                if ((r.length - 1) == index) {
                    this.zakaz.slots.push({
                        dt: r[index],
                        char: '-'
                    })
                } else {
                    this.zakaz.slots.push({
                        dt: r[index],
                        char: '+'
                    })
                }


            }
            this.dialog = true;
        },
    }
}
</script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.infoPerson {
    text-align: left;
}
</style>
    