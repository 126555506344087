<template>
  <div style="margin-right: 10px">
    <v-dialog v-model="dialog" width="1000px">
      <v-card>
        <v-card-title> </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
              <h2>Редактирование</h2>
            </v-col>
            <v-col cols="12" md="12" class="infoPerson">
              <v-text-field
                v-model="skill.name"
                label="Название"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
                <vue-editor v-model="skill.text"></vue-editor>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" text @click="onClose"> Отменить </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveSkill"> Сохранить </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row align="center" justify="space-around" style="margin-bottom: 10px">
        правила
    </v-row>
    <div style="margin-top: 10px">
      <v-card>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Поиск"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="desserts"
          :items-per-page="10"
          :search="search"
          class="elevation-1"
          @click:row="readSkilld"
        ></v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  data: () => ({
    dialog: false,
    search: "",
    skill: {
      id: 0,
      name: "",
      text: "",
    },
    headers: [
      { text: "id", value: "id" },
      { text: "Название", value: "title" },
      { text: "текст", value: "discription" },
    ],
    
  }),
  components: {
    VueEditor
  },
  computed: {
    desserts() {
        let m = [];
        m.push(this.$store.getters.getRulesPerson);
        m.push(this.$store.getters.getRulesSite);
        return m;
    },
  },
  mounted() {
    this.$store.dispatch("getDataRulesSite");
    this.$store.dispatch("getDataRulesPerson");
  },
  name: "RulesEdit",
  methods: {
    readSkilld(e) {
      this.skill.id = e.id;
      this.skill.name = e.title;
      this.skill.text = e.discription;
      this.dialog = true;
    },
    saveSkill() {
      this.$store.dispatch("saveRules", this.skill);
      this.dialog = false;
    },
    onClose() {
      this.dialog = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.infoPerson {
  text-align: left;
}
</style>
