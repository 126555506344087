<template>
  <div style="margin-right: 10px">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Рассылка</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  :items="requestClient"
                  v-model="mess.req"
                  label="Запросы"></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-textarea name="input-7-1" label="Текст сообщения" hint="Введите текс сообщения"
                    v-model="mess.ms"></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Отменить
          </v-btn>
          <v-btn color="blue darken-1" text @click="telegramMess">
            Разослать
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row align="center" justify="space-around" style="margin-bottom: 10px">
      <v-btn depressed color="#FFE816" @click="newSpec"> ДОБАВИТЬ СПЕЦИАЛИСТА + </v-btn>
    </v-row>

    <v-card ref="findPanel">
      <v-container fluid>
        <v-row align="center">
          <v-col cols="6" md="3" sm="6" xs="6">
            <v-autocomplete v-model="find.country" :items="listLocation" deletable-chips outlined hide-details="auto"
              dense chips small-chips label="Локация" multiple @change="onFind"></v-autocomplete>
          </v-col>
          <v-col cols="6" md="3" sm="6" xs="6">
            <v-autocomplete v-model="find.lang" :items="langLevel" deletable-chips outlined hide-details="auto" dense
              chips small-chips label="Уровень английского не ниже" multiple @change="onFind"></v-autocomplete>
          </v-col>
          <v-col cols="6" md="3" sm="6" xs="6">
            <v-autocomplete v-model="find.level" :items="listLevel" deletable-chips outlined hide-details="auto" dense
              chips small-chips label="Уровень" multiple @change="onFind"></v-autocomplete>
          </v-col>
          <v-col cols="6" md="3" sm="6" xs="6">
            <v-autocomplete v-model="find.role" :items="categories" deletable-chips outlined hide-details="auto" dense
              chips small-chips label="Роль" multiple @change="onFind"></v-autocomplete>
          </v-col>
          <v-col cols="6" md="3" sm="6" xs="6">
            <v-text-field v-model="find.rate" label="Ставка до" outlined hide-details="auto" dense chips
              @change="onFind"></v-text-field>
          </v-col>
          <v-col cols="6" md="3" sm="6" xs="6">
            <v-text-field label="Закуп до" v-model="find.zakyp" outlined hide-details="auto" dense chips
              @change="onFind"></v-text-field>
          </v-col>
          <v-col cols="6" md="3" sm="6" xs="6">
            <v-select :items="['доллар', 'рубли']" label="тип валюты" v-model="find.valute" outlined hide-details="auto"
              dense chips @change="onFind"></v-select>
          </v-col>

          <v-col cols="6" md="3" sm="6" xs="6">
            <v-text-field v-model="find.pub" label="Коммерческий опыт (лет), от" outlined type="number"
              hide-details="auto" dense chips @change="onFind"></v-text-field>
          </v-col>
          <v-col cols="6" md="2" sm="6" xs="6">

            <v-autocomplete v-model="find.skills" :items="listSkills" deletable-chips outlined hide-details="auto" dense
              chips small-chips label="скилл" multiple @change="onFind"></v-autocomplete>

          </v-col>
          <v-col cols="6" md="10" sm="6" xs="6">
            <v-text-field v-model="find.skills1" label="Поиск по запросу" outlined hide-details="auto" dense chips
              @change="onFind"></v-text-field>

          </v-col>
          <v-col cols="6" md="2" sm="6" xs="6">
            <v-btn elevation="2" style="width: 100%" @click="onClear">
              Очистить все
            </v-btn>
          </v-col>
          <v-col cols="6" md="2" sm="6" xs="6">
            <v-btn elevation="2" style="width: 100%" v-if="sel" @click="dialog = true">
              Рассылка
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <loader v-if="load" object="#ff9633" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40"
      objectbg="#999793" opacity="80" name="dots"></loader>

    <div style="margin-top: 10px">
      <v-card>
        <v-card-title>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Поиск" single-line
            hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="desserts" :items-per-page="10" :search="search" class="elevation-1"
          v-model="selected" :single-select="singleSelect" show-select @click:row="onCLickSelect">
          <!-- <template v-slot:item.Main_skills="{ item }">
            {{ item.Main_skills.split(",").splice(0, 4).join(",") + ' ...' }}
          </template> -->
          <template v-slot:item.nameLevel="{ item }">
            {{ item.nameLevel }} ({{ item.Years_of_Experience }})
          </template>
          <template v-slot:item.zakyp_rub="{ item }">
            З: {{ item.zakyp_rub }} <br />С: {{ item.Rate_RUB }}
          </template>
          <template v-slot:item.Status="{ item }">
            <v-select :items="items_status" label="статус" v-model="item.Status"
              :style="item.Status == 'Свободен' ? 'background:#FFE816' : ''" outlined hide-details="auto" dense chips
              @change="newStatus(item.id, item.Status)"></v-select>
          </template>
          <template v-slot:item.dts="{ item }">
            Создание: {{ item.dts }} <br />Обновление: {{ item.dtu }}
          </template>
          <template v-slot:item.linkCV="{ item }">
            <!-- <v-btn
            elevation="2"
            small
            @click="openReadPerson(item.id)"
            target="_blank"
            style="margin-bottom: 5px;"
            title="редактировать"
          >
            <v-icon>
              mdi-pencil
            </v-icon>          
          </v-btn> -->
            <v-btn elevation="2" small :href="'/cv/'+item.keyId" target="_blank" style="margin-bottom: 5px;"
              title="Посмотреть">
              <v-icon>
                mdi-eye
              </v-icon>
            </v-btn>

            <!-- <v-btn
            elevation="2"
            small
            :href = "'https://t.me/bhireinterview_bot?start=2_'+item.id"
            target="_blank"
            style="margin-bottom: 5px;"
            title="Запросить доступность"
          >
          
            <v-icon>
              mdi-lock-open
            </v-icon>
          </v-btn> -->

          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({

    dialog: false,
    search: "",
    search_exp: "",
    sel: false,
    uploadFoto: false,
    date: "",
    files: "",
    mess: {
      req: '',
      ms: 'Ребята! Доступен ли специалист <Спец> <роль> для прохождения интервью? <CV> \r\n Если не доступен, возможно есть альтернатива?',
    },
    project: {
      dts: "",
      dte: "",
      role: "",
      ind: "",
      descr: "",
      kol: "",
      skill: [],
      poln: "",
      read: -1,
    },
    find: {
      type: 'adm',
      country: [],
      location: [],
      lang: [],
      level: [],
      role: [],
      rate: [],
      valute: 'рубли',
      zakyp: [],
      skills: [],
      skills1: [],
      pub: [],
    },
    spec: {
      id: 0,
      foto: "",
      about: "",
      fio: "",
      cat: "",
      role: "",
      level: "",
      grag: "",
      zone: "",
      firm: "",
      exp: "",
      rater: "",
      lang: [
        {
          name: "Английский",
          level: "a1 - beginner",
        },
      ],
      educ: [],
      skills: [],
      exp_proj: [],
    },
    tab: null,
    items2: ["О специалисте", "Навыки", "Опыт", "Образование"],
    items_status: ["Свободен", "нужно актуализировать", "Занят"],
    menu: false,
    menu2: false,
    menu_edu_dtn: false,
    menu_edu_dte: false,
    nameRules: [
      (v) => !!v || "Название не может быть пустым",
      (v) => (v && v.length >= 1) || "Name must be less than 10 characters",
    ],
    education: {
      title: "",
      dtn: "",
      dte: "",
      level: "",
      spec: "",
      read: -1,
    },
    data: {
      id: 0,
      name: "",
      abbr: "",
      nameRus: "",
    },
    er: ["q", "s", "f"],
    singleSelect: false,
    selected: [],
    headers: [

      { text: "id", value: "id" },
      {
        text: "Имя",
        value: "FIO",
      },
      { text: "Фирма", value: "nameFirm" },
      { text: "Локация", value: "Country" },
      { text: "Английский", value: "English" },
      { text: "Уровень (Комерческий опыт)", value: "nameLevel" },
      { text: "Роль", value: "Role" },
      { text: "Закуп RUB/Ставка ₽", value: "zakyp_rub" },
      // { text: "Ставка ₽", value: "Rate_RUB" },
      { text: "Навыки", value: "Main_skills" },
      // { text: "Комерческий опыт", value: "Years_of_Experience" },
      //{ text: "Процент совпадения", value: "procent" },
      // { text: "Процент совпадения", value: "id_tg" },
      { text: "Статус", value: "Status" },
      { text: "Даты", value: "dts" },

      { text: "CV", sortable: false, value: "linkCV" },
    ],
    headers_exp: [
      { text: "Роль", value: "role" },
      { text: "Начало", value: "dts" },
      { text: "Конец", value: "dts" },
      { text: "Индустрия", value: "ind" },
      { text: "кол-во чел", value: "kol" },
    ],
    headers_edгс: [
      {
        text: "Название",
        value: "title",
      },
      { text: "Дата начала", value: "dtn" },
      { text: "Дата окончания", value: "dte" },
      { text: "уровень", value: "level" },
      { text: "специальность", value: "spec" },
    ],
    desserts_exp: [],
  }),
  computed: {
    desserts() {
      return this.$store.getters.getListPersonSpec;
      //return this.$store.getters.getMainSpecialist;
    },
    listSkills() {
      return this.$store.getters.getListSkills;
    },
    dataCVPerson() {
      return this.$store.getters.getPersonCV;
    },
    expList() {
      return this.$store.getters.getListExp;
    },
    listLocation() {
      return this.$store.getters.getListLocationAll;
    },
    langLevel() {
      return this.$store.getters.getListLangLevel;
    },
    langName() {
      return this.$store.getters.getListLang;
    },
    listRole() {
      return this.$store.getters.getListRole;
    },
    levelSpec() {
      return this.$store.getters.getListLevel;
    },
    roleSpec() {
      return this.$store.getters.getListRole;
    },
    timeZone() {
      return this.$store.getters.getTimeZone;
    },
    skills() {
      return this.$store.getters.getListSkills;
    },
    categories() {
      return this.$store.getters.getListCategories;
    },
    url() {
      return this.$store.getters.getBaseUrl;
    },
    company() {
      return this.$store.getters.getListCompany;
    },
    listLevel() {
      return this.$store.getters.getListLevel;
    },
    industries() {
      return this.$store.getters.getListIndustries;
    },
    levelEduc() {
      return this.$store.getters.getListLevelEducation;
    },
    educationList() {
      return this.$store.getters.getListEducation;
    },
    load() {
      return this.$store.getters.getOnLoad;
    },
    CountryToTimeZone() {
      return this.$store.getters.getOnListContryTimeZone;
    },
    requestClient(){
      return this.$store.getters.getListRequestCLient;
    },
  },
  mounted() {
    this.$store.dispatch("getListPersonSpecAction");
    //this.$store.dispatch("getSpecialistList");
    this.$store.dispatch("getLocationListActive");
    this.$store.dispatch("getSkillsList");
    this.$store.dispatch("getLangLevelList");
    this.$store.dispatch("getLangList");
    this.$store.dispatch("getLevelList");
    this.$store.dispatch("getRoleList");
    this.$store.dispatch("getTimeZone");
    this.$store.dispatch("getCategories");
    this.$store.dispatch("getCompanyList");
    this.$store.dispatch("getIndustriesList");
    this.$store.dispatch("getLevelEducationList");
    this.$store.dispatch("getEducationList");
    this.$store.dispatch("getCountryToTimeZone");
    this.$store.dispatch("getRequestClient");
  },
  name: "skills",
  methods: {
    newSpec() {
      this.$store.dispatch("ClearPersonCV")
      this.$router.push("/person");
    },
    onCLickSelect() {
      console.log(this.selected);
      if (this.selected.length > 0) {
        this.sel = true;
      }
    },
    newStatus(id, st) {
      console.log(id)
      console.log(st)
      let t = {
        id: id,
        status: st,
      }
      this.$store.dispatch("setNewStatusAdmin", t)
    },
    readData(e) {
      this.$store.dispatch("getPersonCV", e.id).then(() => {
        this.spec.id = e.id;
        this.spec.grag = this.dataCVPerson.nameCountry;
        this.spec.fio = this.dataCVPerson.FIO;
        this.spec.level = this.dataCVPerson.nameLevel;
        this.spec.rater = this.dataCVPerson.Rate_RUB;
        this.spec.cat = this.dataCVPerson.nameRole;
        this.spec.firm = this.dataCVPerson.nameFirm;
        this.spec.exp = this.dataCVPerson.Years_of_Experience;
        this.spec.role = this.dataCVPerson.nameCategories;
        this.spec.zone = this.dataCVPerson.nameTimeZone;
        this.spec.about = this.dataCVPerson.about;
        this.spec.educ = this.dataCVPerson.edu;
        this.spec.exp_proj = this.dataCVPerson.exp;
        this.spec.skills = this.dataCVPerson.skills;
        this.spec.lang = this.dataCVPerson.lang;
        this.dialog = true;
      });
    },
    openReadPerson(t) {
      this.$store.dispatch("getSpecInfoId", t);
      this.$router.push('/person')
    },
    openCVPerson(t) {
      console.log(t);
      this.$store.dispatch("getSpecInfoId", t);
      this.$router.push('/cv/' + t)
    },
    readEducation(e, r) {
      console.log(r);
      this.education.title = e.title;
      this.education.level = e.level;
      this.education.spec = e.spec;
      this.education.dtn = e.dtn;
      this.education.dte = e.dte;
      this.education.read = r.index;
    },
    readDataProj(e, r) {
      this.project.title = e.title;
      this.project.dts = e.dts;
      this.project.dte = e.dte;
      this.project.role = e.role;
      this.project.ind = e.ind;
      this.project.descr = e.descr;
      this.project.kol = e.kol;
      this.project.skill = e.skill;
      this.project.poln = e.poln;
      this.project.read = r.index;
    },

    telegramMess(){
      let t = [];
      for (let index = 0; index < this.selected.length; index++) {
        t.push({
          zp: this.mess.req,
          ms: this.mess.ms,
          id: this.selected[index].id,
          fio: this.selected[index].FIO,
          rol: this.selected[index].Category,
          id_tg: this.selected[index].id_tg,
          cv: this.selected[index].linkCV,
        })
        
      }
      console.log(t);
      this.$store.dispatch("TelegramSendMS", t);
    },
    DelDataEdu() {
      let n = confirm("Вы уверены что хотите удалить?");
      if (n == true) {
        this.spec.educ.splice(this.education.read, 1);
        this.education.title = "";
        this.education.dtn = "";
        this.education.dte = "";
        this.education.level = "";
        this.education.spec = "";
        this.education.read = -1;
      }
    },
    delDataExp() {
      let n = confirm("Вы уверены что хотите удалить?");
      if (n == true) {
        this.spec.exp_proj.splice(this.education.read, 1);
        this.project.title = "";
        this.project.dts = "";
        this.project.dte = "";
        this.project.role = "";
        this.project.ind = "";
        this.project.descr = "";
        this.project.kol = "";
        this.project.skill = [];
        this.project.poln = "";
        this.project.read = -1;
      }
    },
    saveData() {
      this.spec.foto = this.url;
      this.$store.dispatch("saveAdmSepc", this.spec);
      this.dialog = false;
    },
    onClose() {
      this.dialog = false;
    },
    onNewData() {
      this.spec.id = 0;
      this.spec.grag = "";
      this.spec.fio = "";
      this.spec.level = "";
      this.spec.rater = '';
      this.spec.cat = '';
      this.spec.firm = '';
      this.spec.exp = '';
      this.spec.role = '';
      this.spec.zone = '';
      this.spec.about = '';
      this.spec.educ = [];
      this.spec.exp_proj = [];
      this.spec.skills = [];
      this.spec.lang = [
        {
          name: "Английский",
          level: "a1 - beginner",
        },
      ];
      this.dialog = true;
    },
    saveDataExp() {
      // this.$store.dispatch("AddListExp", this.project);
      if (this.project.read == -1) {
        this.spec.exp_proj.push(JSON.parse(JSON.stringify(this.project)));
      } else {
        this.spec.exp_proj[this.project.read].title = this.project.title;
        this.spec.exp_proj[this.project.read].dts = this.project.dts;
        this.spec.exp_proj[this.project.read].dte = this.project.dte;
        this.spec.exp_proj[this.project.read].role = this.project.role;
        this.spec.exp_proj[this.project.read].ind = this.project.ind;
        this.spec.exp_proj[this.project.read].descr = this.project.descr;
        this.spec.exp_proj[this.project.read].kol = this.project.kol;
        this.spec.exp_proj[this.project.read].skill = this.project.skill;
        this.spec.exp_proj[this.project.read].poln = this.project.poln;
      }
      this.project.title = "";
      this.project.dts = "";
      this.project.dte = "";
      this.project.role = "";
      this.project.ind = "";
      this.project.descr = "";
      this.project.kol = "";
      this.project.skill = [];
      this.project.poln = "";
      this.project.read = -1;
    },
    newLangPers() {
      this.spec.lang.push({ name: "Английский", level: "a1 - beginner" });
    },
    delLangPers(t) {
      this.spec.lang.splice(t, 1);
      console.log(this.spec.lang);
    },
    setFoto() {
      if (this.files != null) {
        this.uploadFoto = true;
        this.$store.dispatch("setFotoSpec", this.files);
      } else {
        this.uploadFoto = false;
      }
    },
    saveDataEdu() {
      if (
        this.education.title == "" ||
        this.education.dtn == "" ||
        this.education.dte == "" ||
        this.education.level == "" ||
        this.education.spec == ""
      ) {
        alert("Не заполнены все поля!");
      } else {
        console.log();
        if (this.education.read == -1) {
          this.spec.educ.push(JSON.parse(JSON.stringify(this.education)));
        } else {
          this.spec.educ[this.education.read].title = this.education.title;
          this.spec.educ[this.education.read].dtn = this.education.dtn;
          this.spec.educ[this.education.read].dte = this.education.dte;
          this.spec.educ[this.education.read].level = this.education.level;
          this.spec.educ[this.education.read].spec = this.education.spec;
          this.spec.educ[this.education.read].read = this.education.read;
        }
        this.education.title = "";
        this.education.dtn = "";
        this.education.dte = "";
        this.education.level = "";
        this.education.spec = "";
        this.education.read = -1;
      }
    },
    savedateS(t) {
      this.education.dtn = t;
      this.menu_edu_dtn = false;
    },
    savedateE(t) {
      this.education.dte = t;
      this.menu_edu_dte = false;
    },
    savedateSProj(t) {
      this.project.dts = t;
      this.menu = false;
    },
    savedateEProj(t) {
      this.project.dte = t;
      this.menu2 = false;
    },
    DateNow(t) {
      if (t == 1) {
        this.project.dte = "По настоящее время";
        this.menu2 = false;
      } else {
        this.education.dte = "По настоящее время";
        this.menu_edu_dte = false;
      }
    },
    onClear() {
      this.find.country = "";
      this.find.location = "";
      this.find.lang = "";
      this.find.level = "";
      this.find.role = "";
      this.find.rate = "";
      this.find.skills = "";
      this.find.pub = "";
      this.$store.dispatch("getListPersonSpecActionInf");
    },
    onFind() {
      console.log(this.find);
      this.heightSize = this.$refs.findPanel.$refs.link.offsetHeight + 10;
      this.$store.dispatch("onSeachSpecial", this.find);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.infoPerson {
  text-align: left;
}

body {
  background-color: #d2d2d2;
}
</style>
