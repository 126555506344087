<template>
    <div style="margin: 10px">
        <v-row>
            <v-col cols="12" md="4">
                ID Проекта
                {{project.id}}
            </v-col>
            <v-col cols="12" md="4">
                <v-text-field
                    label="Ссылка на проект"
                    v-model ="project.link"
                    required
                ></v-text-field>
            </v-col>            
            <v-col cols="12" md="4">
                <v-autocomplete
                        v-model ="project.customer"
                        :items="customer"                        
                        dense
                        chips
                        label="Заказчик"
                ></v-autocomplete>                
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="4">
                <v-select
                :items="levelSpec"
                v-model ="project.level"
                label="Уровень(грейд)*"
                multiple
                ></v-select>
            </v-col>
            <v-col cols="12" md="4">
                <v-autocomplete
                :items="categories"
                v-model ="project.role"
                label="Роль *"
                ></v-autocomplete>
            </v-col>            
            <v-col cols="12" md="4">
                <v-autocomplete
                        v-model ="project.location"
                        :items="country"                        
                        dense
                        chips
                        label="Локация"
                ></v-autocomplete>          
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="8">
                <v-textarea
                label="О проекте"
                v-model ="project.about"
                required
                placeholder=""
                ></v-textarea>
            </v-col>
            <v-col cols="12" md="4">
                <v-btn>Посмотреть пример</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="8">
                <v-textarea
                label="О требования"
                v-model ="project.demand"
                required
                placeholder=""
                ></v-textarea>
            </v-col>
            <v-col cols="12" md="4">
                <v-btn>Посмотреть пример</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="4">
                <v-text-field
                :counter="10"
                v-model ="project.reit"
                label="Рейт"
                type = "number"
                required
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
                <v-select
                :items="valute"
                v-model ="project.valute"
                label="Валюта"
                ></v-select>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="12">
                <v-select
                :items="status"
                v-model ="project.status"
                label="Статус проекта"
                ></v-select>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="12">
               <v-switch
                label="Разместить в телеграм"
                ></v-switch>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="4">
                <v-btn to="/admin/project">Отменить</v-btn>
            </v-col>
            <v-col cols="12" md="4">
                <v-btn @click="setProject">Сохранить</v-btn>
            </v-col>
            <v-col cols="12" md="4">
                <v-btn>Удалить</v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
  data: () => ({
    project: {
        id: 0,
        link: "",
        customer: "",
        level: [],
        role: "",
        location: "",
        about: "",
        demand: "",
        reit: 0,
        valute: "RUB",
        status: "",
    }
  }),
  computed: {
    levelSpec() {
      return this.$store.getters.getListLevel;
    },
    categories() {
      return this.$store.getters.getListCategories;
    },
    country() {
      return this.$store.getters.getListLocation;
    },
    customer() {
        return this.$store.getters.getCustomer;
    },
    valute() {
        return this.$store.getters.getValute;
    },
    status(){
        return this.$store.getters.getStatusProject;
    }



  },
  name: "AddProjectAdmin",
  mounted() {
    this.$store.dispatch("getLocationList");
    this.$store.dispatch("getCategories");
    this.$store.dispatch("getLevelList");
    this.$store.dispatch("getValuteList");
    this.$store.dispatch("getStatusProjectList");
    this.$store.dispatch("getCustomerList");
    let w = this.$store.getters.getProjectInfo;
    this.project.id = w.id;
    this.project.link = w.link;
    this.project.customer = w.customer;
    this.project.level = w.level;
    this.project.role = w.role;
    this.project.location = w.location;
    this.project.about = w.about;
    this.project.demand = w.demand;
    this.project.reit = w.reit;
    this.project.valute = w.valute;
    this.project.status = w.status;
  },
  methods: {
    setProject(){
        this.$store.dispatch("setProject", this.project).then(res => {
            if(res) {
                //alert('не удалось сохранить проект!');
               this.$router.push('/admin/project') 
            } else {
                alert('не удалось сохранить проект!');
            }
        });
    }
  },
};
</script>

<style scoped>

</style>