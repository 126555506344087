<template>
<v-container>
    <v-row>
        <v-col cols="12">Настройка оповещений</v-col>
    </v-row>
    <v-row>
        <v-col cols="12" class="mb-12">
            <v-card>
                <v-card-title>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
                </v-card-title>
                <v-data-table :headers="headers" :search="search" :items="desserts" :items-per-page="20" class="elevation-1">
                    <template v-slot:item.fat="{ item }">
                        <v-btn v-if="item.mess==0" @click="saveSS(item.id)">рассылка</v-btn>
                        <v-btn v-else @click="saveSS(item.id)" color="error">отключена</v-btn>
                    </template>
                    <template v-slot:item.bl="{ item }">
                        <v-btn v-if="item.block==0" @click="saveDD(item.id)">В работе</v-btn>
                        <v-btn v-else @click="saveDD(item.id)" color="warning">Блок</v-btn>
                    </template>
                </v-data-table>
            </v-card>
        </v-col>

    </v-row>
</v-container>
</template>

<script>
export default {
    data: () => ({
search: '',
        headers: [{
                text: 'Название чата',
                value: 'nameTg',
            },
            {
                text: 'id Tg',
                value: 'id_tg'
            },
            {
                text: 'действия',
                value: 'fat'
            },
            {
                text: 'блок',
                value: 'bl'
            },
        ],
    }),
    name: "sendSetting",
    mounted() {
        this.$store.dispatch("getListTgChats");
    },
    computed: {
        desserts() {
            return this.$store.getters.listTgCompanyChats;
        },
    },
    methods: {
        saveSS(t) {
            this.$store.dispatch("setListTgChatsId", t);
        },
        saveDD(t) {
            this.$store.dispatch("setListTgChatsIdBlock", t);
        }

    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped></style>
