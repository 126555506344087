<template>
  <v-container>
    <div v-if="vis">
      <v-row v-if="act">
        <v-col>
          <h2>Вы успешно потвердили почту можете теперь войти в кабинет</h2>
          <v-btn class="success" to="/">Войти</v-btn>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col>
          <h2>Не удалось потвердить почту</h2>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-row>
        <v-col>
          <h2>Активируем вашу учетную запись</h2>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    act: false,
    vis: false,
  }),
  name: "active",
  mounted() {
    this.$store.dispatch("ActivateNewUser", this.$route.params.id).then((res) => {
      console.log(res);
      this.act = res;
      this.vis = true;
    });
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
