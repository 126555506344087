<template>
  <div style="margin-right: 10px">
    <v-dialog v-model="dialog" width="1000px">
      <v-card>
        <v-card-title> </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
              <h2>транзакция</h2>
            </v-col>
            <v-col cols="12" md="12" class="infoPerson">
              <v-text-field v-model="dt.dt" type="date" label="Дата" required></v-text-field>
            </v-col>
            <v-col cols="12" md="12" class="infoPerson">
              <v-text-field v-model="dt.sm" label="сумма" required type="number"></v-text-field>
            </v-col>
            <v-col cols="12" md="12" class="infoPerson">
              <v-autocomplete v-model="dt.fn" :items="desserts1" dense chips label="Фонд"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="12" class="infoPerson">
              <v-text-field v-model="dt.info" label="Введите описание транзации" required></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" text @click="onClose"> Отменить </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="onSave"> Сохранить </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog1" width="1000px">
      <v-card>
        <v-card-title>История по транзации </v-card-title>
        <v-card-text>
          <v-row>
            <v-card>
       
        <v-data-table :headers="headers_history" :items="history" :items-per-page="10" :search="search" class="elevation-1"
          >
        </v-data-table>
      </v-card>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog1 = false"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row align="center" justify="space-around" style="margin-bottom: 10px">
      <v-div cols="12">ДДС</v-div>
      <v-div cols="12" md="4"><v-btn color="primary" text @click="onNewTransaction"> Добавить транзакцию </v-btn></v-div>
    </v-row>
    <v-row style="margin: 10px">
      <v-col cols="12" md="2">
        <v-text-field type="date" label="дата начала" @input="onfind" required v-model="find.dts"></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field type="date" label="дата окончания" @input="onfind" required v-model="find.dtn"> </v-text-field>
      </v-col>
      <v-col cols="12" md="2" class="infoPerson">
              <v-autocomplete clearable :items="desserts1" @change="onfind"  v-model="find.fond" dense chips label="Фонд"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="2" class="infoPerson">
            Сумма всех транзакций: {{ summ }}  
            </v-col>
    </v-row>
    <div style="margin-top: 10px">
      <v-card>
        <v-card-title>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Поиск" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="desserts" :items-per-page="10" :search="search" class="elevation-1"
          >
          <template v-slot:item.akc="{ item }">
          <v-btn
            elevation="2"
            small
            @click="onRead(item.idt)"
            target="_blank"
            style="margin-bottom: 5px;"
            title="редактировать"
          >
            <v-icon>
              mdi-pencil
            </v-icon>     
          </v-btn>
           <v-btn
            elevation="2"
            small
            target="_blank"
            @click = "openHistory(item.idt)"
            style="margin-bottom: 5px;"
            title="Посмотреть"
          >
            <v-icon>
              mdi-eye
            </v-icon>
          </v-btn>
          <v-btn
            elevation="2"
            small
            target="_blank"
            @click = "deleteTransaction(item.idt)"
            style="margin-bottom: 5px;"
            title="Удалить"
          >
            <v-icon>
              mdi-delete
            </v-icon>
          </v-btn>
          
      </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>
  
<script>
export default {
  data: () => ({
    dialog: false,
    dialog1: false,
    search: "",
    nameRules: [
      (v) => !!v || "Название не может быть пустым",
      (v) => (v && v.length >= 1) || "Name must be less than 10 characters",
    ],
    dt: {
      id: 0,
      dt: '',
      sm: '',
      fn: 'Фонд ЗП',
      info: '',
    },
    find: {
      dts: '',
      dtn: '',
      fond: '',
    },
    headers: [
      { text: "id", value: "idt" },
      { text: "Пользователь", value: "fio" },
      { text: "email", value: "email" },
      { text: "Дата", value: "dtt" },
      { text: "Сумма", value: "summ" },
      { text: "Фонд", value: "name_fonds" },
      { text: "Описание", value: "info" },
      { text: "Дата создания", value: "dtr" },
      { text: "Дата изменения", value: "dtu" },
      { text: "", value: "akc" },
    ],
    headers_history: [
      { text: "id", value: "id" },
      { text: "Пользователь", value: "fio" },
      { text: "действие", value: "actionUser" },
      { text: "Дата", value: "date_cr" },
    ],
  }),
  computed: {
    desserts() {
      return this.$store.getters.ListTransaction;
    },
    desserts1() {
      return this.$store.getters.ListFond;
    },
    history() {
      return this.$store.getters.ListTransactionHistory;
    },
    summ() {
      return this.$store.getters.SummTransaction;
    }
  },
  mounted() {
    this.$store.dispatch("getTransactionFonds");
    this.$store.dispatch("getFondList");
  },
  name: "dds",
  methods: {
    onSave() {
      this.$store.dispatch("setTransactionFonds", this.dt);
      this.dialog = false;
    },
    onClose() {
      this.dialog = false;
    },
    onfind() {
      this.$store.dispatch("FindTransactionFonds", this.find);
    }, 
    onNewTransaction() {
      this.dt.id = 0;
      this.dt.dt = '';
      this.dt.sm = '';
      this.dt.fn = 'Фонд ЗП';
      this.dt.info = '';
      this.dialog = true;
    },
    openHistory(t){
      console.log(t);
      this.$store.dispatch("setTransactionFondsHistory", t);
      this.dialog1 = true;
    },
    deleteTransaction(t){
      let b= confirm('Вы уверены что хотите удалить?');
      if(b){
        this.$store.dispatch("DelTransactionFonds", t);
      }
      
    },
    onRead(t){
      let e = [];
      for (let index = 0; index < this.desserts.length; index++) {
        if(this.desserts[index].idt == t){
          e = this.desserts[index];
          break;
        }
        
      }
      this.dt.id = e.idt;
      this.dt.dt = e.date_tran;
      this.dt.sm = e.summ;
      this.dt.fn = e.name_fonds;
      this.dt.info = e.info;
      this.dialog = true;
    }
  },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.infoPerson {
  text-align: left;
}
</style>
  