<template>
  <v-app v-if="isAuthUser && $route.path!='/spec'">
  <v-navigation-drawer
          v-model="drawer"
          :clipped="$vuetify.breakpoint.lgAndUp"
          :disable-resize-watcher = "true"
          temporary
          fixed
      bottom
        
          left>
          <v-divider></v-divider>
          <!-- <p>ssss<br />ssss</p> -->
<v-divider></v-divider>
          <v-list dense nav>
            
            <v-list-item
              v-for="item in items"
              :key="item.title"
              link
              :to="item.to"
              :disabled = "item.dis"
            >
              <!-- <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon> -->

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!-- <v-list-item
              
              key="проекты"
              link
              target="blank"
              href="https://t.me/itrequests"
            > -->
              <!-- <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon> -->

              <!-- <v-list-item-content>
                <v-list-item-title>Проекты</v-list-item-title>
              </v-list-item-content> 
            </v-list-item>-->
          </v-list>
        </v-navigation-drawer>
    <v-app-bar app dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" :disable-resize-watcher = "true"></v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <v-btn href="/" text>
          <v-img
            alt="Vuetify Logo"
            class="shrink mr-2"
            contain
            src="./assets/brainics_logo_white.png"
            transition="scale-transition"
            width="140"
          />
        </v-btn>
      </div>

      <v-spacer></v-spacer>
      Пожелания по работе платформы пишите в телеграм аккаут <a style="margin-left: 10px" href="https://t.me/bhire_support"> @bhire_support</a> 
      <v-spacer></v-spacer>
      <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
      <v-badge
        :content="items_list.length"
        :value="items_list.length"
        color="red"
        overlap
      >
      
      <v-btn 
        text 
        class = "btn_notify" 
        style="min-width: 30px;"
        v-bind="attrs"
        v-on="on"
        >
        <v-icon>mdi-bell-ring</v-icon>
      </v-btn>
      </v-badge>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in items_list"
          :key="index"
        >
          <v-list-item-title>Актуализируйте информацию по специалисту {{ item.FIO }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
      <v-btn @click="onExit" text>
        <span class="mr-2">Выход</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
      
    </v-app-bar>
    <ul class="lightrope">
  <li></li>
  <li></li>
  <li></li>
  <li></li>
  <li></li>
  <li></li>
  <li></li>
  <li></li>
  <li></li>
  <li></li>
  <li></li>
  <li></li>
  <li></li>
  <li></li>
  <li></li>
  <li></li>
  <li></li>
  <li></li>
  <li></li>
  <li></li>
  <li></li>
  <li></li>
  <li></li>
  <li></li>
  <li></li>
  <li></li>
  <li></li>
  <li></li>
  <li></li>
  <li></li>
  <li></li>
  <li></li>
  <li></li>
  <li></li>
  <li></li>
  <li></li>
  <li></li>
  <li></li>
  <li></li>
  <li></li>
  <li></li>
  <li></li>
</ul>
    <v-row style="margin-top: 52px">

      <v-col cols="12">
        <v-main>
          <router-view />
        </v-main>
      </v-col>
    </v-row>
  </v-app>
  <v-app v-else>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    right: null,
    drawer: false,
    messages: 1,
    //hosts: "./assets/logo-four.png"
    hosts: "./assets/brainics_logo_white.png"
  }),
  computed: {
    isAuthUser() {
      return this.$store.getters.getAuthClient;
    },
    items() {
      return this.$store.getters.getListMenu;
    },
    items_list() {
      return this.$store.getters.getNotification
    },
  },
  components: {},
  methods: {
    onExit() {
      this.$store.dispatch("getExitClient");
      if(this.isAuthUser == false) {
         this.$router.push('/')
      }
    },
  },
  mounted(){
    this.$store.dispatch("getNotificationUser");
  }
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
.btn_notify {
  width: 30px; 
  height: 30px;
   
  min-height: 30px;
}


$globe-width:   12px;
$globe-height:  28px;
$globe-spacing: 40px;
$globe-spread: 3px;
$light-off-opacity: 0.4;

body {
  background: #000;
}
.lightrope {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  margin: 48px 0 0 0;
  padding: 0;
  pointer-events: none;
  width: 100%;
  li {
    position: relative;
    animation-fill-mode: both; 
    animation-iteration-count:infinite;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: $globe-width;
    height: $globe-height;
    border-radius: 50%;
    margin: $globe-spacing/2;
    display: inline-block;
    background: rgba(0,247,165,1);
    box-shadow: 0px $globe-height/6 $globe-width*2 $globe-spread rgba(0,247,165,1);
    animation-name: flash-1;
    animation-duration: 2s;
    &:nth-child(2n+1) {
      background: rgba(0,255,255,1);
      box-shadow: 0px $globe-height/6 $globe-width*2 $globe-spread rgba(0,255,255,0.5);
      animation-name: flash-2;
      animation-duration: 0.4s;
    }
    &:nth-child(4n+2) {
      background: rgba(247,0,148,1);
      box-shadow: 0px $globe-height/6 $globe-width*2 $globe-spread rgba(247,0,148,1);
      animation-name: flash-3;
      animation-duration: 1.1s;
    }
    &:nth-child(odd) {
      animation-duration: 1.8s;
    }
    &:nth-child(3n+1) {
      animation-duration: 1.4s;
    }
    &:before {
      content: "";
      position: absolute;
      background: #222;
      width: ($globe-width - 2);
      height: $globe-height/3;
      border-radius: 3px;
      top: (0 - ($globe-height/6));
      left: 1px;
    }
    &:after {
      content: "";
      top: (0 - $globe-height/2);
      left: $globe-width - 3;
      position: absolute;
      width: $globe-spacing + 12;
      height: ($globe-height/3 * 2);
      border-bottom: solid #222 2px;
      border-radius: 50%;
    }
    &:last-child:after {
      content: none;
    }
    &:first-child {
      margin-left: -$globe-spacing;
    }
  }
}
@keyframes flash-1 { 
    0%, 100% { background: rgba(0,247,165,1);
    box-shadow: 0px $globe-height/6 $globe-width*2 $globe-spread rgba(0,247,165,1);} 
    50% { background: rgba(0,247,165,$light-off-opacity);
    box-shadow: 0px $globe-height/6 $globe-width*2 $globe-spread rgba(0,247,165,0.2);}
}
@keyframes flash-2 { 
    0%, 100% { background: rgba(0,255,255,1);
    box-shadow: 0px $globe-height/6 $globe-width*2 $globe-spread rgba(0,255,255,1);} 
    50% { background: rgba(0,255,255,$light-off-opacity);
    box-shadow: 0px $globe-height/6 $globe-width*2 $globe-spread rgba(0,255,255,0.2);}
}
@keyframes flash-3 { 
    0%, 100% { background: rgba(247,0,148,1);
    box-shadow: 0px $globe-height/6 $globe-width*2 $globe-spread rgba(247,0,148,1);} 
    50% { background: rgba(247,0,148,$light-off-opacity);
    box-shadow: 0px $globe-height/6 $globe-width*2 $globe-spread rgba(247,0,148,0.2);}
}
</style>