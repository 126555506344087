<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <h1 v-html="isInfo.title"></h1>
          </v-col>
          <v-col cols="12" v-html="isInfo.discription" class="tol">

          </v-col>
        </v-row>
        </v-container>
</template>
<script>
export default {
  data: () => ({
    
  }),
  name: "RulesPerson",
  methods: {
    
  },
  mounted() {
      this.$store.dispatch("getDataRulesPerson")
  },
  computed: {
    isInfo() {
      return this.$store.getters.getRulesPerson;
    },
  },
};
</script>
<style scoped>
.tol {
    text-align: left;
}
</style>