<template>
  <v-container>
    <v-row>
      <v-col cols="4" class="mb-4"></v-col>
      <v-col cols="4" class="mb-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            v-model="email"
            label="Тема сообщения"
            required
          ></v-text-field>

          <v-textarea
          name="input-7-1"
          label="Текст сообщения"
          hint="Введите сообщение"
        ></v-textarea>
          <v-btn color="success" :disabled="!valid" @click="validate">
            Отправить
          </v-btn>
        </v-form>
      </v-col>
      <v-col cols="4" class="mb-4"> </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn color="primary" class="mr-4">
          Отмена
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    name: "",
    inn: "",
    pass: "",
    passNext: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    select: null,
    items: ["Item 1", "Item 2", "Item 3", "Item 4"],
    checkbox: false,
  }),
  name: "account",
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
