<template>
<v-container fluid:true>
    <!-- <v-btn @click="Export2Word('exportContent', 'word-content')">Загрузить</v-btn> -->
    <v-btn @click="downloadURL(dataSpecialist.linkCV)">Загрузить</v-btn>
    <v-content id="exportContent" style="text-align:left; margin:auto;">
      <div style="margin-left: 75px" v-if="Object.keys(dataSpecialist).length !== 0">
            <h2 style="text-align:center; margin-bottom: 50px;"> Общая информация</h2>
            <p style="margin-top: 5px;"><b>Имя: </b> {{ dataSpecialist.FIO }}</p>
            <p style="margin-top: 5px;"><b>Уровень: </b> {{ dataSpecialist.Level }}</p>
            <p style="margin-top: 5px;"><b>Роль: </b> {{ dataSpecialist.Category }}</p>
            <p style="margin-top: 5px;"><b>Уровень английского: </b> {{ dataSpecialist.English }}</p>
            <p style="margin-top: 5px;"><b>Локация: </b> {{ dataSpecialist.Country }}</p>
            <p style="margin-top: 5px;"><b>Коммерческий опыт: </b> {{ dataSpecialist.Years_of_Experience }}</p>
            <h2 style="text-align:center; margin-top: 30px; margin-bottom: 30px;">Ключевые навыки</h2>
            <p style="margin-top: 5px;"> {{ dataSpecialist.Main_skills.toString() }}</p>
            <h2 style="margin-top: 5px; text-align:center; margin-top: 30px; margin-bottom: 30px;"> Опыт в комерческой
                разработке</h2>
            <div v-for="(n, i) in dataSpecialist.ind" :key="i">
                <p style="margin-top: 15px;">#{{ (i+1) }}</p>
                <p style="margin-top: 5px;"><b>Период:</b> {{ n.date_start }} - {{ n.date_ent }}</p>
                <p style="margin-top: 5px;"><b>Проект: </b>{{ n.name }}</p>
                <p style="margin-top: 5px;"><b>Описание проекта: </b>{{ n.description }}</p><br />
                <p style="margin-top: 5px;"><b>Роль: </b> {{ n.rols }}</p>
                <p style="margin-top: 5px;"><b>Стек: </b>{{ n.skill.toString() }}</p>
                <p style="margin-top: 5px;"><b>Полномочия и обязанности: </b>
                    <pre>{{ n.obaz }}</pre>
                </p>
                <p style="margin-top: 5px;"><b>Достижения: </b>
                    <pre>{{ n.dost }}</pre>
                </p>
                <p style="margin-top: 5px;"><b>Команда: </b>{{ n.command }}</p>
            </div>
            <h2 style="margin-top: 5px; text-align:center; margin-top: 30px; margin-bottom: 30px;">Образование</h2>
            <table style="width:100%">
                <tr v-for="(n, i) in dataSpecialist.educInfo" :key="i">
                    <td>
                        <pre>{{ n.name }}</pre>
                    </td>
                </tr>
            </table>
        </div>

    </v-content>
</v-container>
</template>

<script>
export default {
    data: () => ({
        dataSpecialist: [],
    }),
    name: "CV",
    methods: {
        Export2Word(element, filename = '') {

            var preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
            var postHtml = "</body></html>";
            var html = preHtml + document.getElementById(element).innerHTML + postHtml;

            var blob = new Blob(['\ufeff', html], {
                type: 'application/msword'
            });

            // Specify link url
            var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);

            // Specify file name
            filename = filename ? filename + '.doc' : 'document.doc';

            // Create download link element
            var downloadLink = document.createElement("a");

            document.body.appendChild(downloadLink);

            if (navigator.msSaveOrOpenBlob) {
                navigator.msSaveOrOpenBlob(blob, filename);
            } else {
                // Create a link to the file
                downloadLink.href = url;

                // Setting the file name
                downloadLink.download = filename;

                //triggering the function
                downloadLink.click();
            }

            document.body.removeChild(downloadLink);
        },

        downloadURL(url) {
            var hiddenIFrameID = 'hiddenDownloader',
                iframe = document.getElementById(hiddenIFrameID);
            if (iframe === null) {
                iframe = document.createElement('iframe');
                iframe.id = hiddenIFrameID;
                iframe.style.display = 'none';
                document.body.appendChild(iframe);
            }
            iframe.src = url;

        }

    },
    mounted() {
        let t = this.$route.params.id;
        console.log(this.$route.params.id);
        this.$store.dispatch("getSpecialistInfoId", t).then(res => {
            console.log(res);
            this.dataSpecialist = res;
        })
    },
    components: {},
    computed: {
        desserts() {
            return this.$store.getters.InfoSpec;
        },
        spec() {
            return this.$store.getters.getSpecialId;
        },
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
pre {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    width: 100%;
    padding: 0;
    margin: 0;
    overflow: auto;
    overflow-y: hidden;
    line-height: 20px;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}

.vue-html2pdf {
    .layout-container {
        position: fixed;
        width: 100vw;
        height: 100vh;
        left: -100vw;
        top: 0;
        z-index: -9999;
        background: rgba(95, 95, 95, 0.8);
        display: flex;
        justify-content: center;
        align-items: flex-start;
        overflow: auto;

        &.show-layout {
            left: 0vw;
            z-index: 9999;
        }

        &.unset-all {
            all: unset;
            width: auto;
            height: auto;
        }
    }

    .pdf-preview {
        position: fixed;
        width: 65%;
        min-width: 600px;
        height: 80vh;
        top: 100px;
        z-index: 9999999;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 5px;
        box-shadow: 0px 0px 10px #00000048;

        button {
            position: absolute;
            top: -20px;
            left: -15px;
            width: 35px;
            height: 35px;
            background: #555;
            border: 0;
            box-shadow: 0px 0px 10px #00000048;
            border-radius: 50%;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            cursor: pointer;
        }

        iframe {
            border: 0;
        }
    }

    .transition-anim-enter-active,
    .transition-anim-leave-active {
        transition: opacity 0.3s ease-in;
    }

    .transition-anim-enter,
    .transition-anim-leave-to {
        opacity: 0;
    }
}
</style>
