import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios';

Vue.use(Vuex)

export default ({
  state: {
    ListUserMeneger: [],
    listPersonCompanySpec: [],
    ListUser: [],
    ListCustomer: [],
    ListProject: [],
    ListProjectConst: [],
    ListValute: [],
    ListStatusProject: [],
    ListSpecialShort: [],
    ListBxDeal: [],
    ListBxDealBucap: [],
    InfoSpec: [],
    ListFilter: [],
    ListSumm: [],
    infoRequst: [],
    ListFond: [],
    ListTransaction: [],
    ListTransactionConst: [],
    ListTransactionHistory: [],
    ListFirmBot: [],
    ListRequestCLient: [],
    ListCustomer1: [],
    ListCV: [],
    ListCompanySkill: [],
    ListAnswer: [],
    ListDataFond: {
      tran: {
        vir: 0,
        nvir: 0,
        cb: 0,
        nal: 0,
        nds: 0,
        marga: 0,
        fzp: 0,
        fgp: 0,
        fst: 0,
        fkr: 0,
        fmr: 0,
        fpr: 0,
        fdv: 0,
      },
      fond: {
        vir: 0,
        nvir: 0,
        cb: 0,
        nal: 0,
        nds: 0,
        marga: 0,
        fzp: 0,
        fgp: 0,
        fst: 0,
        fkr: 0,
        fmr: 0,
        fpr: 0,
        fdv: 0,
      },
    },
    SummTransaction: 0,

    project: {
      id: 0,
      link: "",
      customer: "",
      level: [],
      role: "",
      location: "",
      about: "",
      demand: "",
      reit: 0,
      valute: "RUB",
      status: "Активен",
    },
    spec: {
      id: 0,
      rolecat: [],
      fname: "",
      lname: "",
      tname: "",
      level: "",
      loc: "",
      grag: "",
      zone: "",
      exp: "",
      rater: "",
      ratern: "",
      lang: "",
      link: "",
      levelSpec: "",
      company: "",
      skills: [],
      skillLevel: [],
      expInfo: "",
      ind: [],
      eduUp: false,
      educInfo: [],
      about: "",
      enb: false,
      citizenship: "",
      taxID: '',
      availability: "",
    },

  },
  getters: {
    ListDataFond(state) {
      return state.ListDataFond;
    },

    ListAnswer(state) {
      return state.ListAnswer;
    },

    infoRequst(state) {
      return state.infoRequst
    },

    getListMeneger(state) {
      return state.ListUserMeneger;
    },
    ListFilter(state) {
      return state.ListFilter;
    },
    ListCVSpec(state) {
      return state.ListCV
    },
    ListSumm(state) {
      return state.ListSumm;
    },
    SummTransaction(state) {
      return state.SummTransaction;
    },

    getListCompanySkill(state) {
      return state.ListCompanySkill;
    },

    ListFond(state) {
      return state.ListFond;
    },
    ListTransactionHistory(state) {
      return state.ListTransactionHistory
    },
    ListTransaction(state) {
      return state.ListTransaction;
    },
    InfoSpec(state) {
      return state.InfoSpec;
    },
    getListPersonSpec(state) {
      return state.listPersonCompanySpec
    },
    getSpecialId(state) {
      return state.spec;
    },
    getNotification(state) {
      return state.ListUser;
    },
    getCustomer(state) {
      return state.ListCustomer;
    },
    getListCustomer(state) {
      return state.ListCustomer1;
    },
    getListProject(state) {
      return state.ListProject;
    },
    getProjectInfo(state) {
      return state.project;
    },
    getValute(state) {
      return state.ListValute;
    },
    getStatusProject(state) {
      return state.ListStatusProject;
    },
    getSpecialShort(state) {
      return state.ListSpecialShort;
    },
    getListBxDeal(state) {
      return state.ListBxDeal;
    },
    getListFirmBot(state) {
      return state.ListFirmBot;
    },

    getListRequestCLient(state) {
      return state.ListRequestCLient;
    },


  },


  mutations: {
    updateMeneger(state, payload) {
      state.ListUserMeneger = payload
    },

    updateListAnswer(state, payload) {
      state.ListAnswer = [];
      for (let index = 0; index < payload.length; index++) {
        if(payload[index].activ==1){
          payload[index].actStr = 'Активен'
        } 
        else {
          payload[index].actStr = 'Закрыт'
        }
        
      }
      state.ListAnswer = payload
    },

    updateCVList(state, payload) {
      console.log('sdsss');
      
      state.ListCV = payload
      console.log(state.ListCV);
    },

    updateInfoRequst(state, payload){
      state.infoRequst = payload
    },

    updateListFirmBot(state, payload) {
      state.ListFirmBot = payload
      //console.log(state.ListFirmBot)

    },
    updateCompanySpecialist(state, payload) {
      state.listPersonCompanySpec = payload;
      for (let index = 0; index < payload.length; index++) {
        // state.ListSpecialShort.push(payload[index].id + ' ' + payload[index].FIO+' '+payload[index].Role+' '+payload[index].Level+' '+payload[index].Rate_RUB);
        state.ListSpecialShort.push(payload[index].FIO + ' (ID: ' + payload[index].id + '; Роль: ' + payload[index].Role + '; Грейд: ' + payload[index].Level + '; Цена: ' + payload[index].Rate_RUB + ' RUB)');

      }
    },

    updateListRequest(state, payload) {
      state.ListRequestCLient = [];
      for (let index = 0; index < payload.length; index++) {
        // state.ListSpecialShort.push(payload[index].id + ' ' + payload[index].FIO+' '+payload[index].Role+' '+payload[index].Level+' '+payload[index].Rate_RUB);
        state.ListRequestCLient.push(payload[index].req);

      }
    },

    updateCompanySkillList(state, payload)
    {
      state.ListCompanySkill = payload
    },
    
    
    updateCompanySpecialistFile(state, payload) {
      state.spec.fname = payload.name;
      state.spec.level = payload.level;
      state.spec.rolecat = payload.role;
      state.spec.loc = payload.loc;
      state.spec.exp = payload.exp;
    },


    clearSpecialist(state) {
      state.spec.id = 0;
      state.grag = "";
      state.spec.rolecat = "";
      state.spec.fname = "";
      state.spec.lname = "";
      state.spec.tname = "";
      state.spec.level = "";
      state.spec.loc = "";
      state.spec.zone = "";
      state.spec.exp = "";
      state.spec.rater = "";
      state.spec.lang = "";
      state.spec.skills = [];
      state.spec.skillLevel = [];
      state.spec.expInfo = "";
      state.spec.ind = [];
      state.spec.eduUp = false;
      state.spec.educInfo = [];
      state.spec.about = "";
      state.spec.enb = false;
      state.spec.link = "";
      state.spec.taxID = "";
      state.spec.grag = "";
      state.spec.availability = "";
      state.spec.citizenship = "";

    },

    updateSpecialist(state, payload) {
      let pay = JSON.parse(JSON.stringify(payload))
      state.spec.id = pay['id'];
      state.spec.rolecat = pay['Role'];
      state.spec.fname = pay['FIO'];
      state.spec.lname = pay['lastName'];
      state.spec.tname = pay['trName'];
      state.spec.level = pay['Level'];
      state.spec.loc = pay['Country'];
      state.spec.zone = pay['Timezone'];
      state.spec.link = pay['linkCV'];
      state.spec.exp = pay['Years_of_Experience'];
      state.spec.rater = pay['zakyp_rub'];
      state.spec.company = pay['Company'];
      state.spec.lang = pay['English'];
      state.spec.skills = pay['Main_skills'];
      state.spec.skillLevel = pay['levelSkill'];
      state.spec.expInfo = pay['Experience'];
      state.spec.ind = pay['ind'];
      state.spec.eduUp = pay['higher_education_IT'];
      state.spec.educInfo = pay['educInfo'];
      state.spec.about = pay['about'];
      state.spec.enb = pay['enableCv'];
      state.spec.grag = pay['taxIdLocation'];
      state.spec.taxID = pay['TaxId'];
      state.spec.availability = pay['data_freed'];
    },
    updateListNotification(state, payload) {
      state.ListUser = payload;
    },
    updateListCustomer(state, payload) {
      for (let index = 0; index < payload.length; index++) {
        state.ListCustomer.push(payload[index].short_off_name);

      }

    },

    updateListCustomer1(state, payload) {
      
        state.ListCustomer1 = payload;

      

    },

    updateListTransactionHistory(state, payload) {
      state.ListTransactionHistory = payload;
    },
    updateInfoSpec(state, payload) {
      state.InfoSpec = payload
    },
    updateListProject(state, payload) {
      state.ListProjectConst = payload
      state.ListProject = payload
    },

    updateTransactionList(state, payload) {
      state.ListTransaction = payload;

      state.ListTransactionConst = payload;
      state.ListDataFond.tran.vir = 0;
      state.ListDataFond.tran.nvir = 0;
      state.ListDataFond.tran.cb = 0;
      state.ListDataFond.tran.nal = 0;
      state.ListDataFond.tran.nds = 0;
      state.ListDataFond.tran.marga = 0;
      state.ListDataFond.tran.fzp = 0;
      state.ListDataFond.tran.fgp = 0;
      state.ListDataFond.tran.fst = 0;
      state.ListDataFond.tran.fkr = 0;
      state.ListDataFond.tran.fmr = 0;
      state.ListDataFond.tran.fpr = 0;
      state.ListDataFond.tran.fdv = 0;
      for (let index = 0; index < state.ListTransaction.length; index++) {
        if (state.ListTransaction[index].name_fonds == "Фонд ЗП") {
          state.ListDataFond.tran.fzp += state.ListTransaction[index].summ * 1
        }
        if (state.ListTransaction[index].name_fonds == "Фонд годовой премии") {
          state.ListDataFond.tran.fgp += state.ListTransaction[index].summ * 1
        }
        if (state.ListTransaction[index].name_fonds == "Страховой фонд") {
          state.ListDataFond.tran.fst += state.ListTransaction[index].summ * 1
        }
        if (state.ListTransaction[index].name_fonds == "Корпоративный фонд") {
          state.ListDataFond.tran.fkr += state.ListTransaction[index].summ * 1
        }
        if (state.ListTransaction[index].name_fonds == "Фонд маркетинга") {
          state.ListDataFond.tran.fmr += state.ListTransaction[index].summ * 1
        }
        if (state.ListTransaction[index].name_fonds == "Фонд постоянных расходов") {
          state.ListDataFond.tran.fpr += state.ListTransaction[index].summ * 1
        }
        if (state.ListTransaction[index].name_fonds == "Фонд дивидендов") {
          state.ListDataFond.tran.fdv += state.ListTransaction[index].summ * 1
        }

        if (state.ListTransaction[index].name_fonds == "НДС") {
          state.ListDataFond.tran.nds += state.ListTransaction[index].summ * 1
        }
        if (state.ListTransaction[index].name_fonds == "Налоги") {
          state.ListDataFond.tran.nal += state.ListTransaction[index].summ * 1
        }
      }
    },

    summTransactionFonds(state) {
      state.SummTransaction = 0;
      for (let index = 0; index < state.ListTransaction.length; index++) {
        state.SummTransaction += state.ListTransaction[index].summ * 1;
      }
    },

    UpdateFindTransactionFonds(state, payload) {
      state.ListTransaction = [];
      let y = [];
      if (payload.dts == '' && payload.dtn == '' && (payload.fond == '' || payload.fond == null)) {
        state.ListTransaction = state.ListTransactionConst;
      } else {
        let a1 = [];
        let a2 = [];
        //console.log(payload)
        if (payload.dts != '') {
          a1 = state.ListTransactionConst.filter(zn => zn.date_tran >= payload.dts);
          if (payload.dtn == '' && (payload.fond == '' || payload.fond == null)) {
            y = a1;
          }
        }
        if (payload.dtn != '') {
          if (payload.dts != '') {
            a2 = a1.filter(zn => zn.date_tran <= payload.dtn);
          } else {
            a2 = state.ListTransactionConst.filter(zn => zn.date_tran <= payload.dtn);
          }
          if (payload.fond == '' || payload.fond == null) {
            y = a2;
          }
        }

        if (payload.fond != '' && payload.fond != null) {
          if (payload.dtn != '') {
            y = a2.filter(zn => zn.name_fonds === payload.fond);
          } else {
            if (payload.dts != '') {
              y = a1.filter(zn => zn.name_fonds === payload.fond);
            } else {
              y = state.ListTransactionConst.filter(zn => zn.name_fonds === payload.fond);
            }

          }
        }


        // for (let index = 0; index < state.ListTransactionConst.length; index++) {
        //   if (payload.dts != '') {
        //     if (y[index].date_tran >= payload.dts) {
        //       y.push(state.ListTransactionConst[index])
        //     }
        //   } else {

        //   }

        //   if (payload.dts == '' && payload.dtn == '' && payload.fond != '') {
        //     if (y[index].name_fonds <= payload.fond) {
        //       y.push(state.ListTransactionConst[index])
        //     }
        //   }


        // }
        // if (payload.dts != '') {
        //   for (let index = 0; index < y.length; index++) {

        //     if (y[index].date_tran < payload.dts) {
        //       y.splice[index, 1];
        //     }

        //   }

        // }
        // if (payload.dtn != '') {
        //   for (let index = 0; index < y.length; index++) {

        //     if (y[index].date_tran > payload.dtn) {
        //       y.splice[index, 1];
        //     }

        //   }

        // }
        // if (payload.fond != '') {
        //  //console.log(payload.fond);
        //   for (let index = 0; index < y.length; index++) {
        //     if (y[index].name_fonds != payload.fond) {
        //      //console.log(y[index].name_fonds);
        //       y.splice(index, 1);
        //     }
        //    //console.log(y);
        //   }

        // }


        state.ListTransaction = y;
      }

    },

    updateFondList(state, payload) {
      for (let index = 0; index < payload.length; index++) {
        state.ListFond.push(payload[index].name_fonds);

      }

    },

    sortListProject(state, payload) {

      state.ListProject = [];
      if (payload.radioGroup == "Все" || payload.radioGroup == "") {
        state.ListProject = state.ListProjectConst;
      }
      if (payload.radioGroup == "Не откликнулись") {

        for (let index = 0; index < state.ListProjectConst.length; index++) {
          if (state.ListProjectConst[index].zayv == 0) {
            //console.log("Не откликнулись");
            state.ListProject.push(state.ListProjectConst[index]);
          }
        }
      }
      if (payload.radioGroup == "В работе") {
        for (let index = 0; index < state.ListProjectConst.length; index++) {
          if (state.ListProjectConst[index].zayv == 1) {
            state.ListProject.push(state.ListProjectConst[index]);
          }
        }
      }
      //console.log(state.ListProject);
    },

    updateProjectInfo(state, payload) {
      state.project.id = payload.id;
      state.project.link = payload.link;
      state.project.customer = payload.firm;
      let l = payload.lvl.split(', ')
      for (let index = 0; index < l.length; index++) {
        state.project.level.push(l[index]);

      }
      state.project.role = payload.nameCategories;
      state.project.location = payload.loc;
      state.project.about = payload.abount;
      state.project.demand = payload.requirements;
      let t = payload.rt.split(' ');
      state.project.reit = t[0];
      state.project.valute = t[1];
      state.project.status = payload.nameSatatus;

    },
    cleatDataProject(state) {
      state.project.id = 0;
      state.project.link = "";
      state.project.customer = "";
      state.project.level = [];

      state.project.role = "";
      state.project.location = "";
      state.project.about = "";
      state.project.demand = "";
      state.project.reit = 0;
      state.project.valute = 'RUB';
      state.project.status = "Активен";
    },
    updateListValute(state, payload) {
      for (let index = 0; index < payload.length; index++) {
        state.ListValute.push(payload[index].name_valute);
      }
    },



    updateListSatusProject(state, payload) {
      for (let index = 0; index < payload.length; index++) {
        state.ListStatusProject.push(payload[index].nameSatatus);
      }
    },
    updateStatusProject(state, payload) {
      for (let index = 0; index < state.ListProject.length; index++) {
        if (payload.tp == 1) {
          if (state.ListProject[index].id == payload.idproj) {
            state.ListProject[index].zayv = 1;
            //console.log("test1");
            break;
          }
        } else {
          if (state.ListProject[index].id == payload.idproj) {
            state.ListProject[index].zayv = 0;
            //console.log("test2");
            break;
          }
        }

        //console.log(state.ListProject);
      }
    },
    UpdateFindBxDealFonds(state, payload) {
      //console.log(payload);
      let f = false;
      // if (payload.post.length > 0) {
      //   f = true;
      //   state.ListBxDeal = [];
      //   for (let index = 0; index < state.ListBxDealBucap.length; index++) {
      //     for (let i = 0; i < payload.post.length; i++) {
      //       if (payload.post[i] == state.ListBxDealBucap[index].UF_CRM_1671063594534) {
      //         if (Object.values(state.ListBxDeal).includes(state.ListBxDealBucap[index].TITLE)) {
      //           state.ListBxDeal.push(state.ListBxDealBucap[index]);
      //         }
      //       }

      //     }


      //   }
      // }
      // if (payload.client.length > 0) {
      //   let ddd = JSON.parse(JSON.stringify(state.ListBxDealBucap));
      //   if (f == true) {
      //     ddd = JSON.parse(JSON.stringify(state.ListBxDeal));
      //   }

      //   f = true;
      //   state.ListBxDeal = [];
      //  //console.log(ddd);
      //   for (let index = 0; index < ddd.length; index++) {
      //     for (let i = 0; i < payload.client.length; i++) {
      //       if (payload.client[i] == ddd[index].company) {
      //         state.ListBxDeal.push(ddd[index]);
      //       }
      //     }


      //   }
      // }

      // if (payload.project.length > 0) {
      //   let ddd = JSON.parse(JSON.stringify(state.ListBxDealBucap));
      //   if (f == true) {
      //     ddd = JSON.parse(JSON.stringify(state.ListBxDeal));
      //   }

      //   f = true;
      //   state.ListBxDeal = [];
      //  //console.log(ddd);
      //   for (let index = 0; index < ddd.length; index++) {
      //     for (let i = 0; i < payload.project.length; i++) {
      //       if (payload.project[i] == ddd[index].UF_CRM_1672773790979) {
      //         state.ListBxDeal.push(ddd[index]);
      //       }
      //     }


      //   }
      // }
      //console.log("stady ")
      //let ss = 0;
      if (payload.stad.length > 0 && payload.stad != null) {
        let ddd = JSON.parse(JSON.stringify(state.ListBxDealBucap));
        if (f == true) {
          ddd = JSON.parse(JSON.stringify(state.ListBxDeal));
        }

        f = true;
        state.ListBxDeal = [];

        for (let index = 0; index < ddd.length; index++) {
          for (let i = 0; i < payload.stad.length; i++) {
            if (payload.stad[i] == ddd[index].STAGE_ID) {
              //console.log(payload.stad[i])
              if (!Object.values(state.ListBxDeal).includes(ddd[index].ID)) {
                state.ListBxDeal.push(ddd[index]);
                //ss += ddd[index].sumprod * 1;
              }
            }
          }


        }
      }
      //console.log(ss);
      //console.log(state.ListBxDeal);
      // if (payload.otv.length > 0) {
      //   let ddd = JSON.parse(JSON.stringify(state.ListBxDealBucap));
      //   if (f == true) {
      //     ddd = JSON.parse(JSON.stringify(state.ListBxDeal));
      //   }

      //   f = true;
      //   state.ListBxDeal = [];
      //  //console.log(ddd);
      //   for (let index = 0; index < ddd.length; index++) {
      //     for (let i = 0; i < payload.otv.length; i++) {
      //       if (payload.otv[i] == ddd[index].NAME) {
      //         state.ListBxDeal.push(ddd[index]);
      //       }
      //     }


      //   }
      // }
      // if (payload.meneg.length > 0) {
      //   let ddd = JSON.parse(JSON.stringify(state.ListBxDealBucap));
      //   if (f == true) {
      //     ddd = JSON.parse(JSON.stringify(state.ListBxDeal));
      //   }

      //   f = true;
      //   state.ListBxDeal = [];
      //  //console.log(ddd);
      //   for (let index = 0; index < ddd.length; index++) {
      //     for (let i = 0; i < payload.meneg.length; i++) {
      //       if (payload.meneg[i] == ddd[index].NAMEMENEG) {
      //         state.ListBxDeal.push(ddd[index]);
      //       }
      //     }


      //   }
      // }
      if (payload.dts != '') {
        let ddd = JSON.parse(JSON.stringify(state.ListBxDealBucap));
        if (f == true) {
          ddd = JSON.parse(JSON.stringify(state.ListBxDeal));
        }

        f = true;
        state.ListBxDeal = [];
        //console.log(payload);
        //console.log('dts');

        let dArr = new Date(payload.dts)
        for (let index = 0; index < ddd.length; index++) {
          let r = new Date(ddd[index].BEGINDATE.split('-').reverse().join('-'))

          //console.log('date='+payload.dts+' zn='+dArr+' bddate='+ddd[index].CLOSEDATE+' zn='+r+' result='+(r>=dArr));
          if (dArr <= r) {
            if (!Object.values(state.ListBxDeal).includes(ddd[index].ID)) {
              state.ListBxDeal.push(ddd[index]);
            }

          }


        }

      }


      if (payload.dte != '') {
        let ddd = JSON.parse(JSON.stringify(state.ListBxDealBucap));
        if (f == true) {
          ddd = JSON.parse(JSON.stringify(state.ListBxDeal));
        }

        f = true;
        state.ListBxDeal = [];
        //console.log('dte');
        let dArr = new Date(payload.dte)
        for (let index = 0; index < ddd.length; index++) {
          let r = new Date(ddd[index].CLOSEDATE.split('-').reverse().join('-'));
          //console.log('date='+payload.dte+' zn='+dArr+' bddate='+ddd[index].CLOSEDATE+' zn='+r);
          if (dArr >= r) {
            if (!Object.values(state.ListBxDeal).includes(ddd[index].ID)) {
              state.ListBxDeal.push(ddd[index]);
            }

          }


        }
      }
      //let tran = state.ListTransaction;

      //console.log(state.ListTransaction);
      let ddd = JSON.parse(JSON.stringify(state.ListBxDeal));
      state.ListBxDeal = [];
      for (let index = 0; index < ddd.length; index++) {
        let r = false;
        for (let i = 0; i < state.ListBxDeal.length; i++) {
          if (state.ListBxDeal[i].ID == ddd[index].ID) {
            r = true;
          }
        }
        if (r == false) {
          state.ListBxDeal.push(ddd[index]);
        }

      }
      //console.log(state.ListBxDeal);
      let arr = {
        fzp: 0,
        fp: 0,
        fst: 0,
        fk: 0,
        fm: 0,
        fps: 0,
        fd: 0,
        nds: 0,
        nalog: 0,
      }
      state.ListDataFond.tran.vir = 0;
      state.ListDataFond.tran.nvir = 0;
      state.ListDataFond.tran.cb = 0;
      state.ListDataFond.tran.nal = 0;
      state.ListDataFond.tran.nds = 0;
      state.ListDataFond.tran.marga = 0;
      state.ListDataFond.tran.fzp = 0;
      state.ListDataFond.tran.fgp = 0;
      state.ListDataFond.tran.fst = 0;
      state.ListDataFond.tran.fkr = 0;
      state.ListDataFond.tran.fmr = 0;
      state.ListDataFond.tran.fpr = 0;
      state.ListDataFond.tran.fdv = 0;
      for (let index = 0; index < state.ListTransaction.length; index++) {
        //state.ListDataFond.fond.vir += state.ListBxDeal[index].sumprod * 1;
        if (state.ListTransaction[index].name_fonds == "Фонд ЗП") {
          arr.fzp += state.ListTransaction[index].summ * 1
          state.ListDataFond.tran.fzp += state.ListTransaction[index].summ * 1

        }
        if (state.ListTransaction[index].name_fonds == "Фонд годовой премии") {
          arr.fp += state.ListTransaction[index].summ * 1
          state.ListDataFond.tran.fgp += state.ListTransaction[index].summ * 1

        }
        if (state.ListTransaction[index].name_fonds == "Страховой фонд") {
          arr.fst += state.ListTransaction[index].summ * 1
          state.ListDataFond.tran.fst += state.ListTransaction[index].summ * 1

        }
        if (state.ListTransaction[index].name_fonds == "Корпоративный фонд") {
          arr.fk += state.ListTransaction[index].summ * 1
          state.ListDataFond.tran.fkr += state.ListTransaction[index].summ * 1

        }
        if (state.ListTransaction[index].name_fonds == "Фонд маркетинга") {
          arr.fm += state.ListTransaction[index].summ * 1
          state.ListDataFond.tran.fmr += state.ListTransaction[index].summ * 1

        }
        if (state.ListTransaction[index].name_fonds == "Фонд постоянных расходов") {
          arr.fps += state.ListTransaction[index].summ * 1
          state.ListDataFond.tran.fpr += state.ListTransaction[index].summ * 1

        }
        if (state.ListTransaction[index].name_fonds == "Фонд дивидендов") {
          arr.fd += state.ListTransaction[index].summ * 1
          state.ListDataFond.tran.fdv += state.ListTransaction[index].summ * 1

        }

        if (state.ListTransaction[index].name_fonds == "НДС") {
          arr.nds += state.ListTransaction[index].summ * 1
          state.ListDataFond.tran.nds += state.ListTransaction[index].summ * 1

        }
        if (state.ListTransaction[index].name_fonds == "Налоги") {
          arr.nalog += state.ListTransaction[index].summ * 1
          state.ListDataFond.tran.nal += state.ListTransaction[index].summ * 1

        }
        //state.ListDataFond.tran.
      }
      //console.log(arr);

      state.ListSumm.ZAKYP = 0;
      state.ListSumm.sumprod = 0;
      state.ListSumm.nalog = 0 - arr.nalog;
      state.ListSumm.marjach = 0;
      state.ListSumm.marjas = 0;
      state.ListSumm.fondzp = 0 - arr.fzp;
      state.ListSumm.fondp = 0 - arr.fps;
      state.ListSumm.fonds = 0 - arr.fst;
      state.ListSumm.fondk = 0 - arr.fk;
      state.ListSumm.fondm = 0 - arr.fm;
      state.ListSumm.fondd = 0 - arr.fd;
      state.ListSumm.fondp1 = 0 - arr.fp;
      state.ListSumm.nds = 0 - arr.nds;
      state.ListSumm.hour = 0;
      state.ListSumm.f1 = 0;
      state.ListSumm.new = 0;
      state.ListSumm.vipl = 0;
      state.ListDataFond.fond.vir = 0;
      state.ListDataFond.fond.nvir = 0;
      state.ListDataFond.fond.cb = 0;
      state.ListDataFond.fond.nal = 0;
      state.ListDataFond.fond.nds = 0;
      state.ListDataFond.fond.marga = 0;
      state.ListDataFond.fond.fzp = 0;
      state.ListDataFond.fond.fgp = 0;
      state.ListDataFond.fond.fst = 0;
      state.ListDataFond.fond.fkr = 0;
      state.ListDataFond.fond.fmr = 0;
      state.ListDataFond.fond.fpr = 0;
      state.ListDataFond.fond.fdv = 0;
      //console.log("ddddd");
      //console.log(state.ListBxDeal);
      for (let index = 0; index < state.ListBxDeal.length; index++) {
        state.ListSumm.ZAKYP += state.ListBxDeal[index].ZAKYP * 1;
        state.ListDataFond.fond.cb += state.ListBxDeal[index].ZAKYP * 1;

        state.ListSumm.sumprod += state.ListBxDeal[index].sumprod * 1;
        state.ListDataFond.fond.vir += state.ListBxDeal[index].sumprod * 1;

        state.ListSumm.nalog += state.ListBxDeal[index].nalog * 1;
        state.ListDataFond.fond.nal += state.ListBxDeal[index].nalog * 1;

        state.ListSumm.marjach += state.ListBxDeal[index].marjach * 1;
        state.ListDataFond.fond.marga += state.ListBxDeal[index].marjas * 1;

        state.ListSumm.marjas += state.ListBxDeal[index].marjas * 1;
        // state.ListDataFond.fond.fzp +=state.ListBxDeal[index].ZAKYP * 1;

        state.ListSumm.fondzp += state.ListBxDeal[index].fondzp * 1;
        state.ListDataFond.fond.fzp += state.ListBxDeal[index].fondzp * 1;

        state.ListSumm.fondp += state.ListBxDeal[index].fondp * 1;
        state.ListDataFond.fond.fpr += state.ListBxDeal[index].fondp * 1;

        state.ListSumm.fonds += state.ListBxDeal[index].fonds * 1;
        state.ListDataFond.fond.fst += state.ListBxDeal[index].fonds * 1;

        state.ListSumm.fondk += state.ListBxDeal[index].fondk * 1;
        state.ListDataFond.fond.fkr += state.ListBxDeal[index].fondk * 1;

        state.ListSumm.fondm += state.ListBxDeal[index].fondm * 1;
        state.ListDataFond.fond.fmr += state.ListBxDeal[index].fondm * 1;

        state.ListSumm.fondd += state.ListBxDeal[index].fondd * 1;
        state.ListDataFond.fond.fdv += state.ListBxDeal[index].fondd * 1;

        state.ListSumm.fondp1 += state.ListBxDeal[index].fondp1 * 1;
        state.ListDataFond.fond.fgp += state.ListBxDeal[index].fondp1 * 1;

        state.ListSumm.nds += state.ListBxDeal[index].UF_CRM_1670087477859 * 1;
        state.ListDataFond.fond.nds += state.ListBxDeal[index].UF_CRM_1670087477859 * 1;
        //state.ListDataFond.fond.cb +=state.ListBxDeal[index].ZAKYP * 1;

        state.ListSumm.hour += state.ListBxDeal[index].UF_CRM_1670087417814 * 1;

        if (state.ListBxDeal[index].UF_CRM_1669048676257 != null) {
          state.ListSumm.vipl += state.ListBxDeal[index].UF_CRM_1669048676257 * 1;
          state.ListDataFond.fond.nvir += state.ListBxDeal[index].UF_CRM_1669048676257 * 1;

        }

        if (state.ListBxDeal[index].TITLE.indexOf('(продление)') == -1) {
          state.ListSumm.new += 1;
        }

      }
      state.ListSumm.f1 = state.ListSumm.sumprod / state.ListSumm.hour;
      //console.log(state.ListSumm);
      //console.log(state.ListDataFond);
    },

    UpdateFindBxDeal(state, payload) {
      //console.log(payload);
      let f = false;
      if (payload.post.length > 0) {
        f = true;
        state.ListBxDeal = [];
        for (let index = 0; index < state.ListBxDealBucap.length; index++) {
          for (let i = 0; i < payload.post.length; i++) {
            if (payload.post[i] == state.ListBxDealBucap[index].UF_CRM_1671063594534) {
              if (Object.values(state.ListBxDeal).includes(state.ListBxDealBucap[index].TITLE)) {
                state.ListBxDeal.push(state.ListBxDealBucap[index]);
              }

            }

          }


        }
      }
      if (payload.client.length > 0) {
        let ddd = JSON.parse(JSON.stringify(state.ListBxDealBucap));
        if (f == true) {
          ddd = JSON.parse(JSON.stringify(state.ListBxDeal));
        }

        f = true;
        state.ListBxDeal = [];
        //console.log(ddd);
        for (let index = 0; index < ddd.length; index++) {
          for (let i = 0; i < payload.client.length; i++) {
            if (payload.client[i] == ddd[index].company) {
              state.ListBxDeal.push(ddd[index]);
            }
          }


        }
      }

      if (payload.project.length > 0) {
        let ddd = JSON.parse(JSON.stringify(state.ListBxDealBucap));
        if (f == true) {
          ddd = JSON.parse(JSON.stringify(state.ListBxDeal));
        }

        f = true;
        state.ListBxDeal = [];
        //console.log(ddd);
        for (let index = 0; index < ddd.length; index++) {
          for (let i = 0; i < payload.project.length; i++) {
            if (payload.project[i] == ddd[index].UF_CRM_1672773790979) {
              state.ListBxDeal.push(ddd[index]);
            }
          }


        }
      }
      //console.log("stady deal")
      //let ss = 0;
      if (payload.stad.length > 0 && payload.stad != null) {
        let ddd = JSON.parse(JSON.stringify(state.ListBxDealBucap));
        if (f == true) {
          ddd = JSON.parse(JSON.stringify(state.ListBxDeal));
        }

        f = true;
        state.ListBxDeal = [];
        //console.log(payload.stad);
        for (let index = 0; index < ddd.length; index++) {
          for (let i = 0; i < payload.stad.length; i++) {
            if (payload.stad[i] == ddd[index].STAGE_ID) {
              //console.log(payload.stad[i]);
              //console.log(Object.values(state.ListBxDeal).includes(ddd[index].ID))

              if (!Object.values(state.ListBxDeal).includes(ddd[index].ID)) {
                state.ListBxDeal.push(ddd[index]);
                //ss += ddd[index].sumprod * 1;
              }

            }
          }


        }
      }
      //console.log(ss);

      if (payload.otv.length > 0) {
        let ddd = JSON.parse(JSON.stringify(state.ListBxDealBucap));
        if (f == true) {
          ddd = JSON.parse(JSON.stringify(state.ListBxDeal));
        }

        f = true;
        state.ListBxDeal = [];
        //console.log(ddd);
        for (let index = 0; index < ddd.length; index++) {
          for (let i = 0; i < payload.otv.length; i++) {
            if (payload.otv[i] == ddd[index].NAME) {
              state.ListBxDeal.push(ddd[index]);
            }
          }


        }
      }
      if (payload.meneg.length > 0) {
        let ddd = JSON.parse(JSON.stringify(state.ListBxDealBucap));
        if (f == true) {
          ddd = JSON.parse(JSON.stringify(state.ListBxDeal));
        }

        f = true;
        state.ListBxDeal = [];
        //console.log(ddd);
        for (let index = 0; index < ddd.length; index++) {
          for (let i = 0; i < payload.meneg.length; i++) {
            if (payload.meneg[i] == ddd[index].NAMEMENEG) {
              state.ListBxDeal.push(ddd[index]);
            }
          }


        }
      }
      if (payload.dts != '') {
        let ddd = JSON.parse(JSON.stringify(state.ListBxDealBucap));
        if (f == true) {
          ddd = JSON.parse(JSON.stringify(state.ListBxDeal));
        }

        f = true;
        state.ListBxDeal = [];
        //console.log(payload);
        //console.log('dts');

        let dArr = new Date(payload.dts)
        for (let index = 0; index < ddd.length; index++) {
          let r = new Date(ddd[index].BEGINDATE.split('-').reverse().join('-'))

          //console.log('date='+payload.dts+' zn='+dArr+' bddate='+ddd[index].CLOSEDATE+' zn='+r+' result='+(r>=dArr));
          if (dArr <= r) {
            if (!Object.values(state.ListBxDeal).includes(ddd[index].ID)) {
              state.ListBxDeal.push(ddd[index]);
            }


          }


        }
      }

      if (payload.dte != '') {
        let ddd = JSON.parse(JSON.stringify(state.ListBxDealBucap));
        if (f == true) {
          ddd = JSON.parse(JSON.stringify(state.ListBxDeal));
        }

        f = true;
        state.ListBxDeal = [];
        //console.log('dte');
        let dArr = new Date(payload.dte)
        for (let index = 0; index < ddd.length; index++) {
          let r = new Date(ddd[index].CLOSEDATE.split('-').reverse().join('-'));
          //console.log('date='+payload.dte+' zn='+dArr+' bddate='+ddd[index].CLOSEDATE+' zn='+r);
          if (dArr >= r) {
            if (!Object.values(state.ListBxDeal).includes(ddd[index].ID)) {
              state.ListBxDeal.push(ddd[index]);
            }


          }


        }
      }
      let ddd = JSON.parse(JSON.stringify(state.ListBxDeal));
      state.ListBxDeal = [];
      for (let index = 0; index < ddd.length; index++) {
        let r = false;
        for (let i = 0; i < state.ListBxDeal.length; i++) {
          if (state.ListBxDeal[i].ID == ddd[index].ID) {
            r = true;
          }
        }
        if (r == false) {
          state.ListBxDeal.push(ddd[index]);
        }

      }
      //console.log(state.ListBxDeal);
      state.ListSumm.ZAKYP = 0;
      state.ListSumm.sumprod = 0;
      state.ListSumm.nalog = 0;
      state.ListSumm.marjach = 0;
      state.ListSumm.marjas = 0;
      state.ListSumm.fondzp = 0;
      state.ListSumm.fondp = 0;
      state.ListSumm.fonds = 0;
      state.ListSumm.fondk = 0;
      state.ListSumm.fondm = 0;
      state.ListSumm.fondp1 = 0;
      state.ListSumm.fondd = 0;
      state.ListSumm.nds = 0;
      state.ListSumm.hour = 0;
      state.ListSumm.f1 = 0;
      state.ListSumm.new = 0;
      state.ListSumm.vipl = 0;

      for (let index = 0; index < state.ListBxDeal.length; index++) {
        state.ListSumm.ZAKYP += state.ListBxDeal[index].ZAKYP * 1;
        state.ListSumm.sumprod += state.ListBxDeal[index].sumprod * 1;
        state.ListSumm.nalog += state.ListBxDeal[index].nalog * 1;
        state.ListSumm.marjach += state.ListBxDeal[index].marjach * 1;
        state.ListSumm.marjas += state.ListBxDeal[index].marjas * 1;
        state.ListSumm.fondzp += state.ListBxDeal[index].fondzp * 1;
        state.ListSumm.fondp += state.ListBxDeal[index].fondp * 1;
        state.ListSumm.fonds += state.ListBxDeal[index].fonds * 1;
        state.ListSumm.fondk += state.ListBxDeal[index].fondk * 1;
        state.ListSumm.fondm += state.ListBxDeal[index].fondm * 1;
        state.ListSumm.fondd += state.ListBxDeal[index].fondd * 1;
        state.ListSumm.nds += state.ListBxDeal[index].UF_CRM_1670087477859 * 1;
        state.ListSumm.hour += state.ListBxDeal[index].UF_CRM_1670087417814 * 1;
        if (state.ListBxDeal[index].UF_CRM_1669048676257 != null) {
          state.ListSumm.vipl += state.ListBxDeal[index].UF_CRM_1669048676257 * 1;
        }

        if (state.ListBxDeal[index].TITLE.indexOf('(продление)') == -1) {
          state.ListSumm.new += 1;
        }

      }
      state.ListSumm.f1 = state.ListSumm.sumprod / state.ListSumm.hour;
      //console.log(state.ListSumm)
    },

    UpdateBxDeal(state, payload) {
      state.ListBxDeal = payload.list;
      state.ListBxDealBucap = payload.list;
      state.ListFilter = payload.filter;
      alert("Данные сделки не могу быть отображены из-за отсуствия связи с фирмами (" + payload.err.join() + ") проверте правильность указаных фирм или произведите синхронизацию фирм на платформе")

      state.ListSumm = payload.summ;
    },
    UpdateBxDealFonds(state, payload) {
      let arr = {
        fzp: 0,
        fp: 0,
        fst: 0,
        fk: 0,
        fm: 0,
        fps: 0,
        fd: 0
      }
      state.ListDataFond.tran.vir = 0;
      state.ListDataFond.tran.nvir = 0;
      state.ListDataFond.tran.cb = 0;
      state.ListDataFond.tran.nal = 0;
      state.ListDataFond.tran.nds = 0;
      state.ListDataFond.tran.marga = 0;
      state.ListDataFond.tran.fzp = 0;
      state.ListDataFond.tran.fgp = 0;
      state.ListDataFond.tran.fst = 0;
      state.ListDataFond.tran.fkr = 0;
      state.ListDataFond.tran.fmr = 0;
      state.ListDataFond.tran.fpr = 0;
      state.ListDataFond.tran.fdv = 0;
      for (let index = 0; index < state.ListTransaction.length; index++) {
        if (state.ListTransaction[index].name_fonds == "Фонд ЗП") {
          arr.fzp += state.ListTransaction[index].summ * 1
          state.ListDataFond.tran.fzp += state.ListTransaction[index].summ * 1

        }
        if (state.ListTransaction[index].name_fonds == "Фонд годовой премии") {
          arr.fp += state.ListTransaction[index].summ * 1
          state.ListDataFond.tran.fgp += state.ListTransaction[index].summ * 1

        }
        if (state.ListTransaction[index].name_fonds == "Страховой фонд") {
          arr.fst += state.ListTransaction[index].summ * 1
          state.ListDataFond.tran.fst += state.ListTransaction[index].summ * 1

        }
        if (state.ListTransaction[index].name_fonds == "Корпоративный фонд") {
          arr.fk += state.ListTransaction[index].summ * 1
          state.ListDataFond.tran.fkr += state.ListTransaction[index].summ * 1

        }
        if (state.ListTransaction[index].name_fonds == "Фонд маркетинга") {
          arr.fm += state.ListTransaction[index].summ * 1
          state.ListDataFond.tran.fmr += state.ListTransaction[index].summ * 1

        }
        if (state.ListTransaction[index].name_fonds == "Фонд постоянных расходов") {
          arr.fps += state.ListTransaction[index].summ * 1
          state.ListDataFond.tran.fpr += state.ListTransaction[index].summ * 1

        }
        if (state.ListTransaction[index].name_fonds == "Фонд дивидендов") {
          arr.fd += state.ListTransaction[index].summ * 1
          state.ListDataFond.tran.fdv += state.ListTransaction[index].summ * 1

        }
        if (state.ListTransaction[index].name_fonds == "НДС") {
          state.ListDataFond.tran.nds += state.ListTransaction[index].summ * 1
        }
        if (state.ListTransaction[index].name_fonds == "Налоги") {
          state.ListDataFond.tran.nal += state.ListTransaction[index].summ * 1
        }


      }
      //console.log(arr);



      state.ListBxDeal = payload.list;
      state.ListBxDealBucap = payload.list;
      state.ListFilter = payload.filter;

      state.ListSumm = payload.summ;
      state.ListDataFond.fond.vir = state.ListSumm.sumprod
      state.ListDataFond.fond.nvir = state.ListSumm.vipl;
      state.ListDataFond.fond.cb = state.ListSumm.ZAKYP;
      state.ListDataFond.fond.nal = state.ListSumm.nalog;
      state.ListDataFond.fond.nds = state.ListSumm.nds;
      state.ListDataFond.fond.marga = state.ListSumm.marjas;
      state.ListDataFond.fond.fzp = state.ListSumm.fondzp;
      state.ListDataFond.fond.fgp = state.ListSumm.fonds;
      state.ListDataFond.fond.fst = state.ListSumm.fonds;
      state.ListDataFond.fond.fkr = state.ListSumm.fondk;
      state.ListDataFond.fond.fmr = state.ListSumm.fondm;
      state.ListDataFond.fond.fpr = state.ListSumm.fondp;
      state.ListDataFond.fond.fdv = state.ListSumm.fondd;

      state.ListSumm.fondzp -= arr.fzp;
      state.ListSumm.fondp -= arr.fp;
      state.ListSumm.fonds -= arr.fst;
      state.ListSumm.fondk -= arr.fk;
      state.ListSumm.fondm -= arr.fm;
      state.ListSumm.fondd -= arr.fd;
      state.ListSumm.fondp1 -= arr.fps;




    },
  },


  actions: {
    saveUserCompanyMeneger({ commit }, payload) {
      commit("onLoad", true);
      //console.log(payload);
      return new Promise((resolve, reject) => {
        Axios.post("/api/v1/company/meneger/add", {
          zn: payload,
        })
          .then((res) => {
            //console.log(res.data);
            commit("onLoad", false);
            commit("updateMeneger", res.data.message);
            resolve(true);
          })
          .catch(() => {
            console.log("error");
            reject(false);
          });
      });
    },
    getSpecialistInfoId({ commit }, payload) {
      commit("onLoad", true);
      //console.log(payload);
      return new Promise((resolve, reject) => {
        Axios.post("/api/v1/company/specialist/get/id", {
          id: payload,
        })
          .then((res) => {
            //console.log(res.data);
            commit("onLoad", false);
            resolve(res.data.message[0]);
          })
          .catch(() => {
            console.log("error");
            reject([]);
          });
      });
    },


    
    getNotificationUser({ commit }) {
      Axios.post("/api/v1/company/Special/warning")
        .then((res) => {
          //console.log('notify');
          //console.log(res);
          commit("updateListNotification", res.data.message);
        })
        .catch(() => {
          console.log("error");
        });
    },

    getAnswerRequest({ commit }) {
      Axios.post("/api/v1/request/get")
        .then((res) => {
          console.log('requestr');
          console.log(res);
          commit("updateListAnswer", res.data.message);
        })
        .catch(() => {
          console.log("error");
        });
    },

setRequestListActive({commit},e)

{ 
  commit("onLoad",true),
Axios.post("/api/v1/request/set",{id:e})
.then(e=>{
  commit("onLoad",false),
  commit("updateListAnswer",e.data.message)})
  .catch(()=>{
    console.log("error")
  })
},

    getRestoreUser({ commit }, payload) {
      commit("onLoad", true);
      return new Promise((resolve, reject) => {
        Axios.post("/api/v1/company/get/restore", payload)
          .then((res) => {
            commit("onLoad", false);
            resolve(res.data.message);
          })
          .catch(() => {
            console.log("error");
            reject("error");
          });
      });
    },

    getFondList({ commit }) {
      commit("onLoad", true);
      Axios.post("/api/v1/handbk/fonds/get")
        .then((res) => {
          commit("onLoad", false);
          //console.log('fonds');
          //console.log(res);
          commit("updateFondList", res.data.message);
        })
        .catch(() => {
          console.log("error");
        });
    },

    setTransactionFonds({ commit }, payload) {
      commit("onLoad", true);
      Axios.post("/api/v1/handbk/transaction/set", {
        zn: payload,
      })
        .then((res) => {
          commit("onLoad", false);
          //console.log('transaction');
          //console.log(res);
          commit("updateTransactionList", res.data.message);
          commit("summTransactionFonds");
        })
        .catch(() => {
          console.log("error");
        });
    },

    DelTransactionFonds({ commit }, payload) {
      commit("onLoad", true);
      Axios.post("/api/v1/handbk/transaction/del", {
        zn: payload,
      })
        .then((res) => {
          commit("onLoad", false);
          //console.log('transaction delete');
          //console.log(res);
          commit("updateTransactionList", res.data.message);
          commit("summTransactionFonds");
        })
        .catch(() => {
          console.log("error");
        });
    },

    FindTransactionFonds({ commit }, payload) {
      commit("UpdateFindTransactionFonds", payload);
      commit("summTransactionFonds");
    },

    getTransactionFonds({ commit }) {
      commit("onLoad", true);
      Axios.post("/api/v1/handbk/transaction/get")
        .then((res) => {
          commit("onLoad", false);
          //console.log('transaction');
          //console.log(res);
          commit("updateTransactionList", res.data.message);
          commit("summTransactionFonds");
        })
        .catch(() => {
          console.log("error");
        });
    },


    getCustomerList({ commit }) {
      Axios.post("/api/v1/company/customer/list")
        .then((res) => {
          //console.log('customer');
          //console.log(res);
          commit("updateListCustomer", res.data.message);
        })
        .catch(() => {
          console.log("error");
        });
    },

    getValuteList({ commit }) {
      Axios.post("/api/v1/handbk/valute/get")
        .then((res) => {
          //console.log('valute');
          //console.log(res);
          commit("updateListValute", res.data.message);
        })
        .catch(() => {
          console.log("error");
        });
    },

    getStatusProjectList({ commit }) {
      Axios.post("/api/v1/handbk/statusproject/get")
        .then((res) => {
          //console.log('statusProject');
          //console.log(res);
          commit("updateListSatusProject", res.data.message);
        })
        .catch(() => {
          console.log("error");
        });
    },

    setTransactionFondsHistory({ commit }, payload) {
      Axios.post("/api/v1/handbk/transactionhistory/get", {
        zn: payload,
      })
        .then((res) => {
          //console.log('get history tranzaction');
          //console.log(res);
          commit("updateListTransactionHistory", res.data.message);
        })
        .catch(() => {
          console.log("error");
        });
    },
    FindBxDeal({ commit }, payload) {
      commit("UpdateFindBxDeal", payload);
    },

    FindBxDealFonds({ commit }, payload) {
      let t = {
        dts: payload.dts,
        dtn: payload.dte,
        fond: '',
      }
      commit("UpdateFindTransactionFonds", t);
      commit("summTransactionFonds");

      commit("UpdateFindBxDealFonds", payload)

    },


    async getBxDeal({ commit }) {
      commit("onLoad", true);
      await Axios.get("/api/v1/report/get")
        .then((res) => {
          //console.log('statusReport');
          //console.log(res);
          commit("onLoad", false);
          commit("UpdateBxDeal", res.data.message);
        })
        .catch((r) => {
          console.log("error");
          console.log(r);
        });
    },

    async getBxDealFonds({ commit }) {
      commit("onLoad", true);
      await Axios.get("/api/v1/report/get")
        .then((res) => {
          //console.log('statusReport');
          //console.log(res);
          commit("onLoad", false);
          commit("UpdateBxDealFonds", res.data.message);
        })
        .catch((r) => {
          console.log("error");
          console.log(r);
        });
    },



    async getBxDealFind({ commit }, payload) {
      commit("onLoad", true);
      await Axios.get("/api/v1/report/get/find", {
        zn: payload
      })
        .then((res) => {
          //console.log('statusReport');
          //console.log(res);
          commit("onLoad", false);
          commit("UpdateBxDeal", res.data.message);
        })
        .catch((r) => {
          console.log("error");
          console.log(r);
        });
    },
    ClearPersonCV({ commit }) {
      commit("clearSpecialist");
    },
    getListSpecProject({ commit }, payload) {
      commit("onLoad", true);
      return new Promise((resolve, reject) => {
        Axios.post("/api/v1/company/special/project/get/list", {
          zn: payload
        })
          .then((res) => {
            //console.log('specialListProject');
            //console.log(res);
            commit("onLoad", false);
            //commit("updateListProject", res.data.message);
            resolve(res.data.message);
          })
          .catch((rs) => {
            //console.log('special');
            console.log(rs);
            reject([]);
          });
      });
    },

    updateProjectSpecWork({ commit }, payload) {
      commit("onLoad", true);
      return new Promise((resolve, reject) => {
        Axios.post("/api/v1/company/special/project/set/work", {
          zn: payload
        })
          .then((res) => {
            //console.log('specialListProject');
            console.log(res);
            commit("onLoad", false);
            //commit("updateListProject", res.data.message);
            resolve(true);
          })
          .catch((rs) => {
            //console.log('special');
            console.log(rs);
            reject(false);
          });
      });
    },

    setRequestCustomer({ commit }, payload) {
      commit("onLoad", true);
      return new Promise((resolve, reject) => {
        Axios.post("/api/v1/admin/request/set", {
          zn: payload
        })
          .then((res) => {
            console.log('specialListProject');
            console.log(res);
            commit("onLoad", false);
            //commit("updateListProject", res.data.message);
            resolve(res.data.message.dt);
          })
          .catch((rs) => {
            //console.log('special');
            console.log(rs);
            reject(false);
          });
      });
    },

    setRequestListSpec({commit}, payload){
commit("onLoad", true);
      return new Promise((resolve, reject) => {
        Axios.post("/api/v1/admin/request/spec/set", {
          zn: payload
        })
          .then((res) => {
            console.log('specialListProject');
            console.log(res);
            commit("onLoad", false);
            //commit("updateListProject", res.data.message);
            resolve(res.data.message);
          })
          .catch((rs) => {
            //console.log('special');
            console.log(rs);
            reject(false);
          });
      });
    },

     setRequestListSpecUpdate({commit}, payload){
commit("onLoad", true);
      return new Promise((resolve, reject) => {
        Axios.post("/api/v1/admin/request/specup/set", {
          zn: payload
        })
          .then((res) => {
            console.log('specialListProjectup');
            console.log(res);
            commit("onLoad", false);
            //commit("updateListProject", res.data.message);
            resolve(res.data.message);
          })
          .catch((rs) => {
            //console.log('special');
            console.log(rs);
            reject(false);
          });
      });
    },


    getListSpecProjectAll({ commit }, payload) {
      commit("onLoad", true);
      return new Promise((resolve, reject) => {
        Axios.post("/api/v1/customer/special/project/get/list", {
          zn: payload
        })
          .then((res) => {
            //console.log('specialListProject');
            //console.log(res);
            commit("onLoad", false);
            //commit("updateListProject", res.data.message);
            resolve(res.data.message);
          })
          .catch((rs) => {
            //console.log('special');
            console.log(rs);
            reject([]);
          });
      });
    },

    getInfoRequstSalesID({ commit }, payload) {
      commit("onLoad", true);
    return new Promise((resolve, reject) => {
        Axios.post("/api/v1/requst/get/id", {
          zn: payload
        })
          .then((res) => {
            console.log(res);
            commit("onLoad", false);
            resolve(res.data.message);
          })
          .catch((rs) => {
            console.log(rs);
            reject([]);
          });
      });
    },

    getSpecialisеCV({commit}){
       commit("onLoad", true);
      Axios.post("/api/v1/company/specialist/CV")
        .then((res) => {
          console.log(res);
          commit("onLoad", false);
          commit("updateCVList", res.data.message[0]);
        })
        .catch(() => {
          console.log("error");
        });
    },

getMenegerCompany({ commit, state }) {
      commit("onLoad", true);
      if (state.ListUserMeneger.length > 0) {
        commit("onLoad", false);
      }
      Axios.post("/api/v1/company/meneger/get")
        .then((res) => {
          //console.log(res);
          commit("onLoad", false);
          commit("updateMeneger", res.data.message);
        })
        .catch(() => {
          console.log("error");
        });
    },

    getListPersonSpecAction({ commit }) {
      commit("onLoad", true);
      Axios.post("/api/v1/company/specialist/get")
        .then((res) => {
          //console.log('special');
          //console.log(res);
          commit("onLoad", false);
          commit("updateCompanySpecialist", res.data.message);
        })
        .catch((rs) => {
          //console.log('special');
          console.log(rs);
        });
    },

    getListCompanySkill({ commit }) {
      commit("onLoad", true);
      Axios.post("/api/v1/company/skill/get")
        .then((res) => {
          console.log('company dsdd');
          console.log(res);
          commit("onLoad", false);
          commit("updateCompanySkillList", res.data.message);
        })
        .catch((rs) => {
          //console.log('special');
          console.log(rs);
        });
    },



    setNewStatusAdmin({ commit }, payload) {
      commit("onLoad", true);
      Axios.post("/api/v1/company/specialist/set/status/admin", {
        zn: payload
      })
        .then((res) => {
          //console.log('special status');
          //console.log(res);
          commit("onLoad", false);
          commit("updateCompanySpecialist", res.data.message);
        })
        .catch((rs) => {
          //console.log('special');
          console.log(rs);
        });
    },
    onDeleteUser({ commit }, payload) {
      commit("onLoad", true);
      Axios.post("/api/v1/company/specialist/del", {
        zn: payload
      })
        .then((res) => {
          commit("onLoad", false);
          //console.log('delete');
          console.log(res);
        })
        .catch((rs) => {
          //console.log('special');
          console.log(rs);
        });
    },
    saveStatus({ commit }, payload) {
      commit("onLoad", true);
      return new Promise((resolve, reject) => {
        Axios.post("/api/v1/company/specialist/set/status", {
          zn: payload
        })
          .then((res) => {
            //console.log('specialStatus');
            //console.log(res);
            commit("onLoad", false);
            commit("updateCompanySpecialist", res.data.message);
            resolve(true);
          })
          .catch((rs) => {
            //console.log('special');
            console.log(rs);
            reject(false);
          });
      });
    },
    setProject({ commit }, payload) {
      commit("onLoad", true);
      return new Promise((resolve, reject) => {
        Axios.post("/api/v1/company/project/set", {
          zn: payload
        })
          .then((res) => {
            //console.log('project');
            //console.log(res);
            commit("onLoad", false);
            commit("updateListProject", res.data.message);
            commit("cleatDataProject");
            resolve(true);
          })
          .catch((rs) => {
            //console.log('special');
            console.log(rs);
            reject(false);
          });
      });
    },
    getProjectList({ commit }) {
      commit("onLoad", true);
      Axios.post("/api/v1/company/project/get")
        .then((res) => {
          //console.log('project list');
          //console.log(res);
          commit("onLoad", false);
          commit("updateListProject", res.data.message);
        })
        .catch((rs) => {
          //console.log('special');
          console.log(rs);
        });
    },
    sortProjectList({ commit }, payload) {
      commit("onLoad", true);
      commit("sortListProject", payload);
      commit("onLoad", false);
    },
    updateProject({ commit }, payload) {
      commit("onLoad", true);
      let t = true;
      //console.log(payload);
      return new Promise((resolve, reject) => {

        commit("onLoad", false);
        commit("updateProjectInfo", payload);
        if (t) {
          resolve(true);
        }
        else {
          reject(false);
        }



      });
    },
    getSpecInfoId({ commit }, payload) {
      commit("onLoad", true);
      //console.log("sdfsdf");
      return new Promise((resolve, reject) => {
        Axios.post("/api/v1/company/specialist/get/id", {
          id: payload
        })
          .then((res) => {
            //console.log('specialID');
            //console.log(res);
            commit("onLoad", false);
            commit("updateSpecialist", res.data.message[0]);
            commit("updateInfoSpec", res.data.message[0]);
            resolve(true);
          })
          .catch((rs) => {
            //console.log('special');
            console.log(rs);
            reject(false);
          });
      });
    },

    SaveProjectToSpec({ commit }, payload) {
      commit("onLoad", true);
      return new Promise((resolve, reject) => {
        Axios.post("/api/v1/company/specialist/set/project", {
          id: payload
        })
          .then((res) => {
            //console.log('specialID');
            //console.log(res);
            commit("updateListProject", res.data.message)
            commit("onLoad", false);

            resolve(true);
          })
          .catch((rs) => {
            //console.log('special');
            console.log(rs);
            reject(false);
          });
      });
    },

    getCVSpec({ commit }, payload) {
      commit("onLoad", true);
      Axios.post("/api/v1/cv/specialist/", payload)
        .then((res) => {
          //console.log(res);
          commit("onLoad", false);
          commit("updateInfoSpec", res.data.message);
        })
        .catch((rs) => {
          console.log(rs);
        });
    },

    onSeachSpecial({ commit }, payload) {
      commit("onLoad", true);
      Axios.post("/api/v1/company/specialist/seach", payload)
        .then((res) => {
          console.log("find");
          console.log(res);
          commit("onLoad", false);
          commit("updateCompanySpecialist", res.data.message);
          commit("updateMainSpecialist", res.data.message);
        })
        .catch((rs) => {
          console.log(rs);
        });
    },

    onSeachCompany({ commit }, payload) {
      commit("onLoad", true);
      Axios.post("/api/v1/company/skills/seach", payload)
        .then((res) => {
          console.log("find company");
          console.log(res);
          commit("onLoad", false);
          commit("updateCompanySkillList", res.data.message);
        })
        .catch((rs) => {
          console.log(rs);
        });
    },

    SentBot({ commit }, payload) {
      commit("onLoad", true);
      Axios.post("/api/v1/account/bot", payload)
        .then((res) => {
          console.log(res);
          commit("onLoad", false);
          alert('Действие сделано! Ксюха молодец!')
        })
        .catch((rs) => {
          console.log(rs);
        });
    },

    getRequestClient({ commit }, payload) {
      commit("onLoad", true);
      Axios.post("/api/v1/admin/request", payload)
        .then((res) => {
          console.log(res);
          commit("onLoad", false);
          commit("updateListRequest", res.data.message)
        })
        .catch((rs) => {
          console.log(rs);
        });
    },

    getCustomer({ commit }, payload) {
      commit("onLoad", true);
      Axios.post("/api/v1/Company/list/customer", payload)
        .then((res) => {
          console.log(res);
          commit("onLoad", false);
          commit("updateListCustomer1", res.data.message)
        })
        .catch((rs) => {
          console.log(rs);
        });
    },

    TelegramSendMS({ commit }, payload) {
      commit("onLoad", true);
      console.log(payload);
      Axios.post("/api/v1/admin/request/tg", {
        zn:payload
      })
        .then((res) => {
          console.log(res);
          commit("onLoad", false);
          alert('ok')
        })
        .catch((rs) => {
          console.log('error'+rs);
        });
    },

    getBotListFirm({ commit }, payload) {
      commit("onLoad", true);
      Axios.post("/api/v1/account/botListId", payload)
        .then((res) => {
          //console.log(res);
          commit("onLoad", false);
          commit("updateListFirmBot", res.data.message)
        })
        .catch((rs) => {
          console.log(rs);
        });
    },

    SentBotImg({ commit }, payload) {
      commit("onLoad", true);
      let formData = new FormData();
      formData.append('file', payload);
      return new Promise((resolve, reject) => {
        Axios.post("/api/v1/account/botImage", formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then((res) => {
            //console.log('данные');
            //console.log(res.data);
            commit("onLoad", false);
            resolve(res.data.message)
          })
          .catch(() => {
            console.log("error");
            reject([]);
          });
      });
    },

    setSpecialInfo({ commit }, payload) {
      //console.log(payload.lname);
      commit("onLoad", true);
      return new Promise((resolve, reject) => {
        Axios.post("/api/v1/company/specialist/set/info", {
          payload
        })
          .then((res) => {
            //console.log(res);
            commit("onLoad", false);
            commit("updateMeneger", res.data.message);
            commit("clearSpecialist");
            resolve(true);
          })
          .catch(() => {
            console.log("error");
            reject(false);
          });
      });
    },

    saveSpec({ commit }, payload) {
      commit("onLoad", true);
      //console.log(payload);
      Axios.post("/api/v1/company/specialist/set", payload)
        .then((res) => {
          //console.log(res);

          commit("onLoad", false);
          commit("updateCompanySpecialist", res.data.message);
        })
        .catch((rs) => {
          console.log(rs);
        });

    },

    clearSpecialInfo({ commit }) {
      commit("clearSpecialist");
    },

    delMenegerCompany({ commit }, payload) {
      commit("onLoad", true);
      //console.log(payload);
      return new Promise((resolve, reject) => {
        Axios.post("/api/v1/company/meneger/del", {
          payload
        })
          .then((res) => {
            //console.log(res);
            commit("onLoad", false);
            commit("updateMeneger", res.data.message);
            resolve(true);
          })
          .catch(() => {
            console.log("error");
            reject(false);
          });
      });
    },

    getListDataFirm({ commit }) {
      commit("onLoad", true);
      return new Promise((resolve, reject) => {
        Axios.post("/api/v1/firm/list", {
          id: localStorage.getItem("b-hire-user-fio"),
        })
          .then((res) => {
            console.log('данные');
            console.log(res.data);
            commit("onLoad", false);
            resolve(res.data.message)
          })
          .catch(() => {
            console.log("error");
            reject([]);
          });
      });
    },

    saveUserLogo({ commit }, payload) {
      commit("onLoad", true);
      let formData = new FormData();
      formData.append('file', payload);
      return new Promise((resolve, reject) => {
        Axios.post("/api/v1/firm/upload/img", formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then((res) => {
            //console.log('данные');
            //console.log(res.data);
            commit("onLoad", false);
            resolve(res.data.message)
          })
          .catch(() => {
            console.log("error");
            reject([]);
          });
      });
    },

    setLoadFileCV({ commit }, payload) {
      commit("onLoad", true);
      let formData = new FormData();
      formData.append('file', payload);
      Axios.post("/api/v1/company/specialist/fileCV", formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((res) => {
          commit("onLoad", false);
          //console.log(res)
          commit("updateCompanySpecialistFile", res.data.message);
        })
        .catch((rs) => {
          console.log(rs);
        });
    },

    saveUserCompany({ commit }, payload) {
      commit("onLoad", true);
      return new Promise((resolve, reject) => {
        Axios.post("/api/v1/firm/company/user/save", {
          zn: payload
        })
          .then((res) => {
            //console.log(res.data);
            localStorage.setItem("b-hire-user-ddf", res.data.message[0].dds);
            alert('Данные сохранены!');
            //console.log(res.data.message[0].dds)
            resolve(res.data.message[0].dds)
            commit("onLoad", false);
          })
          .catch(() => {
            console.log("error");
            alert('Данные не сохранены!');
            reject(0);
          });
      });

    },

  },


})