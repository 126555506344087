<template>
    <v-container>
      <v-col>
        <h1 class="display-2 font-weight-bold mb-3">Логирование регистрации пользователей</h1>
      </v-col>
      <div style="margin-top: 10px">
      <v-card>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Поиск"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="desserts"
          :items-per-page="10"
          :search="search"
          class="elevation-1"
          
        >

        </v-data-table>
      </v-card>
    </div>
    </v-container>
  </template>
  
  <script>
  export default {
    data: () => ({
        search: '',
        headers: [
      {
        text: "id",
        value: "id",
      },
      { text: "Дата создания", value: "date_create" },
      { text: "ид фирма", value: "id_client" },
      { text: "фирма", value: "nameFirm" },
      { text: "входные данные", value: "info" },      
      { text: "результат", value: "result" },
      { text: "Коментарии", value: "commet" },
    ],
  }),
  name: "logreg",
  mounted() {
    console.log("dd");
    this.$store.dispatch("getListLogRegister");
  },
  methods: {
    
    
  },
  computed: {
    desserts(){
      return this.$store.getters.ListRegLog;
    },
    
    },
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped></style>
  