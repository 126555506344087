import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index.js"
import Home from "../components/HelloWorld.vue";
import Registr from "../components/Registartion.vue"
import Registr1 from "../components/Registartion1.vue"
import Login from "../components/Login.vue"
import Cabinet from "../components/cabinet/Cabinet.vue"
import Firms from "../components/cabinet/Firms.vue"
import Account from "../components/cabinet/Account.vue"
import Setting from "../components/cabinet/Setting.vue"
import Person from "../components/cabinet/Person.vue"
//import Main from "../components/Main.vue"
import Faq from "../components/Faq.vue"
import Skills from "../components/admin/Skills.vue"
import Country from "../components/admin/Country.vue"
import AdmSpec from "../components/admin/AdmSpec.vue"
import Role from "../components/admin/Role.vue"
import Level from "../components/admin/Level.vue"
import Lang from "../components/admin/Lang.vue"
import Company from "../components/admin/Company.vue"
import Active from "../components/cabinet/Active.vue"
import RulesSite from "../components/info/RulesSite.vue"
import RulesPers from "../components/info/RulesPerson.vue"
import RulesEdit from "../components/admin/Rules.vue"
import Meneger from "../components/cabinet/Meneger.vue"
import Spec from "../components/Spec.vue"
import Sync from "../components/admin/Sync.vue"
import SettingAdm from "../components/admin/Setting.vue"
import ProjectCustomer from "../components/customer/Project.vue"
import AddProjectCustomer from "../components/customer/AddProject.vue"
import ProjectAdmin from "../components/admin/project/Project.vue"
import AddProjectAdmin from "../components/admin/project/AddProject.vue"
import SupplierProject from "../components/cabinet/Project.vue"
import CVForms from "../components/CV.vue"
import Deal from "../components/admin/report/Dealbx.vue"
import Fonds from "../components/admin/report/Fonds.vue"
import Dds from "../components/admin/report/Dds.vue"
import Logreg from "../components/admin/report/Logreg.vue"
import Restore from "../components/Restore.vue"
import TypeOrg from "../components/admin/setting/TypeOrg.vue"
import TypeInn from "../components/admin/setting/TypeInn.vue"
import Quiz from "../components/Quiz.vue"
import Interview from "../components/admin/Interview.vue"
import InterviewUser from "../components/cabinet/Interview.vue"
import Bot from "../components/admin/bot/Bot.vue"
import Profile from "../components/admin/Profile.vue"
import Calendar from "../components/admin/Calendar.vue"
import Deals from "../components/deals/Deals.vue"
import Request from "../components/admin/Request.vue"
import RequestID from "../components/admin/RequestID.vue"
import AnswerRequest from "../components/admin/AnswerReuest.vue"
import CVPerson from "../components/cabinet/CVPerson.vue"
import ListCompany from "../components/admin/ListCompany.vue"
import SendSetting  from "../components/admin/SendSetting.vue"
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "главная",
    component: Login,
  },
  {
    path: "/quiz",
    name: "опрос",
    component: Quiz,
  },
  {
    path: "/cv/:id",
    name: "CVForms",
    component: CVForms,
  },

    {
    path: "/login",
    name: "Вход",
    component: Login,
  },

  {
    path: "/restore",
    name: "Восстановление пароля",
    component: Restore,
  },

  {
    path: "/faq",
    name: "faq",
    component: Faq,
  },

  {
    path: "/spec",
    name: "spec",
    component: Spec,
  },
  
  {
    path: "/register",
    name: "Регистрация",
    component: Registr,
  },
    {
    path: "/register1",
    name: "Регистрация",
    component: Registr1,
  },
  {
    path: "/home",
    name: "домой",
    component: Home,
  },
  {
    path: "/data/:id",
    name: "active",
    component: Active,
  },
  {
    path: "/cabinet",
    name: "кабинет",
    component: Cabinet,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/firm",
    name: "компания",
    component: Firms,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/admin/interview",
    name: "интервью",
    component: Interview,
    meta: {
      requiresAuth: true
    }
  },

    {
    path: "/cabinet/interview",
    name: "интервью",
    component: InterviewUser,
    meta: {
      requiresAuth: true
    }
  },

   {
    path: "/admin/firm",
    name: "интервью",
    component: ListCompany,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/cabinet/CV",
    name: "CV",
    component: CVPerson,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/account",
    name: "аккаунт",
    component: Account,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/adm/setting",
    name: "настройки",
    component: SettingAdm,
    meta: {
      requiresAuth: true
    }
  },
{
    path: "/adm/profile",
    name: "профиль",
    component: Profile,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/adm/calendar",
    name: "календарь",
    component: Calendar,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/setting",
    name: "поддержка",
    component: Setting,
  },
  {
    path: "/person",
    name: "Обслуживание",
    component: Person,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/skills",
    name: "навыки",
    component: Skills,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/country",
    name: "страны",
    component: Country,
    meta: {
      requiresAuth: true
    }
  },  
  {
    path: "/admspec",
    name: "адм спец",
    component: AdmSpec,
    meta: {
      requiresAuth: true
    }
  },   
  {
    path: "/admin/setting/typeorg",
    name: "адм спец",
    component: TypeOrg,
    meta: {
      requiresAuth: true
    }
  },  
  {
    path: "/admin/setting/typeinn",
    name: "type inn",
    component: TypeInn,
    meta: {
      requiresAuth: true
    }
  },  
  {
    path: "/admin/bots",
    name: "type inn",
    component: Bot,
    meta: {
      requiresAuth: true
    }
  },  
  {
    path: "/Role",
    name: "роли",
    component: Role,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/level",
    name: "уровни",
    component: Level,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/lang",
    name: "Язык",
    component: Lang,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/company",
    name: "Компании",
    component: Company,
    meta: {
      requiresAuth: true
    }
  },  
  {
    path: "/rules/edit",
    name: "rulesEdit",
    component: RulesEdit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/admin/synchronization",
    name: "sync",
    component: Sync,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/admin/deal",
    name: "Deal",
    component: Deal,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/admin/deals",
    name: "Deals",
    component: Deals,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/admin/dds",
    name: "Dds",
    component: Dds,
    meta: {
      requiresAuth: true
    }
  },
{
    path: "/admin/fonds",
    name: "Fonds",
    component: Fonds,
    meta: {
      requiresAuth: true
    }
  },

{
    path: "/admin/project",
    name: "ProjectAdmin",
    component: ProjectAdmin,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/admin/project",
    name: "ProjectAdmin",
    component: ProjectAdmin,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/admin/project/add",
    name: "AddProjectAdmin",
    component: AddProjectAdmin,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/supplier/project",
    name: "SupplierProject",
    component: SupplierProject,
    meta: {
      requiresAuth: true
    }
  },
  
  {
    path: "/info/rules/site",
    name: "rulesSite",
    component: RulesSite,
  },
  {
    path: "/info/rules/peson",
    name: "rulesPers",
    component: RulesPers,
  },  
  {
    path: "/meneger",
    name: "Meneger",
    component: Meneger,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/project",
    name: "ProjectCustomer",
    component: ProjectCustomer,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/admin/log/reg",
    name: "logreg",
    component: Logreg,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/project/add",
    name: "AddProjectCustomer",
    component: AddProjectCustomer,
    meta: {
      requiresAuth: true
    }
  },
   {
    path: "/admin/request/:id",
    name: "requestID",
    component: RequestID,
   
  },
  {
    path: "/admin/request",
    name: "request",
    component: Request,
    meta: {
      requiresAuth: true
    }
  },
  
{
    path: "/admin/requestanswer",
    name: "requestanswer",
    component: AnswerRequest,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "/admin/setting/send",
    name: "SendSetting",
    component: SendSetting,
    meta: {
      requiresAuth: true
    }
  },
  
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.getAuthClient) {
      localStorage.setItem("b-hire-user-link", to.path);
      console.log(to.path);
      next()
      return
    }
    next('/login') 
  } else {
    next() 
  }
})

export default router;
