<template>
    <div style="margin: auto;">
        <loader v-if="load" object="#ff9633" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40"
            objectbg="#999793" opacity="80" name="dots"></loader>
        <v-row>
            <v-col cols="12">
                <h2>Работа с ботом</h2>
            </v-col>
        </v-row>
        <v-row style="margin: auto; width: 800px;">
            <v-col cols="12">
                <v-autocomplete :items="list" v-model="dt.id" dense chips multiple label="пользователи"></v-autocomplete>
            </v-col>
            <v-col cols="12">
                <v-checkbox v-model="checkbox" label="Выбрать всех" @change ="Alert('ddd')"></v-checkbox>
            </v-col>

            <v-col cols="12">
                <v-textarea name="input-7-1" label="Текст сообщения" hint="Введите текс сообщения"
                    v-model="dt.text"></v-textarea>

            </v-col>
            <v-col cols="12">
                <v-file-input reg="file" accept="image/png, image/jpeg, image/bmp" label="File input" id="file" ref="file"
                    v-on:change="handleFileUpload()" v-model="file"></v-file-input>
            </v-col>
            <v-col cols="12">

                <v-img max-width="200" :src="dt.foto" style="margin: auto;"></v-img>
            </v-col>
            <v-col cols="12">
                <v-btn @click="onPost">Запустить</v-btn>
            </v-col>
        </v-row>

    </div>
</template>
  
<script>
export default {
    name: "deal",
    data: () => ({
        dt: {
            img: "",
            foto: "",
            text: "",
            id: [],
        },
        checkbox: false,
        file: '',
    }),
    computed: {
        list() {
            return this.$store.getters.getListFirmBot;
        },
        load() {
            return this.$store.getters.getOnLoad;
        },
    },
    mounted() {
        this.$store.dispatch("getBotListFirm", this.dt);
    },
    methods: {
        onPost() {
            console.log(this.dt);
            if(this.checkbox == true){
                this.dt.dt = [];
                for (let index = 0; index < this.list.length; index++) {
                    this.dt.id.push(this.list[index]);
                    
                }
            }
            this.$store.dispatch("SentBot", this.dt);
        },
        handleFileUpload() {
            console.log(this.file)
            this.$store.dispatch("SentBotImg", this.file).then(res => {
                this.dt.foto = 'https://humacl.x-or.ru/public/bot/' + res
                this.dt.img = res
            });
        }

    },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.infoPerson {
    text-align: left;
}
</style>
  