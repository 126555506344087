<template>
<v-container>
    <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="900px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Специалисты</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row v-for="(n, i) in ListSpec" :key="i">
              <v-col
                cols="12"
                sm="6"
                md="5"
              >
              {{n.FIO}}({{n.nameFirm}}) 
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="3"
              >
              <v-checkbox
              :value="Number(n.aswer)"
      label="Откликнулись"
      @change="updateSpec(n.id, 1)"
    ></v-checkbox>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="2"
              >
              <v-checkbox
      :value="Number(n.scrin)"
      label="Скрининг"
    ></v-checkbox>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="2"
              >
               <v-checkbox
      :value="Number(n.sobec)"
      label="Собес"
    ></v-checkbox>
              </v-col>
              
            </v-row>
            <v-row>
              <v-col cols="12">
                {{(dataSpec!=[]?dataSpec[0]['FIO']+"("+dataSpec[0]['nameFirm']+") стек: "+dataSpec[0]['Main_skills']:'')}}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" md="6" sm="6" xs="6">
            <v-text-field  label="ID битрикс" outlined type="number"
              hide-details="auto" dense chips v-model="idbxSpec" ></v-text-field>
              
          </v-col>
          <v-col cols="6" md="2" sm="6" xs="6">
            <v-btn
            color="warning darken-1"            
            @click="findSpec()"
            >
            поиск
          </v-btn>
          </v-col>
          <v-col cols="6" md="4" sm="6" xs="6">
            <v-btn
            color="warning darken-1"            
            @click="dialog = false"          >
            Добавить
          </v-btn>
          </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
         
          
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>

    <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">Отлики по запросам</h1>
    </v-col>
    <v-col>
        <v-row>
            <v-card>
                <v-card-title>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Поиск" single-line hide-details></v-text-field>
                </v-card-title>
                <v-data-table :headers="headers" :items="country" :items-per-page="10" :search="search" class="elevation-1">
                  <template v-slot:item.Location="{ item }">
                        {{item.Location.substring(0, 10)}}
                    </template>  
                  <template v-slot:item.id_mess="{ item }">
                        <v-btn :href="'https://t.me/itrequests/'+item.id_mess" target="_blank" class="btn_read" title="Перейти в телеграм" style="margin-top:2px; margin-bottom: 3px">
                            Перейти
                        </v-btn>
                        <v-select :items='["Аналитика (businnes/system)","Разработка (frontend/backend/fullstack)","1с","Тестирование (qa/aqa)","Администрирование (data base/devops)","Менеджмент (project/product)","Нетиповой стек"]' v-model="item.categor" @change="read(item.id)" label="Группа"></v-select>
                     
                        <v-btn @click="openSpec(item.id_bx)" class="btn_read" title="отобразить список спецов" style="margin-top:2px; margin-bottom: 3px">
                            специалисты
                        </v-btn>
                    </template>
                    <template v-slot:item.activ="{ item }">
                      <v-select :items="['Закрыт', 'Активен']" v-model="item.actStr" @change="read(item.id)" label="Статус"></v-select>
                        
                    </template>
                </v-data-table>
            </v-card>
        </v-row>

    </v-col>
</v-container>
</template>

<script>
export default {
    data: () => ({
      search:'',
       dialog: false,
       ListSpec: [],
       dataSpec: "",
      id: 0,
      idbxSpec: 0,
        headers: [{
                text: "id",
                value: "id_bx",
            },
            {
                text: "Менеджер",
                value: "fio"
            },
            {
                text: "Инфо о проекте",
                value: "lvl"
            },
            {
                text: "Локация",
                value: "Location"
            },
            {
                text: "Роль",
                value: "title"
            },
            {
                text: "Уровень",
                value: "level"
            },
            {
                text: "Рейт",
                value: "rate"
            },
            {
                text: "Работа с запросами",
                value: "id_mess"
            },
            {
                text: "Дата Создания",
                value: "dateCreate"
            },
            {
                text: "Фирма",
                value: "firms"
            },
            {
                text: "Статус",
                value: "activ"
            },
        ],
    }),
    name: "answerRequest",
    mounted() {
        this.$store.dispatch("getAnswerRequest");
    },
    methods: {
      read(t){
        this.$store.dispatch("setRequestListActive", t);
        console.log(t)
      },
      openSpec(t){
        this.dialog = true;
        this.$store.dispatch("setRequestListSpec", t).then(res =>{
            this.ListSpec = res;
        });
      },
      updateSpec(t, n){
        let p = {
            id: t,
            et: n,
        }
        this.$store.dispatch("setRequestListSpecUpdate", p).then(res =>{
            this.ListSpec = res;
        });
      },
      findSpec(){
        console.log(this.idbxSpec)
        this.$store.dispatch("findSpecIDbx", this.idbxSpec).then(res =>{
            this.dataSpec = res;
        });
      }
    },
    computed: {
        country() {
            return this.$store.getters.ListAnswer;
        },

    },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped></style>
