<template>
    <v-container>
        <v-row>
            <!-- <v-col cols="12" md="2">
                <v-img height="100" max-width="125" :src="'https://humacl.x-or.ru/public/images/'+idCompany.logo" style="margin: auto;"></v-img>
            </v-col> -->
            <v-col cols="12" md="12">
                <v-row>
                    <v-col cols="12" md="2"><b>Имя</b></v-col>
                    <v-col cols="12" md="2">{{ dataSpecialist.FIO }}</v-col>
                    <v-col cols="12" md="2"><b>Роль:</b></v-col>
                    <v-col cols="12" md="2">{{ dataSpecialist.Category }}</v-col>
                    <v-col cols="12" md="2"><b>Локация</b></v-col>
                    <v-col cols="12" md="2">{{ dataSpecialist.Country }}</v-col>
                    <v-col cols="12" md="2"><b>Уровень английского</b></v-col>
                    <v-col cols="12" md="2">{{ dataSpecialist.English }}</v-col>
                    <v-col cols="12" md="2"><b>Уровень</b></v-col>
                    <v-col cols="12" md="2">{{ dataSpecialist.Level }}</v-col>
                    <v-col cols="12" md="2"><b>Стоимость часа(руб.)</b></v-col>
                    <v-col cols="12" md="2">{{ dataSpecialist.zakyp_rub }}</v-col>
                    <v-col cols="12" md="2"><b>Часовой пояс</b></v-col>
                    <v-col cols="12" md="2">{{ dataSpecialist.Timezone }}</v-col>
                    <v-col cols="12" md="2"><b>Комерческий опыт</b></v-col>
                    <v-col cols="12" md="2">{{ dataSpecialist.Years_of_Experience }}</v-col>
                    <v-col cols="12" md="2"><b>Фирма</b></v-col>
                    <v-col cols="12" md="2">{{ dataSpecialist.Company }}</v-col>
                    <v-col cols="12" md="2"><b>о специалисте</b></v-col>
                    <v-col cols="12" md="10">{{ dataSpecialist.about }}</v-col>
                    <v-col cols="12">
                        <fieldset>
                            <legend>Опыт</legend>
                            <v-col cols="12">
                                <v-row>
                                    <v-col cols="12" md="4"><b>Навык</b></v-col>
                                    <v-col cols="12" md="4"><b>Уровень владения</b></v-col>
                                    <v-col cols="12" md="4"><b>Срок</b></v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-row v-for="(n, i) in dataSpecialist.levelSkill" :key="i">
                                    <v-col cols="12" md="4">{{ n.name }}</v-col>
                                    <v-col cols="12" md="4">{{ n.type }}</v-col>
                                    <v-col cols="12" md="4">{{ n.time }}</v-col>
                                </v-row>
                            </v-col>
                        </fieldset>
                    </v-col>
                    <v-col cols="12">
                        <fieldset>
                            <legend>Опыт в комерческой разработке</legend>
                            <v-col cols="12">
                                <v-row v-for="(n, i) in dataSpecialist.ind" :key="i">
                                    <v-col cols="12" md="12"><b>Проект: {{ n.name }}</b></v-col>
                                    <v-col cols="12" md="2"><b>Индустрия</b></v-col>
                                    <v-col cols="12" md="2">{{ n.ind }}</v-col>
                                    <v-col cols="12" md="2"><b>Срок исполнения</b></v-col>
                                    <v-col cols="12" md="2">{{ n.date_start }} - {{ n.date_ent }}</v-col>
                                    <v-col cols="12" md="2"><b>Роль в проекте</b></v-col>
                                    <v-col cols="12" md="2">{{ n.role }}</v-col>
                                    <v-col cols="12" md="2"><b>Навыки</b></v-col>
                                    <v-col cols="12" md="10">{{ n.skill }}</v-col>
                                    <v-col cols="12" md="2"><b>Описание проекта</b></v-col>
                                    <v-col cols="12" md="10">{{ n.description }}</v-col>
                                    <v-col cols="12" md="12">&nbsp;</v-col>
                                    
                                </v-row>
                            </v-col>
                        </fieldset>
                    </v-col>
                    <v-col cols="12">
                        <fieldset>
                            <legend>Образование</legend>
                            <v-col cols="12">
                                <v-row>
                                    <v-col cols="12" md="4"><b>Уровень образования</b></v-col>
                                    <v-col cols="12" md="4"><b>Название учебного заведения</b></v-col>
                                    <v-col cols="12" md="4"><b>Срок обучения</b></v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-row v-for="(n, i) in dataSpecialist.educInfo" :key="i">
                                    <v-col cols="12" md="4">{{ n.type }}</v-col>
                                    <v-col cols="12" md="4">{{ n.name }}</v-col>
                                    <v-col cols="12" md="4">{{ n.dts }} - {{ n.dte }}</v-col>
                                </v-row>
                            </v-col>
                        </fieldset>
                    </v-col>
                </v-row>
            </v-col>

            <!-- <v-col cols="12"> 
                <v-row>
                    <v-col cols="12"  md="2"><b>Адрес</b></v-col>
                    <v-col cols="12"  md="10">{{ idCompany.adress }}</v-col>
                </v-row>
            </v-col>
            <v-col cols="12">
                <v-row>
                    <v-col cols="12"  md="3"><b>Бренд</b></v-col>
                    <v-col cols="12"  md="3">{{ idCompany.brand }}</v-col>
                    <v-col cols="12"  md="3"><b>Сайт</b></v-col>
                    <v-col cols="12"  md="3">{{ idCompany.site }}</v-col>
                    <v-col cols="12"  md="2"><b>Кол-во в штате</b></v-col>
                    <v-col cols="12"  md="2">{{ idCompany.countWorkes }}</v-col>
                    <v-col cols="12"  md="2"><b>Цена мидл</b></v-col>
                    <v-col cols="12"  md="2">{{ idCompany.RateMiddle }}</v-col>
                    <v-col cols="12"  md="2"><b>Цена сеньер</b></v-col>
                    <v-col cols="12"  md="2">{{ idCompany.RateSenior }}</v-col>
                </v-row>
            </v-col>
            <fieldset>
                <legend>Банк</legend>
            <v-col cols="12">
                <v-row>
                    <v-col cols="12"  md="2"><b>Банк</b></v-col>
                    <v-col cols="12"  md="10">{{ idCompany.bank }}</v-col>
                    <v-col cols="12"  md="2"><b>Бик банка</b></v-col>
                    <v-col cols="12"  md="2">{{ idCompany.bik }}</v-col>
                    <v-col cols="12"  md="1"><b>Кор</b></v-col>
                    <v-col cols="12"  md="3">{{ idCompany.korChet }}</v-col>
                    <v-col cols="12"  md="1"><b>Рас</b></v-col>
                    <v-col cols="12"  md="3">{{ idCompany.rasChet }}</v-col>
                </v-row>
            </v-col>
        </fieldset>
            <fieldset>
                <legend>Ответы на анкету</legend>
            <v-col cols="12">
                <v-row v-for="(n,i) in idCompany.answer" :key="i">
                    <v-col cols="12"  md="6"><b>{{ n.textQuestion }}</b></v-col>
                    <v-col cols="12"  md="6">{{ n.answer}}</v-col>
                </v-row>
            </v-col>
        </fieldset> -->
        </v-row>
        <!-- {{ this.dataSpecialist }} -->
    </v-container>
</template>
<script>

export default {
    name: 'resumeSpecialist',
    props:
        ['dataSpecialist'],
    data: () => ({
        dt: [],
    }),

}
</script>
