<template>
  <v-container>
    <v-row>
      <v-col cols="4" class="mb-4"></v-col>
      <v-col cols="4" class="mb-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field v-model="email" :rules="emailRules" label="E-mail" required></v-text-field>
          <v-text-field v-model="fio" label="ФИО" required></v-text-field>
          <v-text-field v-model="teleg" label="Telegram" required type="text"></v-text-field>
          <v-text-field v-model="pass" label="пароль*" required type="password"></v-text-field>
          <v-text-field v-model="passNext" label="повторите пароль*" required type="password"></v-text-field>
          <v-btn color="success" :disabled="!valid" @click="validate">
            Сохранить
          </v-btn>
        </v-form>
      </v-col>
      <v-col cols="4" class="mb-4"> </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    name: "",
    inn: "",
    fio: "",
    pass: "",
    teleg: "",
    passNext: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    select: null,
    items: ["Item 1", "Item 2", "Item 3", "Item 4"],
    checkbox: false,
  }),
  name: "account",
  methods: {
    validate() {
      this.$refs.form.validate();
      let t = {
        eml: this.email,
        ps: this.pass,
        ps1: this.passNext,
        fio: this.fio,
        tel: this.teleg,
      }
      this.$store.dispatch("setDataAccUser", t).then((res) => {
        this.email = res;
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  mounted() {
    this.$store.dispatch("getMailUser").then((res) => {
      this.email = res.eml;
      this.fio = res.fio;
      this.teleg = res.tel;
    });
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
