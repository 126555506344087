<template>
  <div style="margin-right: 10px">
    <element-loading :active="onLoadData" spinner="spinner" color="#FF6700"/>
    <v-row align="center" justify="space-around" style="margin-bottom: 10px">
    <v-btn color="primary"  @click="onNewData1"> Синхронизировать фирмы </v-btn>
    <v-btn color="primary"  @click="onNewData"> Синхронизировать специалистов </v-btn>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Поиск"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="err"
          :items-per-page="10"
          :search="search"
          class="elevation-1"
        >
        </v-data-table>
      </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    search: "",
    
    data: {
      id: 0,
      name: "",
    },
    headers: [
      { text: "id", value: "id" },
      { text: "Имя", value: "name" },
      { text: "Ошибка", value: "err" },
    ],
  }),
  computed: {
    desserts() {
      return this.$store.getters.getListRoleEdit;
    },
    onLoadData() {
      return this.$store.getters.getOnLoad;
    },
    err() {
      return this.$store.getters.ListError;
    }
  },
  mounted() {
    this.$store.dispatch("getRoleList");
  },
  name: "Role",
  methods: {
    onNewData() {
      this.$store.dispatch("synchronizationSpec").then(res => {        
          alert(res);
      });
      
    },
    onNewData1() {
      this.$store.dispatch("synchronizationCom").then(res => {        
          alert(res);
      });
    },

    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.infoPerson {
  text-align: left;
}
</style>
