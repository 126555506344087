<template>
  <div style="margin-right: 10px">
    <v-dialog v-model="dialog" width="1000px">
      <v-card>
        <v-card-title> </v-card-title>
        <v-card-text>
          <v-row>
             
          </v-row>

          

        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="#D9D9D9" @click="dialog = false"> Отменить </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="#FFE816" @click="saveData"> Сохранить </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row align="center" justify="space-around" style="margin-bottom: 10px">
      <v-col cols="12" md="4">Работа с интервью</v-col>
      <!-- <v-col cols="12" md="2">
        <v-btn color="#FFE816" @click="mod = 0">Список</v-btn>
      </v-col> -->
      <v-col cols="12" md="2">
        <v-btn color="#FFE816" @click="mod = 1">Запросы</v-btn>
      </v-col>
      <v-col cols="12" md="2">
        <v-btn color="#FFE816" @click="mod = 2">Направить CV</v-btn>
      </v-col>
      <v-col cols="12" md="2">
        <v-btn color="#FFE816" @click="mod = 3">Назначить интервью</v-btn>
      </v-col>


    </v-row>
    <div style="margin-top: 10px" v-if="mod == 2">
      <request-spec></request-spec>
    </div>
    <div style="margin-top: 10px" v-if="mod == 1">
      <new-request></new-request>
    </div>
    <div style="margin-top: 10px" v-if="mod == 3">
      <deal-inteview></deal-inteview>
    </div>
    <div style="margin-top: 10px" v-if="mod == 0">
      <v-col cols="12" md="12">
        <v-card>
          <v-card-title>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Поиск" single-line
              hide-details></v-text-field>
          </v-card-title>
          <v-data-table :headers="headers" :items="listInterview" :items-per-page="10" :search="search"
            class="elevation-1" @click:row="readData">
            <template v-slot:item.result="{ item }">
              <span>{{ ((item.result == -1) ? 'Ожидает ответа' : (item.result == 0 ? 'отказ' : 'успех')) }}</span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>

    </div>
  </div>
</template>
  
<script>
import RequestSpec from "@/components/admin/interview/Request.vue"
import NewRequest from "@/components/admin/interview/NewRequest.vue"
import DealInteview from "@/components/admin/interview/Deal.vue"


export default {
  data: () => ({
    dialog: false,
    mod: 0,
    search: "",
    
    headers: [
      { text: "id", value: "id" },
      { text: "Проект", value: "name_project" },
      { text: "Компания", value: "officeName" },
      { text: "Бренд", value: "brand" },
      { text: "ид спеца", value: "id_bx" },
      { text: "имя спеца", value: "firstname" },
      { text: "результат", value: "result" },
    ],
    
  }),
  components: {
    RequestSpec, NewRequest, DealInteview
  },
  computed: {
    spec() {
      return this.$store.getters.listSpecIntr;
    },
    proj() {
      return this.$store.getters.listCompanyIntr;
    },
    listInterview() {
      return this.$store.getters.listInterview;
    }
  },
  mounted() {
    this.$store.dispatch("getListInfoCompanySpecAdm");
    this.$store.dispatch("getListInterviewCompany");
  },
  name: "RulesEdit",
  methods: {
    addNewSlot(t) {
      console.log(this.zakaz.slots);
      if (this.zakaz.slots[t].char == '+') {
        this.zakaz.slots[t].char = '-'
        let r = {
          dt: '',
          char: '+'
        }
        this.zakaz.slots.push(r);
      } else {
        this.zakaz.slots.splice(t, 1);
        this.zakaz.slots[t - 1].char = '-'
      }

    },

    saveData() {
      if (this.zakaz.spec != '' && this.zakaz.slots[0].dt != '') {
        this.$store.dispatch('saveInterviewAdm', this.zakaz);
        this.dialog = false;

      }
    },
    readData(e) {
      console.log(e);
      this.zakaz.slots = [

      ];
      this.zakaz.id = e.id;
      this.zakaz.spec = e.fff;

      this.zakaz.proj = e.name_project;
      let r = e.slots.split(",");
      console.log(r);
      for (let index = 0; index < r.length; index++) {
        if ((r.length - 1) == index) {
          this.zakaz.slots.push({
            dt: r[index],
            char: '-'
          })
        } else {
          this.zakaz.slots.push({
            dt: r[index],
            char: '+'
          })
        }


      }
      this.dialog = true;
    },
  }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.infoPerson {
  text-align: left;
}
</style>
  