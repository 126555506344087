<template>
  <div style="margin-right: 10px">
    <v-dialog v-model="dialogInfoCompany" width="1200px">
      <v-card>
        <v-card-title> </v-card-title>
        <v-card-text>
          <info-company :idCompany="idCompany" ></info-company>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" text @click="dialogInfoCompany=false"> Отменить </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="badModer(idCompany.id)"> Отказать </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="GoodModer(idCompany.id)"> Модерация </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <v-dialog v-model="dialog" width="1000px">
      <v-card>
        <v-card-title> </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
              <h2>Редактирование</h2>
            </v-col>
            <v-col cols="12">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  v-model="data.name"
                  :rules="nameRules"
                  label="Название"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="data.email"
                  :rules="emailRules"
                  label="Email"
                  required
                ></v-text-field>
                <h1 class="display-2 font-weight-bold mb-3">О Компании</h1>
                <v-select
                  :items="typeCompany"
                  label="Тип компании"
                  v-model="data.type_company"
                ></v-select>
                <v-select
                  :items="CountPeople"
                  label="Количество сотрудников"
                  v-model="data.count_people"
                ></v-select>
                <v-select
                  :items="country"
                  label="Страна"
                  v-model="data.country"
                ></v-select>
                <h1 class="display-2 font-weight-bold mb-3">Реквизиты</h1>
                <v-select
                  :items="FormOrg"
                  label="Форма организации"
                  v-model="data.form_org"
                ></v-select>
                <v-text-field
                  label="Официальное наименование"
                  v-model="data.office_name"
                  required
                ></v-text-field>
                <v-text-field
                  label="Должность руководителя"
                  v-model="data.position_d"
                  required
                ></v-text-field>
                <v-text-field
                  label="На основании чего действует руководитель"
                  v-model="data.base_dir"
                  required
                ></v-text-field>
                <!-- <v-select
                  :items="colPerson"
                  label="Должность руководителя"
                  v-model="data.position_d"
                ></v-select>
                <v-select
                  :items="colPerson"
                  label="На основании чего действует руководитель"
                  v-model="data.base_dir"
                ></v-select> -->
                <v-text-field
                  v-model="data.fio_dir"
                  label="ФИО руководителя"
                  required
                ></v-text-field>
                <v-text-field
                  :rules="nameInn"
                  v-model="data.inn"
                  @change="onGetFirm"
                  type="number"
                  label="ИНН"
                  required
                ></v-text-field>
                <v-text-field
                  v-if="data.country=='russian federation'"
                  v-model="data.ogrn"
                  :rules="nameORGN"
                  type="number"
                  label="ОГРН / ОГРНИП (для РФ)"
                  required
                ></v-text-field>
                <v-text-field
                  v-if="data.country=='russian federation'"
                  v-model="data.kpp"
                  :rules="nameKPP"
                  label="КПП (для РФ)"
                  required
                ></v-text-field>
                <h1 class="display-2 font-weight-bold mb-3">
                  Платежные данные
                </h1>
                <v-text-field
                  v-model="data.name_bank"
                  label="Банк корреспондент"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="data.bik_bank"
                  :rules="nameBik"
                  type="number"
                  label="БИК банка корреспондента"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="data.kor_chet_bank"
                  :rules="nameKor"
                  type="number"
                  label="Корреспондентский счет"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="data.chet_bank_org"
                  :rules="nameKor"
                  type="number"
                  label="Счет в банке корреспонденте"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="data.number_bank_org"
                  :rules="nameKor"
                  type="number"
                  label="Номер счет в банке своей страны"
                  required
                ></v-text-field>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" text @click="onClose"> Отменить </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="onGenPass">
            Генерация пароля
          </v-btn>
          <v-btn color="primary" v-if="data.block==0" text @click="onBlockFirm"> Блокировать </v-btn>
          <v-btn color="primary" v-else text @click="onBlockFirm"> Разблокировать </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveData" :disabled="!valid">
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row align="center" justify="space-around" style="margin-bottom: 10px">
      <v-btn color="primary" text @click="onNewData"> Добавить </v-btn>
    </v-row>
    <div style="margin-top: 10px">
      <v-card>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Поиск"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="desserts"
          :items-per-page="10"
          :search="search"
          class="elevation-1"
         
        >
        <template v-slot:item.action="{ item }">
         <!--<v-btn v-if="item.activ==0" class="btn_read" @click="ActivateCompany(item.id)" title="Активация" style="margin-top:2px; margin-bottom: 3px">
            Активация
          </v-btn>
          <v-btn v-else class="btn_read" @click="ActivateCompany(item.id)" title="Активация" style="margin-top:2px; margin-bottom: 3px">
            Деактивация
          </v-btn><br /><br />
            <v-btn v-if="item.bl == 0 || item.bl == NULL" class="btn_read" @click="BlockCompany(item.id)" :key="item.act" title="Блокировка" style="margin-bottom: 3px">
            Блокировка
          </v-btn>
          <v-btn v-else class="btn_read" @click="BlockCompany(item.id)" :key="item.act" title="Блокировка" style="margin-bottom: 3px">
            Деблокировка
          </v-btn>
          <br /><br />
          <v-btn class="btn_read" @click="OpenDialog(item.act)" :key="item.act" disabled title="Удалить" style="margin-bottom: 3px">
            Удалить
          </v-btn> -->
          <v-menu offset-y>       
            <template v-slot:activator="{ on, attrs }">
              <v-btn                
                v-bind="attrs"
                v-on="on"
              >
                Действия
              </v-btn>
            </template>     
             
            
            <v-list>
              <v-list-item
                @click="Moder(item.id)"
                :key="item.id + 0"
              >
                <v-list-item-title>Анкета</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="item.activ==0"
                @click="ActivateCompany(item.id)"
                :key="item.id + 1"
              >
                <v-list-item-title>Активация</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-else
                @click="ActivateCompany(item.id)"
                :key="item.id + 2"
              >
                <v-list-item-title>Деактивация</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="item.bl == 0 || item.bl == NULL"
                @click="BlockCompany(item.id)"
                :key="item.id + 3"
              >
                <v-list-item-title>Блокировка</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-else
                @click="BlockCompany(item.id)"
                :key="item.id + 4"
              >
                <v-list-item-title>Деблокировка</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="GoodModer(item.id)"
                :key="item.id + 5"
              >
                <v-list-item-title>Модерация успех</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="badModer(item.id)"
                :key="item.id + 6"
              >
                <v-list-item-title
                >Модерация провал</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import InfoCompany from "@/components/admin/dialog/InfoCompany.vue"
export default {
  data: () => ({
    dialog: false,
    search: "",
    idCompany: 0,
    dialogInfoCompany: false, 
    valid: true,
    items: [
        { title: 'Анкета' },
        { title: 'Активация' },
        { title: 'Блокировка' },
        { title: 'Модерация пройдена' },
        { title: 'Модерация провалена' },
        { title: 'Удалить' },
      ],
    nameRules: [
      (v) => !!v || "Название не может быть пустым",
      (v) => (v && v.length >= 1) || "Name must be less than 10 characters",
    ],
    nameKPP: [
      (v) => !!v || "Кол-во цифр не может быть пустым если вы ип поставьте 0",
      (v) => (v && (v.length == 9 || v==0)) || "КПП должно быть 9 цифр или 0",
    ],
    nameORGN: [
      (v) => !!v || "Кол-во цифр не может быть пустым",
      (v) => (v && (v.length == 13 || v.length==15)) || "ОГРН или ОГРИП должно быть 13 цифр или 15",
    ],
    nameBik: [
      (v) => !!v || "Кол-во цифр не может быть пустым",
      (v) => (v && v.length == 9) || "БИК должно быть 9 цифр",      
    ],
    nameInn: [
      (v) => !!v || "Кол-во цифр не может быть пустым",
      (v) => (v && v.length == 12) || "инн должно быть 12 цифр",  
    ],
    nameKor: [
      (v) => !!v || "Кол-во цифр не может быть пустым",
      (v) => (v && v.length == 20) || "Счет должен быть 20 цифр",  
    ],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    data: {
      id: 0,
      name: "",
      email: "",
      type_company: "",
      count_people: "",
      country: "",
      form_org: "",
      office_name: "",
      position_d: "",
      base_dir: "",
      fio_dir: "",
      inn: "",
      ogrn: "",
      kpp: "",
      name_bank: "",
      bik_bank: "",
      kor_chet_bank: "",
      chet_bank_org: "",
      number_bank_org: "",
      block: 0,
    },
    headers: [
      { text: "id", value: "id" },
      { text: "Название", value: "nameFirm" },
      { text: "Инн", value: "inn" },
      { text: "Телеграм", value: "telegram" },
      { text: "Страна", value: "nameCountry" },
      { text: "Тип организации", value: "namePerson" },
      { text: "Директор", value: "FIOR" },
      { text: "Действия", value: "action" },
    ],
  }),
  components: {
    InfoCompany
  }, 
  computed: {
    desserts() {
      return this.$store.getters.getListCompanyEdit;
    },
    country() {
      return this.$store.getters.getListLocation;
    },
    typeCompany() {
      return this.$store.getters.getListTypeCompany;
    },
    CountPeople() {
      return this.$store.getters.getListPeopleCount;
    },
    FormOrg() {
      return this.$store.getters.getListFormOrg;
    },
  },
  mounted() {
    this.$store.dispatch("getCompanyList");
    this.$store.dispatch("getLocationList");
    this.$store.dispatch("getTypeCompanyList");
    this.$store.dispatch("getCountPeopleCompany");
    this.$store.dispatch("getFormOrganization");
  },
  name: "Role",
  methods: {
    readData(e) {
      this.data.id = e.id;
      this.data.name = e.nameFirm;
      this.data.email = e.email;
      this.data.inn = e.inn;
      this.data.type_company = e.namePerson;
      this.data.count_people = e.nameCount;
      this.data.country = e.nameCountry;
      this.data.form_org = e.nameFormOrg;
      this.data.office_name = e.officeName;
      this.data.position_d = e.positionR;
      this.data.base_dir = e.baseR;
      this.data.fio_dir = e.FIOR;
      this.data.ogrn = e.OGRN;
      this.data.kpp = e.KPP;
      this.data.name_bank = e.bank;
      this.data.bik_bank = e.bik;
      this.data.kor_chet_bank = e.korChet;
      this.data.chet_bank_org = e.rasChet;
      this.data.number_bank_org = e.numberChetBank;
      this.data.block = e.block;
      this.dialog = true;
    },
    Moder(t){
      console.log('sdfsdf')
      this.$store.dispatch("getCompanyInfoId", t).then(res =>{
            console.log('dddd');
            console.log(res);
            this.idCompany = res;
        })
      this.dialogInfoCompany = true;
    },
    ActivateCompany(t) {
      this.$store.dispatch("setActivateCompany", t);
      this.$store.dispatch("getCompanyList");
    },
    BlockCompany(t) {
      this.$store.dispatch("setBlockCompany", t);
      this.$store.dispatch("getCompanyList");
    },
    onGetFirm() {
      var url =
        "https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party";
      var token = "326c0f7d4ced81baa0d95a35cceda204bc255423";
      var query = this.data.inn;

      var options = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Token " + token,
        },
        body: JSON.stringify({ query: query }),
      };

      fetch(url, options)
        .then((response) => response.text())
        .then((result) => {
          let r = JSON.parse(result);
          console.log(JSON.parse(result));
          this.name = r.suggestions[0].value;
          this.email = r.suggestions[0].data.emails;
        })
        .catch((error) => console.log("error", error));
    },
    saveData() {
      this.$store.dispatch("saveCompany", this.data);
      this.dialog = false;
    },
    onClose() {
      this.dialog = false;
    },
    onBlockFirm() {
      this.$store.dispatch("blockCompany", this.data.id);
      this.dialog = false;
    },
    onGenPass() {
      this.$store.dispatch("genPassCompany", this.data.id);
    },
    onNewData() {
      this.data.id = 0;
      this.data.name = "";
      this.data.type_company = "";
      this.data.count_people = "";
      this.data.country = "";
      this.data.form_org = "";
      this.data.office_name = "";
      this.data.position_d = "";
      this.data.base_dir = "";
      this.data.fio_dir = "";
      this.data.inn = "";
      this.data.ogrn = "";
      this.data.kpp = "";
      this.data.name_bank = "";
      this.data.bik_bank = "";
      this.data.kor_chet_bank = "";
      this.data.chet_bank_org = "";
      this.data.number_bank_org = "";
      this.data.block = 0;
      this.dialog = true;
    },
    GoodModer(t) {
      this.$store.dispatch("goodModerCompanyID", t).then(res => {
        if(res) {
          alert("Фирме разрешение успешно выдано!")
          this.dialogInfoCompany = false;
        }
      })
      
    },
    badModer(t){
      let m = prompt("Укажите причину отказа!");
      console.log(m);
      if(m != "" && m != null){
        this.$store.dispatch("badModerCompanyID", {id:t, msg: m}).then(res => {
        if(res) {
          alert("Фирма Послана успешно!")
          this.dialogInfoCompany = false;
        }
      })
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.infoPerson {
  text-align: left;
}
</style>
