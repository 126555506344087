<template>
    <div style="margin-right: 10px">
   <v-dialog v-model="dialog" width="1000px">
      <v-card>
        <v-card-title>О проекте </v-card-title>
        <v-card-text>

        </v-card-text>
        <v-card-title>Предложения специалистов </v-card-title>
        <v-card-text>

          <v-row v-if="listSpec.length > 0">
            <v-col cols="12">
              <v-data-table
                :headers="headers_spec"
                :items="listSpec"
                :items-per-page="10"
                class="elevation-1"
              >     
              <template v-slot:item.m3="{ item }">
                <a :href="item.m3" class = "btn" target="_blank" rel="noopener noreferrer">Посмотреть</a>
              </template>         
               <template v-slot:item.m2="{ item }">
                <v-autocomplete
                    :items="['Предложение','Интервью', 'Тестовое задание', 'Принять', 'Отказать']"
                    v-model="item.m1"
                    outlined
                    dense
                    style=" margin-top:15px"
                    label="Действие"
                  ></v-autocomplete>
                </template>         
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" text @click="dialog=false"> отмена </v-btn>
          <v-spacer></v-spacer>
          
          <v-btn color="primary" @click="onSave()" text> Ок </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <v-row align="center" justify="space-around" style="margin-bottom: 10px">
      <v-btn to="/project/add"> ДОБАВИТЬ ПРОЕКТ + </v-btn>
    </v-row>
    <div style="margin-top: 10px">
      <v-card>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Поиск"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="listProj"
          :items-per-page="10"
          :search="search"
          class="elevation-1"
          
        >
         <template v-slot:item.act="{ item }">
          <v-btn class="btn_read" @click="getInfoProject(item)" title="редактировать специалиста" style="margin:2px;">
            Изменить
          </v-btn>
          <v-btn v-if="item.col>0" class="btn_read" @click="openZav(item.id)" title="заявки" style="margin:2px">
            заявки
          </v-btn>
        </template>
        </v-data-table>
      </v-card>
    </div>

  </div>
</template>

<script>
export default {
  data: () => ({
    search: '',
    listSpec: [],
    statusVl: [],
    idProj: 0,
    dialog:false,
    headers: [
      {
        text: "id",
        value: "id",
      },
      { text: "Дата создания", value: "dateCreate" },
      { text: "уровень", value: "lvl" },
      { text: "роль", value: "nameCategories" },
      { text: "О проекте", value: "abount" },
      { text: "Требования", value: "requirements" },
      { text: "Рейт", value: "rt" },
      { text: "Статус", value: "nameSatatus" },
      { text: "Заявок", value: "col" },
      { text: "", value: "act" },
    ],
    headers_spec: [
      {
        text: "Cпециалист",
        value: "m0",
      },
      {
        text: "CV",
        value: "m3",
      },
      { text: "Статус", value: "m1" },
      { text: "действие", value: "m2" },
    ],
    desserts: [],


  }),
  computed: {
    listProj() {
      return this.$store.getters.getListProject;
    }

  },
  name: "projectCustomer",
  mounted() {
    this.$store.dispatch("getProjectList");
    
  },
  methods: {
    getInfoProject(t){
       this.$store.dispatch("updateProject", t).then(res => {
            if(res) {
                //alert('не удалось сохранить проект!');
               this.$router.push('/project/add') 
            } else {
                alert('не удалось получить информацию о проекте!');
            }
        });
    },
  openZav(t){
    this.idProj = t;
    this.$store.dispatch("getListSpecProjectAll", t).then(res => {
            if(res) {
                //alert('не удалось сохранить проект!');
                this.listSpec = res;
               this.dialog=true
            } else {
                alert('не удалось получить информацию!');
            }
        });
  },
    onSave()
    {
      console.log(this.listSpec);
      let y = {
        dt: this.listSpec,
        id: this.idProj,
      }
      this.$store.dispatch("updateProjectSpecWork", y);      
      this.dialog = false;
    }
    
  },
};
</script>

<style scoped>

</style>