<template>
    <v-container>
        <v-row>
            
            <v-col cols=12 md="4">
                <v-btn>Заказчики</v-btn>
            </v-col>
            <v-col cols=12 md="4">
                <v-btn>Поставщики</v-btn>
            </v-col>
            <v-col cols=12 md="4">
                <v-btn>все</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
              <company />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import Company from '../../admin/Company.vue';
export default {
  data: () => ({
    valid: true,

   
   
  }),
  name: "client",
  mounted() {
    
  },
  components: {
    Company
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
   
  },
  computed: {
    onLoadData() {
      return this.$store.getters.getOnLoad;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>