<template>
  <v-container>
    <v-dialog v-model="com_fff">
      <v-card>
        <v-card-title>Опыт работы </v-card-title>
        <v-cart-text>

          <v-row style="margin:5px">
            <v-col cols="12" md="12">
              <v-text-field label="Название проекта*" v-model="default_mas.name" required></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field label="Описание проекта*" v-model="default_mas.description" required></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <div>Дата начала проекта*</div>
              <v-date-picker width="190" flat v-model="default_mas.date_start" locale="ru-ru" no-title scrollable
                type="month">
              </v-date-picker>
              <!-- <v-text-field label="Дата начала*" type="date" v-model="default_mas.date_start" required></v-text-field> -->
            </v-col>
            <v-col cols="12" md="6">
              <div>Дата окончания проекта*</div>
              <v-date-picker width="190" v-model="default_mas.date_ent" :max="getEndDate" locale="ru-RU" no-title
                scrollable type="month">
              </v-date-picker>
              <!-- <v-text-field label="" type="date" v-model="default_mas.date_ent" required></v-text-field> -->
            </v-col>
            <v-col cols="12" md="12">
              <v-autocomplete :items="categories" v-model="default_mas.role" label="Роль *"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="12">
              <v-autocomplete :items="skills" v-model="default_mas.skill" @change="addSkillDebug" dense chips small-chips persistent-hint
                label="Ключевые технологии*" hint='выберите те, на которых вы вы работатли на данном проекте'
                multiple></v-autocomplete>
            </v-col>
            <v-col cols="12" md="12">
              <v-autocomplete :items="industries" dense chips small-chips persistent-hint label="Индустрия*"
                v-model="default_mas.ind"></v-autocomplete>
            </v-col>
          </v-row>

        </v-cart-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" text @click="com_fff = false"> Отмена </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" v-if="default_mas.id != 0" text @click="delete_exp"> Удалить </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="save_educ"> Сохранить </v-btn>


        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="educ_fff">
      <v-card>
        <v-card-title>Образование </v-card-title>
        <v-cart-text>

          <v-row style="margin:5px">
            <v-col cols="12" md="12">
              <v-select :items="['Высшее', 'Среднее-специальное', 'Курсы', 'Семинары']" label="Образование *"
                v-model="edu.type" dense></v-select>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field label="Название*" type="text" required v-model="edu.name"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-date-picker width="190" v-model="edu.dts" locale="ru-ru" no-title scrollable type="month">
              </v-date-picker>
              <!-- <v-text-field label="Дата начала*" type="date" required v-model="edu.dts"></v-text-field> -->
            </v-col>
            <v-col cols="12" md="6">
              <v-date-picker width="190" v-model="edu.dte" :max="getEndDate" locale="ru-ru" no-title scrollable
                type="month">
              </v-date-picker>
              <!-- <v-text-field label="Даты окончания*" type="date" required v-model="edu.dte"></v-text-field> -->
            </v-col>

          </v-row>

        </v-cart-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" text @click="educ_fff = false"> Отмена </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" v-if="edu.id != 0" text @click="delete_education"> Удалить </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="save_edu"> Сохранить </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-form ref="form" lazy-validation v-model="validate">
      <v-container>
        <v-row>
          <v-col cols="12">
            <fieldset>
              <legend>Уникальные данные</legend>
              <v-row>
                <v-col cols="12" md="6">
                  <v-autocomplete :items="country" label="Гражданство" :rules="SelectRules" @change="findList"
                    v-model="spec.grag" required></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" v-if="spec.grag != ''">
                  <v-text-field :label="nameTax" type="number" :rules="InputRules"
                    v-model="spec.taxID" required></v-text-field>

                </v-col>
                <v-col cols="12" md="6" v-else>
                  <v-text-field label="Налоговый индентификатор*" v-model="spec.taxID" disabled type="number"
                    required></v-text-field>

                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  Данные о налоговом идентификаторе используются системой для предотвращения дублей и хранятся в
                  зашифрованном виде
                </v-col>
              </v-row>

            </fieldset>
          </v-col>
        </v-row>
        <v-row style="margin: 5px">
          <v-col cols="12">
            <p>Дорогой коллега, просим оформить резюме по образцу для максимального распознавания системой данных из резюме <a href="#">Образец CV</a> </p>
            <!-- <v-text-field
              label="Вставьте сюда ссылку на резюме на стороннем сервисе (гугл диск, работные сайты итд) (не обязательно)"
              hint="url ссылки " required v-model="spec.link"></v-text-field> -->
              <v-file-input label="Загрузите пожалуйста оригинал CV." accept="application/pdf,application/msword, .docx"
            placeholder="Загрузите оригинал CV" filled prepend-icon="mdi-file" id="file" ref="file" v-model="fileCV" @change="loadFile" ></v-file-input>
          </v-col>
        </v-row>

        <fieldset>

          <legend>Общая информация о специалисте</legend>
          <v-row style="margin: 5px">
            <v-col cols="12" md="4">
              <v-text-field v-model="spec.fname" label="Имя*" required :rules="InputRules"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="spec.lname" label="Фамилия (необязательно)"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="spec.tname" label="Отчество (необязательно)"></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-autocomplete v-model="spec.rolecat" :items="categories" label="Роль *"
                :rules="MassRules"></v-autocomplete>
            </v-col>


            <v-col cols="12" md="4">
              <v-autocomplete :items="levelSpec" label="Уровень(грейд)*" v-model="spec.level"
                :rules="SelectRules"></v-autocomplete>
            </v-col>


            <v-col cols="12" md="4">
              <v-autocomplete :items="location" label="Локация" v-model="spec.loc" :rules="SelectRules"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete :items="timeZone" label="Часовой пояс*" v-model="spec.zone"
                :rules="SelectRules"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field :counter="10" label="Коммерческий опыт(лет)*" type="number" v-model="spec.exp" required
                :rules="InputRules"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field :counter="10" label="Ставка руб/час*" type="number" v-model="spec.rater" required
                :rules="InputRules"></v-text-field>
            </v-col>
            <v-col cols="12" md="4" v-if="statusUser > 3">
              <v-text-field :counter="10" label="Cтоимость продажи*" type="number" v-model="spec.ratern" required
                :rules="InputRules"></v-text-field>
            </v-col>
            <v-col cols="12" md="4" v-if="statusUser > 3">
              <v-autocomplete :items="company" v-model="spec.company" label="Поставщик"
                :rules="SelectRules"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field label="Доступен c*" type="date" v-model="spec.availability" required
                :rules="InputRules"></v-text-field>
            </v-col>
            <v-col cols="12" md="8" class="infoPerson">
              <v-text-field label="О специалисте" hint="Краткая история успеха" required
                v-model="spec.about"></v-text-field>
            </v-col>
          </v-row>
        </fieldset>
        
        <fieldset>
          <legend>Описание комерческого опыта</legend>
          <v-row style="margin: 5px">
            

            <v-col cols="12" md="12" class="infoPerson">
              <v-alert text type="error" v-if="data_exp.length == 0 && validate != true">Данные не заполнены!</v-alert>
              <v-data-table :headers="headers" :items="data_exp" :items-per-page="5" class="elevation-1"
                @click:row="OpenDialogExp">

              </v-data-table>

            </v-col>
            <v-col cols="12" md="12" class="infoPerson">
              <v-btn @click="com_fff = true">Добавить опыт</v-btn>
            </v-col>
          </v-row>
        </fieldset>
        <fieldset>         
          <legend>Навыки</legend>
          <v-row style="margin: 5px">
            
            <v-col cols="12" md="12">
              <v-select :items="langLevel" label="Уровень английского *" v-model="spec.lang"
                :rules="SelectRules"></v-select>
            </v-col>


            <v-col cols="12" md="12">
              <v-autocomplete v-model="spec.skills" @change="onNewSkillLevel" :items="skills" dense chips small-chips
                :rules="MassRules" persistent-hint label="Ключевые технологии*"
                hint='выберите те, на которых вы специализируетесь и реально используете в работе. Если вы знаете какой-либо язык программирования, но не собираетесь на нем писать - не надо его указывать'
                multiple required></v-autocomplete>
            </v-col>
            <v-col cols="12" md="12"  style="display:none">
              <v-row v-if="spec.skills.length > 0">
                <v-col>
                  Укажите уровень владения технологией
                </v-col>
              </v-row>
              <v-row v-for="(n, i) in spec.skillLevel" :key='i'>
                <v-col cols="12" md="5">{{ n.name }}</v-col>
                <v-col cols="12" md="3" style="display: none;">
                  <v-autocomplete v-model="n.type" :items="['не указан', 'Junior', 'Middle', 'Senior']"
                    label="Уровень владения*"></v-autocomplete>
                  <!-- <v-radio-group v-model="n.radioGroui">
                    <v-row>
                      <v-col cols="12" md="3">
                        <v-radio label="не указан" :value="0"></v-radio>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-radio label="Junior" :value="1"></v-radio>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-radio label="Middle" :value="2"></v-radio>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-radio label="Senior" :value="3"></v-radio>
                      </v-col>
                    </v-row>

                  </v-radio-group> -->
                </v-col>
                <v-col cols="12" md="6">
                  <v-autocomplete v-model="n.time"
                    :items="['не указан', 'до года', '1 год', '2 года', '3 года', '4 года', '5 лет', '6 лет', '7 лет', '8 лет', '9 лет', '10 и более']"
                    label="кол-во лет*"></v-autocomplete>

                </v-col>
                <v-col cols="12" md="1">
                  <v-btn @click="onDelNav(i)">x</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>


        </fieldset>
        <fieldset>
          <legend>Образование</legend>
          <v-alert text type="error" v-if="data_edul.length == 0 && validate != true">Данные не заполнены!</v-alert>
          <v-row style="margin: 5px">
            <v-col cols="12" md="10" style="display: none;">
              <v-checkbox v-model="spec.eduUp" label="Есть высшее образование в сфере ИТ"></v-checkbox>
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col cols="12" md="12" class="infoPerson">
                  <v-data-table :headers="headers_edu" :items="data_edul" :items-per-page="5" class="elevation-1"
                    @click:row="open_education"></v-data-table>
                </v-col>

              </v-row>
            </v-col>
            <v-col cols="12" md="12" class="infoPerson">
              <v-btn @click="open_edu">Добавить образование</v-btn>
            </v-col>

          </v-row>
          <!-- <education-component></education-component> -->
        </fieldset>
        <v-checkbox label="Не размещать резюме в каталоге B-hire" v-model="spec.enb"></v-checkbox>
        <!-- <v-card-actions>
          <v-btn color="primary" text to="/cabinet"> Отменить </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveData"> Сохранить</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveData"> Удалить </v-btn>

        </v-card-actions> -->
      </v-container>
    </v-form>
    <v-fab-transition>
      <v-btn @click="$router.push('/cabinet')" fab fixed large bottom left class="v-btn--example"
        title="Вернуться к списку специалистов">
        Назад
      </v-btn>
    </v-fab-transition>
    <v-fab-transition>
      <v-btn @click="saveData" fab fixed large bottom right class="v-btn--example" title="сохранить специалиста">
        OK
      </v-btn>
    </v-fab-transition>
  </v-container>
</template>

<script>
// import EducationCcomponent from "@/components/input/education.vue"
// import Vue2Editor from "vue2-editor";
export default {
  // components: {
  //   'education-component':EducationCcomponent
  // },
  data: () => ({
    date: new Date(),
    nameTax: "",
    countTax: "12",
    com_fff: false,
    educ_fff: false,
    validate: false,
    g: false,
    fileCV: "",
    default_mas: {
      id: 0,
      name: "",
      description: "",
      date_start: "",
      date_ent: "",
      role: "",
      skill: [],
      ind: "",
    },
    edu: {
      id: 0,
      type: "",
      name: "",
      dts: "",
      dte: "",
    },
    SelectRules: [
      v => !!v || 'Должно быть выбрано значение',
    ],
    InputRules: [
      v => !!v || 'Должно быть выбрано значение',
    ],
    MassRules: [
      v => v.length != 0 || 'Данные должно быть выбрано хотя бы одно',
    ],

    data_edul: [

    ],
    data_exp: [

    ],
    headers: [
      {
        text: 'Название',
        value: 'name',
      },
      { text: 'Описание', value: 'description' },
      { text: 'дата начала', value: 'date_start' },
      { text: 'дата окончания', value: 'date_ent' },
      { text: 'роли', value: 'role' },
      { text: 'стек', value: 'skill' },
      { text: 'Индустрия', value: 'ind' },
    ],
    headers_edu: [
      {
        text: 'Образование',
        value: 'type',
      },
      { text: 'Название', value: 'name' },
      { text: 'дата начала', value: 'dts' },
      { text: 'дата окончания', value: 'dte' },
    ],

  }),
  // components: { Vue2Editor },
  name: "addspecial",
  methods: {
    onDelNav(t) {
      this.spec.skills.splice(t, 1);
      this.spec.skillLevel.splice(t, 1);
    },

    saveData() {
      console.log(this.spec.fname);
      this.g = true;
      this.$refs.form.validate();
      this.spec.ind.push(JSON.parse(JSON.stringify(this.data_exp)));
      console.log(this.data_edul);
      this.spec.educInfo.push(JSON.parse(JSON.stringify(this.data_edul)));
      if (this.spec.fname != "" && this.spec.level != "" && this.spec.loc != "" && this.spec.rolecat != "" && this.spec.level != "" && this.spec.zone != "" && this.spec.exp != "" && this.spec.rater != "" && this.spec.about != "" && this.spec.lang != "" && this.spec.skills.length != 0 && this.spec.ind.length != 0 && this.spec.educInfo.length != 0) {
        let t = JSON.parse(JSON.stringify(this.spec))
        console.log(t);
        this.$store.dispatch("setSpecialInfo", t).then((res) => {
          if (res) {
            console.log('save good');
            
            this.$router.push('/cabinet');
          } else {
            console.log("save bad");
          }
        })
      } else {
        alert("Не все поля заполнены!")
      }



    },
    findList() {
      for (let index = 0; index < this.ListTaxId.length; index++) {
        if (this.ListTaxId[index].location == this.spec.grag) {
          this.nameTax = this.ListTaxId[index].TaxId;
          this.countTax = this.ListTaxId[index].SizeTaxId;
        }

      }
    },
    loadFile(){
      console.log(this.fileCV);
      this.$store.dispatch("setLoadFileCV", this.fileCV);
    },
    onNewSkillLevel() {
      if (this.spec.skills.length < this.spec.skillLevel.length) {
        for (let index = 0; index < this.spec.skillLevel.length; index++) {
          if (!this.spec.skills.includes(this.spec.skillLevel[index].name)) {
            this.spec.skillLevel.splice(index, 1);
          }

        }
      } else {
        let t = {
          name: this.spec.skills[this.spec.skills.length - 1],
          type: "не указан",
          time: "не указан"
        }
        this.spec.skillLevel.push(t);
      }

    },
    addSkillDebug() {
      console.log("add skill")
      let t = this.default_mas.skill[this.default_mas.skill.length-1];
      console.log(t)
      let f = false;
      for (let index = 0; index < this.spec.skills.length; index++) {
        if(this.spec.skills[index] == t) {
          f = true;
          break;
        }        
      }
      if (f==false) {
        this.spec.skills.push(t);
        this.onNewSkillLevel();
      }
      console.log(this.spec.skills)
    },
    onClear() {
      this.$store.dispatch("clearSpecialInfo", this.spec)
      this.$router.push('/admspec')
    },
    save_educ() {
      console.log(this.default_mas);

      if (this.default_mas.name != "" && this.default_mas.description != "" && this.default_mas.date_start != "" && this.default_mas.role != "" && this.default_mas.skill.length != 0) {
        if (this.default_mas.id == 0) {
          this.default_mas.id = this.data_exp.length + 1;
          this.data_exp.push(JSON.parse(JSON.stringify(this.default_mas)));

        } else {
          for (let index = 0; index < this.data_exp.length; index++) {
            if (this.data_exp[index].id == this.default_mas.id) {
              this.data_exp[index].name = this.default_mas.name;
              this.data_exp[index].description = this.default_mas.description;
              this.data_exp[index].date_start = this.default_mas.date_start;
              this.data_exp[index].date_ent = this.default_mas.date_ent;
              this.data_exp[index].role = this.default_mas.role;
              this.data_exp[index].skill = this.default_mas.skill;
              this.data_exp[index].ind = this.default_mas.ind;
              break;
            }
          }
        }
        this.default_mas.id = 0;
        this.default_mas.name = "";
        this.default_mas.description = "";
        this.default_mas.date_start = "";
        this.default_mas.date_ent = "";
        this.default_mas.role = "";
        this.default_mas.skill = [];
        this.default_mas.ind = "";
        this.com_fff = false;
      } else {
        alert("Не все поля заполнены!")
      }

    },
    open_edu() {
      this.edu.id = 0;
      this.edu.type = "",
        this.edu.name = "",
        this.edu.dts = "",
        this.edu.dte = "",
        this.educ_fff = true;
    },
    save_edu() {
      console.log(this.data_edul);
      if (this.edu.name != "" && this.edu.dts != "" && this.edu.dte != "" && this.edu.type != "") {
        if (this.edu.id == 0) {
          this.edu.id = this.data_edul.length + 1;
          this.data_edul.push(JSON.parse(JSON.stringify(this.edu)));

        } else {
          for (let index = 0; index < this.data_edul.length; index++) {
            if (this.data_edul[index].id == this.edu.id) {
              this.data_edul[index].name = this.edu.name;
              this.data_edul[index].dts = this.edu.dts;
              this.data_edul[index].dte = this.edu.dte;
              this.data_edul[index].type = this.edu.type;
              break;
            }

          }
        }
        this.educ_fff = false;
      } else {
        alert("Не все поля заполнены!");
      }

    },
    OpenDialogExp(e) {
      console.log(e);
      this.default_mas.id = e.id;
      this.default_mas.name = e.name;
      this.default_mas.description = e.description;
      this.default_mas.date_start = e.date_start;
      this.default_mas.date_ent = e.date_ent;
      this.default_mas.role = e.role;
      this.default_mas.skill = e.skill;
      this.default_mas.ind = e.ind;
      this.com_fff = true;
    },
    delete_exp() {
      if (confirm("Вы уверены что хотите удалить?")) {
        for (let index = 0; index < this.data_exp.length; index++) {
          if (this.data_exp[index].id == this.default_mas.id) {
            this.data_exp.splice(index, 1);
            break;
          }
        }
        this.com_fff = false;
      }
    },
    open_education(e) {
      this.edu.id = e.id;
      this.edu.type = e.type,
        this.edu.name = e.name,
        this.edu.dts = e.dts,
        this.edu.dte = e.dte,
        this.educ_fff = true;
    },
    delete_education() {
      if (confirm("Вы уверены что хотите удалить?")) {
        for (let index = 0; index < this.data_edul.length; index++) {
          if (this.data_edul[index].id == this.edu.id) {
            this.data_edul.splice(index, 1);
            break;
          }
        }
        this.educ_fff = false;
      }
    },


    confirmLeave() {
      console.log('aaa');
      return window.confirm('Вы уверены что хотите уйти с данной страницы?')
    },

    confirmStayInDirtyForm() {
      console.log('aaddda');
      //f = false;
      const error = [];
      this.$refs.form.inputs.forEach(e => {
        if (e.errorBucket && e.errorBucket.length) {
          error.push(...e.errorBucket);
        }
      },
      );
      console.log(error);
      console.log(error.length);
      if (error.length != 11 && this.g == false) {

        return !window.confirm('Вы уверены что хотите уйти с данной страницы?')
      }

    },

    beforeWindowUnload(e) {
      console.log('aasssa');
      if (this.confirmStayInDirtyForm() && this.g == false) {
        // Cancel the event
        e.preventDefault()
        // Chrome requires returnValue to be set
        e.returnValue = ''
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    // If the form is dirty and the user did not confirm leave,
    // prevent losing unsaved changes by canceling navigation
    if (this.confirmStayInDirtyForm()) {
      next(false)
    } else {
      // Navigate to next view
      next()
    }
  },
  created() {
    window.addEventListener('beforeunload', this.beforeWindowUnload)
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.beforeWindowUnload)
  },
  computed: {
    country() {
      return this.$store.getters.getListLocation;
    },
    location() {
      return this.$store.getters.getListLocationAll;
    },
    spec() {
      return this.$store.getters.getSpecialId;
    },
    langLevel() {
      return this.$store.getters.getListLangLevel;
    },
    levelSpec() {
      return this.$store.getters.getListLevel;
    },
    timeZone() {
      return this.$store.getters.getTimeZone;
    },
    skills() {
      return this.$store.getters.getListSkills;
    },
    categories() {
      return this.$store.getters.getListCategories;
    },
    company() {
      return this.$store.getters.getListCompany;
    },
    industries() {
      return this.$store.getters.getListIndustries;
    },
    load() {
      return this.$store.getters.getOnLoad;
    },
    statusUser() {
      return this.$store.getters.getStatusUser;
    },
    ListTaxId() {
      return this.$store.getters.ListTaxId;
    },
    getEndDate() {
      var endDate = new Date(this.date.getFullYear(), this.date.getMonth(), 20);
      console.log("sss")
      console.log(endDate)
      return endDate.toISOString().slice(0, 10)
    }
  },
  mounted() {
    this.$store.dispatch("getLocationList");
    this.$store.dispatch("getSkillsList");
    this.$store.dispatch("getLangLevelList");
    this.$store.dispatch("getLevelList");
    this.$store.dispatch("getTimeZone");
    this.$store.dispatch("getCategories");
    this.$store.dispatch("getCompanyList");
    this.$store.dispatch("getIndustriesList");
    if(this.spec.id != 0){
      console.log('edu update')
      for (let index = 0; index < this.spec.educInfo.length; index++) {
        this.data_edul.push(this.spec.educInfo[index]);
        
      }
      for (let index = 0; index < this.spec.ind.length; index++) {
        this.data_exp.push(this.spec.ind[index]);
        
      }

      console.log(this.data_exp)
      
    }
    
  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
