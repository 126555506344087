<template>
  <div class="hello">
    <v-data-table
      :headers="headers"
      :items="desserts"
      :items-per-page="8"
      class="elevation-1"
    ></v-data-table>
  </div>
</template>

<script>
export default {
  data: () => ({
    headers: [
      {
        text: "Имя",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Категория", value: "calories" },
      { text: "Страна", value: "fat" },
      { text: "Английский", value: "carbs" },
      { text: "Уровень", value: "iron" },
      { text: "Скилы", value: "skills" },
      { text: "Стоимость", value: "prices" },
      { text: "Роль", value: "role" },
    ],
    desserts: [
      {
        name: "Максим",
        calories: "Development",
        fat: "Russian Federation",
        carbs: "A1 - beginner",
        iron: "Middle",
        skills: "PHP, Laravel, React.js, Javascript",
        prices: "1700",
        role: "Backend Developer",
      },
      {
        name: "Артём",
        calories: "Development",
        fat: "Russian Federation",
        carbs: "A1 - beginner",
        iron: "Middle",
        skills:
          "PHP, Yii, Linux, Git, Docker, Nginx, MySQL, PostgreSQL, Object Oriented Programming (OOP), JavaScript, Lamp, React.js, HTML, CSS, Node.js, Slimpress, JQuery",
        prices: "1300",
        role: "Fullstack Developer",
      },
      {
        name: "Алик",
        calories: "Development",
        fat: "Russian Federation",
        carbs: "B2 - upper intermediate",
        iron: "Senior",
        skills:
          "HTML5, Amazon S3, JavaScript, Bootstrap, PHP, Git, Sass, Gulp, AngularJS, React.js, JavaScript, Webpack, Redux, Backbone.js, ES6, BABEL, Ajax, REST, JavaScript, Jade, PUG, jQuery, Node.js, React.js, Yii",
        prices: "1480",
        role: "Frontend Developer",
      },
      {
        name: "Илья П.",
        calories: "Data Science & Analytics",
        fat: "Russian Federation",
        carbs: "B1 - intermediate",
        iron: "Senior",
        skills: "SQL, MS SQL Server, JavaScript, XML, Atlassian Jirasio",
        prices: "1400",
        role: "DevOps Engineer",
      },
      {
        name: "Анастасия",
        calories: "Development",
        fat: "Russian Federation",
        carbs: "A1 - beginner",
        iron: "Middle",
        skills:
          "PHP, Yii, Linux, Git, Docker, Nginx, MySQL, PostgreSQL, Object Oriented Programming (OOP), JavaScript, Lamp, React.js, HTML, CSS, Node.js, Slimpress, JQuery",
        prices: "1700",
        role: "System Analyst",
      },
      {
        name: "Михаил",
        calories: "Development",
        fat: "Russian Federation",
        carbs: "B1 - intermediate",
        iron: "Middle+",
        skills:
          "Node.js, SQL, JavaScript, SQL, MySQL, Git, Object Oriented Programming (OOP)  ",
        prices: "1200",
        role: "Backend Developer",
      },
      {
        name: "Иван К.",
        calories: "Development",
        fat: "Russian Federation",
        carbs: "A1 - beginner",
        iron: "Middle",
        skills:
          "PHP, Yii, Linux, Git, Docker, Nginx, MySQL, PostgreSQL, Object Oriented Programming (OOP), JavaScript, Lamp, React.js, HTML, CSS, Node.js, Slimpress, JQuery",
        prices: "1700",
        role: "Fullstack Developer",
      },
      {
        name: "Виктор М.",
        calories: "Development",
        fat: "Russian Federation",
        carbs: "A1 - beginner",
        iron: "Middle",
        skills:
          "PHP, Yii, Linux, Git, Docker, Nginx, MySQL, PostgreSQL, Object Oriented Programming (OOP), JavaScript, Lamp, React.js, HTML, CSS, Node.js, Slimpress, JQuery",
        prices: "1700",
        role: "Fullstack Developer",
      },
    ],
  }),
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
