import Vue from 'vue'
import Axios from "axios";
import Vuex from 'vuex'
//import Axios from 'axios';

Vue.use(Vuex)

export default ({
  state: {
    rulesSite: [],
    rulesPerson: [],
    ListMainSpec: [],
},
  getters: {
    getRulesSite(state){
        return state.rulesSite;
    },
    getRulesPerson(state){
        return state.rulesPerson;
    },
    getMainSpecialist(state) {
        return state.ListMainSpec;
    }
  },


  mutations: {
    updateRulesPerson(state, payload) {
        state.rulesPerson = payload;
    },
    updateRulesSite(state, payload) {
        state.rulesSite = payload;
    },
    updateMainSpecialist(state, payload) {
        state.ListMainSpec = payload;
    }
  },


  actions: {
    getDataRulesPerson({commit}){
        commit("onLoad", true);
        Axios.post("/api/v1/main/rules/person")
        .then((res) => {
            console.log(res);
            commit("onLoad", false);
            commit("updateRulesPerson", res.data.message[0]);

        })
        .catch(() => {
            console.log("error");
          });
    },
    getDataRulesSite({commit}){
        commit("onLoad", true);
        Axios.post("/api/v1/main/rules/site")
        .then((res) => {
            console.log(res);
            commit("onLoad", false);
            commit("updateRulesSite", res.data.message[0]);

        })
        .catch(() => {
            console.log("error");
          });
    },

    saveRules({commit}, payload){
        commit("onLoad", true);
        Axios.post("/api/v1/main/rules/save", {
            zn: payload
        })
        .then((res) => {
            console.log(res);
            commit("onLoad", false);
            if(res.data.message[0]['id'] == 2){
                commit("updateRulesSite", res.data.message[0]);
            } else {
                commit("updateRulesPerson", res.data.message[0]);
            }
            

        })
        .catch(() => {
            console.log("error");
          });
    },
    getListPersonSpecActionInf({commit}){
        commit("onLoad", true);
            Axios.post("/api/v1/main/specialist/get")
              .then((res) => {
                console.log('specialict');
                console.log(res);
                commit("onLoad", false);
                commit("updateMainSpecialist", res.data.message);
              })
              .catch((rs) => {
                console.log('special');
                console.log(rs);
              });
      },
  },
})