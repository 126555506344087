<template>
    <v-container>
        <v-row v-if="!visibleList">
            <v-col cols="12">
                <h1>Добавление запроса</h1>
                <v-row>
                    <v-col cols="12" md="12">
                        <v-autocomplete :items="[]" v-model="data.company" deletable-chips outlined hide-details="auto" dense chips small-chips
                            label="Компания"></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-autocomplete :items="[]" v-model="data.location" deletable-chips outlined hide-details="auto" dense chips small-chips
                            label="Локация"></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-autocomplete :items="[]" v-model="data.role" deletable-chips outlined hide-details="auto" dense chips small-chips
                            label="Роль"></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-autocomplete :items="[]" v-model="data.grade" deletable-chips outlined hide-details="auto" dense chips small-chips
                            label="Грейд"></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="8">
                        <v-text-field label="Рейт"  v-model="data.rate"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-autocomplete  v-model="data.valute" :items="['Руб/час', '$/час']" deletable-chips outlined hide-details="auto" dense
                            chips small-chips label="Валюта"></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-text-field v-model="data.info" label="Инфо о проекте"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-text-field v-model="data.demands" label="Требования"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                       <v-btn @click="cancelForm">Отмена</v-btn>
                    </v-col>
                    <v-col cols="12" md="6">
                       <v-btn @click="saveForm">Сохранить</v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col cols="12" >
                <v-btn @click="openForm">Новый запрос</v-btn>
            </v-col>
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Поиск" single-line
                            hide-details></v-text-field>
                    </v-card-title>
                    <v-data-table :headers="headers" :items="listInterview" :items-per-page="10" :search="search"
                        class="elevation-1" @click:row="readData">
                        <template v-slot:item.result="{ item }">
                            <span>{{ ((item.result == -1) ? 'Ожидает ответа' : (item.result == 0 ? 'отказ' :
                                'успех')) }}</span>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>

export default {
    name: 'RequestSpecialist',
    data: () => ({
        dt: [],
        visibleList: true,
        data: {
            company: "",
            location: "",
            role: "",
            grade: "",
            rate: "",
            valute: "",
            info: "",
            demands:"",
        },

        vis: false,
    }),
    mounted() {

    },
    computed: {

    },
    methods: {
        openForm(){
            this.visibleList = false;
        },
        cancelForm(){
            this.visibleList = true;
        },
        saveForm(){
            this.visibleList = true;
        }
    }


}
</script>
