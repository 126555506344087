<template>
    <v-container>
      <div>
        <v-row>
            <v-col cols="4" class="mb-4">
                <v-select :items="country" label="Страна"></v-select>
            </v-col>
            <v-col cols="4" class="mb-4">
                <v-text-field label="Юридическая форма в стране" type="text" required></v-text-field>
            </v-col>
            <v-col cols="4" class="mb-4">
              <v-select :items="list" label="Юридическая форма для нас"></v-select>
            </v-col>
        </v-row>
      </div>
      <div style="margin-top: 10px">
      <element-loading :active="onLoadData" spinner="spinner" color="#FF6700"/>
      <v-card>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Поиск"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="desserts"
          :items-per-page="15"
          :search="search"
          class="elevation-1"
          
        >
        <template v-slot:item.act="{ item }">
          <v-btn class="btn_read" @click="onReadSpec(item.id)" title="редактировать специалиста" style="margin-top:2px; margin-bottom: 3px">
            Изменить
          </v-btn>
          <v-btn class="btn_read" @click="OpenDialog(item.act)" :key="item.act" title="сменить статус" style="margin-bottom: 3px">
            Статус
          </v-btn>
        </template>
        </v-data-table>
      </v-card>
    </div>
    </v-container>
  </template>
  
  <script>
  export default {
    data: () => ({
      list: [
        'Общество с дополнительной ответственностью',
        'Общество с ограниченной ответственностью',
        'Открытое акционерное общество',
        'Закрытое акционерное общество',
        'Индивидуальный предприниматель',
        'Частное унитарное предприятие',
        'Товарищество с ограниченной ответственностью',
        'Товарищество с дополнительной ответственностью',
        'Командитное Товарищество',
        'Общественное объединение',
        'Полное Товарищество',
        'Производственный кооператив',
        'Семейная компания',
        'Унитарное предприятие',
      ],
      desserts: [
        {
          id: 1,
          country: "Узбекистан",
          dts: "МЧЖ (Масулияти Чекланган Джамият / Масъулияти Чекланган Жамият)",
          nn: "Общество с ограниченной ответственностью"
        },
        {
          id: 2,
          country: "Узбекистан",
          dts: "QMJ (Qo'shimcha ma'suliyatli jamiyat / Қўшимча масъулиятли жамият)",
          nn: "Общественное объединение"
        },
        
      ],
      headers: [
      { text: "id", value: "id" },
      { text: "Страна", value: "country" },
      { text: "Орг. форма", value: "dts" },
      { text: "Орг. форма наша", value: "nn" },
    ],
    }),
    name: "typeorg",
    mounted() {
      this.$store.dispatch("getLocationList");
    },
    computed: {
    country() {
      return this.$store.getters.getListLocation;
    },
  },
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped></style>
  