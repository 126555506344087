<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <h1>Интервью</h1>
                <v-row>
                    <v-col cols="12" md="12">
                        <v-autocomplete :items="proj" deletable-chips outlined hide-details="auto" dense chips small-chips
                            label="Проект" v-model="zakaz.proj" @change="onFindFirm"></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-autocomplete :items="spec" deletable-chips outlined hide-details="auto" dense chips small-chips
                            label="Специалист" v-model="zakaz.spec" @change="onFindFirm"></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="12">{{ comp.officeName }}</v-col>
                    <v-col cols="12" md="12">
                        <v-textarea name="input-7-1" label="Коментарий для поставщика" value="" v-model="zakaz.info"
                            hint="Коментарий для поставщика"></v-textarea>
                    </v-col>
                </v-row>
                <v-row v-for="(n, i) in zakaz.slots" :key="i">
                    <v-col cols="12" md="10">
                        <v-text-field :label="'Слот' + (i + 1)" type="datetime-local" v-model="n.dt"
                            min="2023-10-23 00:00:00" required></v-text-field>
                    </v-col>

                    <v-col cols="12" md="2">
                        <v-btn @click="addNewSlot(i)" color="#D9D9D9">{{ n.char }}</v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>

export default {
    name: 'RequestSpecialist',
    data: () => ({
        dt: [],
        data: {

        },
        comp: "",
        zakaz: {
            id: 0,
            spec: "",
            proj: "",
            info: "",
            slots: [
                {
                    dt: '',
                    char: '+'
                }
            ],

        },
        vis: false,
    }),
    mounted() {

    },
    computed: {
        proj() {
            return this.$store.getters.listCompanyIntr;
        },
    },
    methods: {
        onFindFirm() {
            this.$store.dispatch("getCompanyToSpecAdm", this.zakaz.spec).then(res => {
                this.comp = res;
            })
        },
        addNewSlot(t) {
            console.log(this.zakaz.slots);
            if (this.zakaz.slots[t].char == '+') {
                this.zakaz.slots[t].char = '-'
                let r = {
                    dt: '',
                    char: '+'
                }
                this.zakaz.slots.push(r);
            } else {
                this.zakaz.slots.splice(t, 1);
                this.zakaz.slots[t - 1].char = '-'
            }

        },
    }


}
</script>
