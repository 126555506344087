<template>
    <v-container>
        <v-row v-if="visible">
            <v-col cols="12">
                <h1>Запрос</h1>
                <v-row>
                    <v-col cols="12" md="12">
                        <v-autocomplete :items="dataTitleRequist" outlined hide-details="auto" dense chips small-chips
                            label="Проект" v-model="data.resq" @change="openProject"></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="12" v-html="data.info" v-if="vis">

                    </v-col>
                    <v-col cols="12" md="12" v-html="data.skill" v-if="vis">

                    </v-col>
                    <v-col cols="12" md="12">
                        <v-autocomplete :items="spec" v-model="data.spec" deletable-chips outlined hide-details="auto" dense
                            chips small-chips multiple label="Специалист"></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-row v-for="(n, i) in data.spec" :key="i">
                            <v-col cols="12" md="4">{{ n }}</v-col>
                            <v-col cols="12" md="8">
                                <v-text-field :label="'Коментарий ' + n"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-btn color="#D9D9D9" @click="cancelForm"> Отменить </v-btn>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-btn color="#FFE816" @click="saveForm"> Сохранить </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col cols="12" >
                <v-btn @click="openForm">Новое CV</v-btn>
            </v-col>
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Поиск" single-line
                            hide-details></v-text-field>
                    </v-card-title>
                    <v-data-table :headers="headers" :items="listInterview" :items-per-page="10" :search="search"
                        class="elevation-1" @click:row="readData">
                        <template v-slot:item.result="{ item }">
                            <span>{{ ((item.result == -1) ? 'Ожидает ответа' : (item.result == 0 ? 'отказ' :
                                'успех')) }}</span>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>

export default {
    name: 'RequestSpecialist',
    data: () => ({
        dt: [],
        visible: false,
        data: {
            resq: "",
            spec: [],
            info: "",
            skill: "",
        },

        vis: false,
    }),
    mounted() {
        this.$store.dispatch("getListRequest");
        this.$store.dispatch("getListInfoCompanySpecAdm");
    },
    computed: {
        dataTitleRequist() {
            return this.$store.getters.listRequestTitle;
        },
        spec() {
            return this.$store.getters.listSpecIntr;
        },
        request() {
            return this.$store.getters.listRequest;
        }
    },
    methods: {
        openForm(){
            this.visible = true;

        },
        cancelForm(){
            this.visible = false;
        },
        saveForm(){
            this.visible = false;
        }
    }


}
</script>
