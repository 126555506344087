<template>
    <v-container style="background-color: #fff;">
        <v-row>
            <v-col cols="12">Профиль <v-btn  color="#FFE816">редактировать</v-btn> </v-col>
            <v-col cols="2" class="mb-2"></v-col>
            <v-col cols="2" class="mb-2" style="margin-right: 10px;">
                <v-row>
                    <v-col>
                        <v-list>
                            <v-list-item>
                                <v-list-item-avatar width="150" height="150" style="box-shadow: 2px 2px 2px 2px #ddd; margin: auto;">
                                    <v-img
                                        src="https://klev.club/uploads/posts/2023-10/1697837804_klev-club-p-arti-anime-avatarki-48.jpg"></v-img>
                                </v-list-item-avatar>
                            </v-list-item>
                        </v-list>
                    </v-col>
                    <v-col style="text-align: left; box-shadow: 2px 2px 2px 2px #ddd;border-radius: 20px;" >
                        Документы

                        <p><v-btn color="primary" text style="font-size: 10px;font-family: 'Inter',Arial,sans-serif;">Заявление о поступлении на работу</v-btn></p>
                        <p><v-btn color="primary" text style="font-size: 10px;font-family: 'Inter',Arial,sans-serif;">Завление на отпуск</v-btn></p>
                        <p><v-btn color="primary" text style="font-size: 10px;font-family: 'Inter',Arial,sans-serif;">Завление на захват мира</v-btn></p>
                        <p><v-btn color="primary" text style="font-size: 10px;font-family: 'Inter',Arial,sans-serif;">Запрос на получении справки о доходах</v-btn></p>
                        <p><v-btn color="primary" text style="font-size: 10px;font-family: 'Inter',Arial,sans-serif;">Сопуствующие документы</v-btn></p>
                    </v-col>
                    
                </v-row>

            </v-col>
            <v-col cols="6" class="mb-8" style="text-align: left; box-shadow: 2px 2px 2px 2px #ddd; border-radius: 20px;">
                <p>
                    <b>ФИО</b> Баранов Дмитрий Сергеевич
                </p>
                <p style="background-color: #ddd;">
                    <b>Отдел</b> Програмного обеспечения
                </p>
                <p>
                    <b>Руководитель</b> Иванов Иван Иванович
                </p>
                <p style="background-color: #ddd;">
                    <b>Должность</b> <v-btn text color="primary" title="Занимается контролем за работой платформы">Aдминистратор</v-btn>
                </p>
                <p>
                    <b>Грейд</b> Мидл
                </p>
                <p style="background-color: #ddd;">
                    <b>Стаж работы в компании</b> 2 года
                </p>
                <p>
                    <b>Дата рождения</b> 25.07.1989
                </p>
                <p style="background-color: #ddd;">
                    <b>Телефон личный</b> +7 (900)-555-44-33
                </p>
                <p>
                    <b>Телефон рабочий</b> +7 (900)-555-44-33
                </p>
                <p style="background-color: #ddd;">
                    <b>Рабочий ТГ</b> <span style="color: blue;">@folEdvg</span>
                </p>
                <p>
                    <b>Личная гугл почта</b> <span style="color: blue;">baranov3@mail.ru</span>
                </p>
                <p style="background-color: #ddd;">
                    <b>Контакт довереного 1</b> Мама тел: 8 (345) 456-67-56
                </p>
                <p>
                    <b>Контакт довереного 2</b> Папа тел: 8 (345) 456-67-56
                </p>

            </v-col>

        </v-row>
    </v-container>
</template>

<script>
export default {
    data: () => ({

        valid: true,
        name: "",
        inn: "",
        fio: "",
        pass: "",
        passNext: "",
        nameRules: [
            (v) => !!v || "Name is required",
            (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,
    }),
    name: "account",
    methods: {
        validate() {
            this.$refs.form.validate();
            let t = {
                eml: this.email,
                ps: this.pass,
                ps1: this.passNext,
                fio: this.fio,
            }
            this.$store.dispatch("setDataAccUser", t).then((res) => {
                this.email = res;
            });
        },
        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
        getEvents({ start, end }) {
            const events = []

            const min = new Date(`${start.date}T00:00:00`)
            const max = new Date(`${end.date}T23:59:59`)
            const days = (max.getTime() - min.getTime()) / 86400000
            const eventCount = this.rnd(days, days + 20)

            for (let i = 0; i < eventCount; i++) {
                const allDay = this.rnd(0, 3) === 0
                const firstTimestamp = this.rnd(min.getTime(), max.getTime())
                const first = new Date(firstTimestamp - (firstTimestamp % 900000))
                const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
                const second = new Date(first.getTime() + secondTimestamp)

                events.push({
                    name: this.names[this.rnd(0, this.names.length - 1)],
                    start: first,
                    end: second,
                    color: this.colors[this.rnd(0, this.colors.length - 1)],
                    timed: !allDay,
                })
            }

            this.events = events
        },
        getEventColor(event) {
            return event.color
        },
        rnd(a, b) {
            return Math.floor((b - a + 1) * Math.random()) + a
        },
    },
    mounted() {
        this.$store.dispatch("getMailUser").then((res) => {
            this.email = res.eml;
            this.fio = res.fio
        });
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>