<template>
  <v-container>
    faq
  </v-container>
</template>

<script>
export default {
  data: () => ({
   
  }),
  name: "faq",
  methods: {
    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
