<template>
    <div style="margin-right: 10px;">
      <loader
      v-if="load"
      object="#ff9633"
      color1="#ffffff"
      color2="#17fd3d"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="80"
      name="dots"
    ></loader>
      <v-row>
        <v-col cols="12" md= "2">
          <v-autocomplete
                :items="filter.poct"  
                v-model="filt.post"                      
                dense
                chips                
                multiple
                @change ="onfind"
                label="Поставщик"
            ></v-autocomplete>
        </v-col>
        <v-col cols="12" md= "2">
          <v-autocomplete
                :items="filter.client"   
                v-model="filt.client"                    
                @change ="onfind"                  
                dense
                chips
                multiple
                label="Клиент"
            ></v-autocomplete>
        </v-col>
        <v-col cols="12" md= "2">
          <v-autocomplete
                :items="filter.project"   
                v-model="filt.project"                      
                dense
                chips
                multiple
                @change ="onfind"
                label="проект"
            ></v-autocomplete>
        </v-col>
        <v-col cols="12" md= "2">
          <v-autocomplete
                :items="filter.stad" 
                v-model="filt.stad"   
                @change ="onfind"                     
                dense
                chips
                multiple
                label="стадия"
            ></v-autocomplete>
        </v-col>
        <v-col cols="12" md= "2">
          <v-autocomplete
                :items="filter.otv"  
                v-model="filt.otv"   
                @change ="onfind"                          
                dense
                chips
                multiple
                label="отвественный"
            ></v-autocomplete>
        </v-col>
        <v-col cols="12" md= "2">
          <v-autocomplete
                :items="filter.meneg" 
                v-model="filt.meneg"   
                @change ="onfind"                           
                dense
                chips
                multiple
                label="менеджер"
            ></v-autocomplete>
        </v-col>
        <v-col cols="12" md= "2">
          <v-text-field
          v-model="filt.dts"  
                @input ="onfind"  
            type="date"
            label="дата начала"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md= "2">
          <v-text-field
            v-model="filt.dte"  
                @input ="onfind"  
            type="date"
            label="дата окончания"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" justify="space-around" style="margin-bottom: 10px">
        
            <v-col cols="12" md="1" sm="1">Сделки:<br />{{ desserts.length }}</v-col>
            <v-col cols="12" md="1" sm="1">Новые сделки:<br />{{ summ.new }}</v-col>
            <v-col cols="12" md="1" sm="1">Продано часов:<br />{{ summ.hour }}</v-col>
            <v-col cols="12" md="1" sm="1">Ср. стоимость часа:<br />{{ summ.f1.toFixed(1) }}</v-col>
            <v-col cols="12" md="1" sm="1">Средний чек:<br />{{ summ.f1.toFixed(1) * 160 }}</v-col>
            <v-col cols="12" md="1" sm="1">Сумма продаж:<br />{{ summ.sumprod.toFixed(1) }}</v-col>
            <v-col cols="12" md="1" sm="1">Себе-<br/>стоимость:<br />{{ summ.ZAKYP.toFixed(1) }}</v-col>
            <v-col cols="12" md="1" sm="1">Налоги:<br />{{ summ.nalog.toFixed(1) }}</v-col>
            <v-col cols="12" md="1" sm="1">НДС на импорт:<br />{{ summ.nds.toFixed(1) }}</v-col>
            <v-col cols="12" md="1" sm="1">Маржа:<br />{{ summ.marjas.toFixed(1) }}</v-col>
            <v-col cols="12" md="1" sm="1">Ср. маржа со сделки:<br />{{ (summ.marjas/desserts.length).toFixed(1) }}</v-col>
            <v-col cols="12" md="1" sm="1">Ср. маржа с часа:<br />{{ (summ.marjas/summ.hour).toFixed(1) }}</v-col>
            <v-col cols="12" md="1" sm="1">Маржина-<br />льность:<br />{{ (summ.marjas.toFixed(1) / summ.sumprod.toFixed(1) * 100).toFixed(1) }}</v-col>
            <v-col cols="12" md="1" sm="1">Фонд ЗП:<br />{{ summ.fondzp.toFixed(1) }}</v-col>
            <v-col cols="12" md="1" sm="1">Фонд годовой премии:<br />{{ summ.fondp.toFixed(1) }}</v-col>
            <v-col cols="12" md="1" sm="1">Страховой фонд:<br />{{ summ.fonds.toFixed(1) }}</v-col>
            <v-col cols="12" md="1" sm="1">Корпоративный фонд:<br />{{ summ.fondk.toFixed(1) }}</v-col>
            <v-col cols="12" md="1" sm="1">Фонд маркетинга:<br />{{ summ.fondm.toFixed(1) }}</v-col>
            <v-col cols="12" md="1" sm="1">Фонд постоянных расходов:<br />{{ summ.fondm.toFixed(1) }}</v-col>
            <v-col cols="12" md="1" sm="1">Фонд дивидендов:<br />{{ summ.fondd.toFixed(1) }}</v-col>
         
      </v-row>
      <div style="margin-top: 10px;overflow: auto;" >
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Поиск"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="desserts"
            :items-per-page="10"
            :search="search"
            class="elevation-1"
          ></v-data-table>
        </v-card>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "deal",
    data: () => ({
      dialog: false,
      filt: {
        post: [],
        client:[],
        project: [],
        otv: [],
        meneg:[],
        stad: [],
        dts: '',
        dte: '',
      },
      search: "",
      nameRules: [
        (v) => !!v || "Название не может быть пустым",
        (v) => (v && v.length >= 1) || "Name must be less than 10 characters",
      ],
      data: {
        id: 0,
        name: "",
      },
      headers: [
        { text: "ID Сделки", value: "ID" },
        { text: "Название", value: "TITLE" },
        { text: "Стадия сделки", value: "STAGE_ID" },
        { text: "Клиент", value: "company" },
        { text: "Дата начала", value: "BEGINDATE" },
        { text: "Дата окончания", value: "CLOSEDATE" },
        { text: "Ответственный", value: "NAME" },
        { text: "Новая сделка", value: "IS_NEW" },
        { text: "ФИО Специалиста", value: "UF_CRM_1670089961720" },
        { text: "Роль", value: "UF_CRM_1670090126303" },
        { text: "Грейд", value: "UF_CRM_1670090137271" },
        { text: "Поставщик", value: "UF_CRM_1671063594534" },
        { text: "проект", value: "UF_CRM_1672773790979" },
        { text: "Коннект менеджер", value: "NAMEMENEG" },
        { text: "кол-во часов", value: "UF_CRM_1670087417814" },        
        { text: "Валюта", value: "CURRENCY_ID" },
        { text: "Закупка час", value: "UF_CRM_1670090300192" },
        { text: "Продажа час", value: "UF_CRM_1670090339455" },
        { text: "НДС НА ИМПОРТ", value: "UF_CRM_1670087477859" },

        { text: "Себестоимость", value: "ZAKYP" },
        { text: "СУММА ПРОДАЖИ", value: "sumprod" },
        { text: "Налоги", value: "nalog" },
        { text: "Маржа час", value: "marjach" },
        { text: "Маржа по сделке", value: "marjas" },
        { text: "Фонд ЗП", value: "fondzp" },
        { text: "Фонд П", value: "fondp" },
        { text: "Фонд страх", value: "fonds" },
        { text: "Фонд корп", value: "fondk" },        
        { text: "Фонд маркет", value: "fondm" },              
        { text: "Фонд пост", value: "fondp" },      
        { text: "Фонд Д", value: "fondd" },
      ],
    }),
    computed: {
      desserts() {
        return this.$store.getters.getListBxDeal;
      },
      filter() {
        return this.$store.getters.ListFilter;
      },
      summ(){
        return this.$store.getters.ListSumm;
      },
      load() {
      return this.$store.getters.getOnLoad;
    },
    },
    mounted() {
      this.$store.dispatch("getBxDeal");
    },
    methods: {
      onfind(){
        this.$store.dispatch("FindBxDeal", this.filt);
      }
    },
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .infoPerson {
    text-align: left;
  }
  </style>
  