<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                Сделки
            </v-col>
        </v-row>
        <v-row>
            <v-col cols=12 md="4">
                <v-btn @click="form=0">Задачи</v-btn>
            </v-col>
            <v-col cols=12 md="4">
                <v-btn @click="form=1">Сделки</v-btn>
            </v-col>
            <v-col cols=12 md="4">
                <v-btn @click="form=2">Клиенты</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <tasks-list v-if="form==0"></tasks-list>
            <list-deals v-if="form==1"></list-deals>
            <client-list v-if="form==2"></client-list>
        </v-row>
    </v-container>
</template>
<script>
import TasksList from './elemets/TasksList.vue';
import ListDeals from './elemets/ListDeals.vue';
import ClientList from './elemets/Client.vue';
export default {
  data: () => ({
    form: 0,
   
  }),
  name: "deal",
  components: {
    TasksList, ListDeals, ClientList
  },
  mounted() {
    
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
   
  },
  computed: {
    onLoadData() {
      return this.$store.getters.getOnLoad;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>