<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="2">
                <v-img height="100" max-width="125" :src="'https://humacl.x-or.ru/public/images/'+idCompany.logo" style="margin: auto;"></v-img>
            </v-col>
            <v-col cols="12" md="10">
                <v-row>
                    <v-col cols="12" md="4"><b>Название фирмы</b></v-col>
                    <v-col cols="12" md="8">{{ idCompany.officeName }}</v-col>
                    <v-col cols="12" md="4"><b>ФИО руководителя:</b></v-col>
                    <v-col cols="12" md="8">{{ idCompany.FIOR }} ({{ idCompany.positionR }}, {{ idCompany.baseR }})</v-col>                    
                    <v-col cols="12" md="2"><b>ИНН</b></v-col>
                    <v-col cols="12" md="2">{{ idCompany.inn }}</v-col>
                    <v-col cols="12" md="2"><b>КПП</b></v-col>
                    <v-col cols="12" md="2">{{ idCompany.kpp }}</v-col>
                    <v-col cols="12" md="2"><b>ОГРН</b></v-col>
                    <v-col cols="12" md="2">{{ idCompany.OGRN }}</v-col>
                </v-row>
            </v-col>
            <v-col cols="12">
                <v-row>
                    <v-col cols="12"  md="2"><b>Адрес</b></v-col>
                    <v-col cols="12"  md="10">{{ idCompany.adress }}</v-col>
                </v-row>
            </v-col>
            <v-col cols="12">
                <v-row>
                    <v-col cols="12"  md="3"><b>Бренд</b></v-col>
                    <v-col cols="12"  md="3">{{ idCompany.brand }}</v-col>
                    <v-col cols="12"  md="3"><b>Сайт</b></v-col>
                    <v-col cols="12"  md="3">{{ idCompany.site }}</v-col>
                    <v-col cols="12"  md="2"><b>Кол-во в штате</b></v-col>
                    <v-col cols="12"  md="2">{{ idCompany.countWorkes }}</v-col>
                    <v-col cols="12"  md="2"><b>Цена мидл</b></v-col>
                    <v-col cols="12"  md="2">{{ idCompany.RateMiddle }}</v-col>
                    <v-col cols="12"  md="2"><b>Цена сеньер</b></v-col>
                    <v-col cols="12"  md="2">{{ idCompany.RateSenior }}</v-col>
                </v-row>
            </v-col>
            <fieldset>
                <legend>Банк</legend>
            <v-col cols="12">
                <v-row>
                    <v-col cols="12"  md="2"><b>Банк</b></v-col>
                    <v-col cols="12"  md="10">{{ idCompany.bank }}</v-col>
                    <v-col cols="12"  md="2"><b>Бик банка</b></v-col>
                    <v-col cols="12"  md="2">{{ idCompany.bik }}</v-col>
                    <v-col cols="12"  md="1"><b>Кор</b></v-col>
                    <v-col cols="12"  md="3">{{ idCompany.korChet }}</v-col>
                    <v-col cols="12"  md="1"><b>Рас</b></v-col>
                    <v-col cols="12"  md="3">{{ idCompany.rasChet }}</v-col>
                </v-row>
            </v-col>
        </fieldset>
            <fieldset>
                <legend>Ответы на анкету</legend>
            <v-col cols="12">
                <v-row v-for="(n,i) in idCompany.answer" :key="i">
                    <v-col cols="12"  md="6"><b>{{ n.textQuestion }}</b></v-col>
                    <v-col cols="12"  md="6">{{ n.answer}}</v-col>
                </v-row>
            </v-col>
        </fieldset>
        </v-row>
        <!-- {{ this.idCompany }} -->
    </v-container>
</template>
<script>

export default {
    name: 'formInfoCompanyId',
    props: 
        ['idCompany'],           
    data: () => ({
        dt: [],
    }),
    
}
</script>
